export const VALID_GUESSES = [
	'aahof',
	'aaken',
	'abadi',
	'abaja',
	'abaji',
	'abaju',
	'abaka',
	'abakā',
	'abako',
	'abaks',
	'abaku',
	'abala',
	'abano',
	'abapo',
	'abars',
	'abasi',
	'abass',
	'abaša',
	'abašu',
	'abata',
	'abatā',
	'abate',
	'abatē',
	'abati',
	'abats',
	'abatu',
	'abava',
	'abaza',
	'abazā',
	'abazi',
	'abazs',
	'abazu',
	'abāza',
	'abāzā',
	'abāzi',
	'abāzs',
	'abāzu',
	'abēji',
	'abēju',
	'abida',
	'abies',
	'abīda',
	'abjas',
	'abonē',
	'abora',
	'abpus',
	'abrai',
	'abras',
	'abrām',
	'abrās',
	'abuka',
	'abula',
	'abuls',
	'abuss',
	'acēna',
	'acils',
	'acini',
	'aciņa',
	'acīga',
	'acīgā',
	'acīgi',
	'acīgo',
	'acīgs',
	'acīgu',
	'acīte',
	'acoja',
	'acoji',
	'acoju',
	'acone',
	'acosi',
	'acošu',
	'acota',
	'acotā',
	'acoti',
	'acoto',
	'acots',
	'acotu',
	'actum',
	'acuto',
	'ačeha',
	'ačele',
	'ačelē',
	'ačeli',
	'ačeļu',
	'ačini',
	'ačkas',
	'adads',
	'adaha',
	'adana',
	'adapa',
	'adaro',
	'adars',
	'adata',
	'adatā',
	'adatu',
	'adāma',
	'adāmā',
	'adāmi',
	'adāmo',
	'adāms',
	'adāmu',
	'addio',
	'adehe',
	'adela',
	'adeli',
	'adena',
	'adens',
	'adēra',
	'adiet',
	'adieu',
	'adigi',
	'adiri',
	'aditi',
	'adīja',
	'adīji',
	'adīju',
	'adīne',
	'adīsi',
	'adīšu',
	'adīta',
	'adītā',
	'adīti',
	'adīto',
	'adīts',
	'adītu',
	'adobe',
	'adoša',
	'adošā',
	'adoši',
	'adošo',
	'adošs',
	'adošu',
	'adsel',
	'adums',
	'adūra',
	'adzes',
	'aedes',
	'aerēs',
	'aerēt',
	'afaik',
	'afara',
	'afāra',
	'afēra',
	'afiap',
	'afiša',
	'afišā',
	'afišē',
	'afišu',
	'afoņa',
	'afoze',
	'agama',
	'agapa',
	'agape',
	'agara',
	'agarā',
	'agari',
	'agars',
	'agaru',
	'agate',
	'agats',
	'agave',
	'agavē',
	'agavi',
	'agāma',
	'agāmā',
	'agāmi',
	'agāmo',
	'agāms',
	'agāmu',
	'agens',
	'agere',
	'agile',
	'aglia',
	'agnai',
	'agnam',
	'agnas',
	'agnām',
	'agnās',
	'agnie',
	'agnos',
	'agnus',
	'agona',
	'agone',
	'agoni',
	'agons',
	'agora',
	'agrai',
	'agram',
	'agras',
	'agrāk',
	'agrām',
	'agrās',
	'agres',
	'agrie',
	'agros',
	'agrot',
	'agrus',
	'agula',
	'aguli',
	'aguts',
	'aģitē',
	'ahaja',
	'ahajā',
	'ahaji',
	'ahajs',
	'ahaju',
	'ahāta',
	'ahātā',
	'ahāti',
	'ahāts',
	'ahātu',
	'ahuna',
	'ahura',
	'ahūns',
	'aicis',
	'aidla',
	'aiēts',
	'aijaa',
	'aijaā',
	'aijai',
	'aijas',
	'aijau',
	'aijās',
	'aijāt',
	'ailai',
	'ailas',
	'ailām',
	'ailās',
	'ailei',
	'ailes',
	'ailēm',
	'ailēs',
	'ailēt',
	'ailis',
	'ainai',
	'ainas',
	'ainām',
	'ainās',
	'aions',
	'aiova',
	'airai',
	'airas',
	'aires',
	'airēs',
	'airēt',
	'airim',
	'airis',
	'airos',
	'airus',
	'aisis',
	'aisti',
	'aitai',
	'aitas',
	'aitām',
	'aitās',
	'aitra',
	'aivai',
	'aivas',
	'aivām',
	'aivās',
	'aizai',
	'aizar',
	'aizas',
	'aizām',
	'aizās',
	'aizej',
	'aizēd',
	'aizir',
	'ajačo',
	'ajaka',
	'ajane',
	'ajasa',
	'ajāti',
	'ajona',
	'ajuda',
	'ajuga',
	'ajūna',
	'akaba',
	'akaci',
	'akacī',
	'akacs',
	'akača',
	'akači',
	'akaču',
	'akada',
	'akaki',
	'akali',
	'akani',
	'akasi',
	'akaša',
	'akaši',
	'akata',
	'akažu',
	'akāca',
	'akers',
	'akiba',
	'akina',
	'akinā',
	'akini',
	'akins',
	'akinu',
	'akita',
	'akīši',
	'aklai',
	'aklam',
	'aklas',
	'aklām',
	'aklās',
	'aklie',
	'aklis',
	'aklos',
	'aklus',
	'akļas',
	'aknai',
	'aknas',
	'aknām',
	'aknās',
	'aknes',
	'akola',
	'akota',
	'akotā',
	'akote',
	'akoti',
	'akots',
	'akotu',
	'akram',
	'akris',
	'akros',
	'akrus',
	'aksim',
	'aksis',
	'aksts',
	'akšos',
	'akšus',
	'aktau',
	'aktij',
	'aktis',
	'aktīm',
	'aktīs',
	'akure',
	'akuts',
	'akūta',
	'akūtā',
	'akūti',
	'akūto',
	'akūts',
	'akūtu',
	'aķiem',
	'aķīša',
	'aķīši',
	'aķīšu',
	'aķīti',
	'aķītī',
	'alajs',
	'alalu',
	'alama',
	'alamo',
	'aland',
	'alani',
	'alasi',
	'alass',
	'alata',
	'alatā',
	'alate',
	'alatu',
	'alave',
	'alavi',
	'album',
	'alcea',
	'alces',
	'alcis',
	'aldas',
	'aleja',
	'alejā',
	'aleju',
	'alene',
	'aleni',
	'alepa',
	'aleph',
	'alepo',
	'alesa',
	'alesi',
	'alesī',
	'aleša',
	'aleši',
	'alešu',
	'alēle',
	'alēlē',
	'alēli',
	'alēļu',
	'alfai',
	'alfas',
	'alfām',
	'alfās',
	'alfis',
	'algae',
	'algai',
	'algas',
	'algām',
	'algās',
	'algol',
	'algos',
	'algot',
	'alias',
	'alibi',
	'aliča',
	'aličā',
	'aliču',
	'aliem',
	'alife',
	'alima',
	'aliņa',
	'aliņā',
	'aliņi',
	'aliņš',
	'aliņu',
	'alise',
	'alisē',
	'alisi',
	'ališu',
	'alīls',
	'alīta',
	'alīts',
	'alkas',
	'alkām',
	'alkāt',
	'alksi',
	'alkst',
	'alkšu',
	'alkta',
	'alktā',
	'alkti',
	'alkto',
	'alkts',
	'alktu',
	'allah',
	'allaž',
	'allez',
	'allis',
	'alnim',
	'alnis',
	'alnus',
	'alods',
	'alofi',
	'aloja',
	'alojā',
	'aloje',
	'aloju',
	'alona',
	'alons',
	'alosa',
	'alots',
	'aloza',
	'alpai',
	'alpam',
	'alpas',
	'alpām',
	'alpās',
	'alpos',
	'alpus',
	'altam',
	'altea',
	'alter',
	'altos',
	'altus',
	'aluna',
	'aluts',
	'alūns',
	'alvai',
	'alvas',
	'alvām',
	'alvās',
	'alvos',
	'alvot',
	'aļģei',
	'aļģes',
	'aļģēm',
	'aļģēs',
	'aļļis',
	'aļņos',
	'aļņus',
	'amada',
	'amads',
	'amaja',
	'amaka',
	'amals',
	'amapa',
	'amara',
	'amari',
	'amata',
	'amatā',
	'amati',
	'amats',
	'amatu',
	'amāra',
	'ambas',
	'ambla',
	'ambra',
	'ambrā',
	'ambru',
	'amele',
	'ameli',
	'amels',
	'amesa',
	'amēba',
	'amēbā',
	'amēbu',
	'amida',
	'amila',
	'amilā',
	'amili',
	'amils',
	'amilu',
	'amiša',
	'amišā',
	'amiši',
	'amišs',
	'amišu',
	'amizē',
	'amīda',
	'amīdā',
	'amīdi',
	'amīds',
	'amīdu',
	'amīna',
	'amīnā',
	'amīni',
	'amīns',
	'amīnu',
	'amīši',
	'amoks',
	'amols',
	'amons',
	'amora',
	'amors',
	'amour',
	'amula',
	'amule',
	'amuļi',
	'amuru',
	'amūra',
	'amūrā',
	'amūri',
	'amūrs',
	'amūru',
	'anana',
	'anapa',
	'anaša',
	'anata',
	'anate',
	'anava',
	'anāla',
	'anālā',
	'anāli',
	'anālo',
	'anāls',
	'anālu',
	'ancio',
	'ancis',
	'andra',
	'andri',
	'andrs',
	'andži',
	'aneni',
	'anesī',
	'aneto',
	'anglē',
	'angļi',
	'angra',
	'angri',
	'angro',
	'anhro',
	'aniba',
	'anika',
	'anikā',
	'aniks',
	'aniku',
	'anima',
	'anina',
	'aniņi',
	'anise',
	'aniss',
	'anīsa',
	'anīsā',
	'anīse',
	'anīsi',
	'anīss',
	'anīsu',
	'ankhs',
	'annas',
	'annus',
	'anoda',
	'anodā',
	'anode',
	'anodi',
	'anods',
	'anodu',
	'anoka',
	'anona',
	'anotē',
	'anser',
	'antei',
	'antes',
	'antēm',
	'antēs',
	'anton',
	'antrē',
	'anuja',
	'anulē',
	'anuļa',
	'anura',
	'anuži',
	'aņhui',
	'aņuks',
	'aorta',
	'aosta',
	'apači',
	'apada',
	'apadi',
	'apadu',
	'apakš',
	'apali',
	'apals',
	'apaļa',
	'apaļā',
	'apaļi',
	'apaļo',
	'apaļš',
	'apaļu',
	'apans',
	'apapa',
	'apara',
	'aparā',
	'apari',
	'apars',
	'apart',
	'aparu',
	'apass',
	'apaša',
	'apašā',
	'apaši',
	'apašs',
	'apašu',
	'apaud',
	'apaug',
	'apauj',
	'apaus',
	'apaut',
	'apauž',
	'apava',
	'apavā',
	'apavi',
	'apavs',
	'apavu',
	'apāva',
	'apāvi',
	'apāvu',
	'apbāz',
	'apbāž',
	'apber',
	'apbur',
	'apbūb',
	'apcel',
	'apceļ',
	'apcep',
	'apdeg',
	'apdod',
	'apdos',
	'apdot',
	'apdur',
	'apecē',
	'apeja',
	'apejā',
	'apeju',
	'apeks',
	'apela',
	'apelē',
	'Apeli',
	'Apelī',
	'Apeļa',
	'Apeļi',
	'Apeļu',
	'apene',
	'apenē',
	'apeni',
	'apeņi',
	'apeņu',
	'apera',
	'apēda',
	'apēdi',
	'apēdu',
	'apēna',
	'apēno',
	'apēst',
	'apgāz',
	'apgāž',
	'apgūs',
	'apgūt',
	'aphis',
	'apies',
	'apiet',
	'apija',
	'apika',
	'apikā',
	'apiki',
	'apiks',
	'apiku',
	'apini',
	'apinī',
	'apiņa',
	'apiņi',
	'apiņu',
	'apirs',
	'apirt',
	'apiru',
	'apiss',
	'apium',
	'apīni',
	'apīnī',
	'apīņa',
	'apīņi',
	'apīņu',
	'apīra',
	'apīri',
	'apīru',
	'apjāj',
	'apjās',
	'apjāt',
	'apjoz',
	'apjož',
	'apjum',
	'apjūc',
	'apjūk',
	'apkal',
	'apkaļ',
	'apkar',
	'apkār',
	'apkod',
	'apkop',
	'apkož',
	'apkul',
	'apkuļ',
	'apkūp',
	'apķep',
	'apķer',
	'aplam',
	'aplec',
	'aplej',
	'aplēs',
	'aplēš',
	'aplim',
	'aplis',
	'aplīp',
	'aplīs',
	'aplīt',
	'aplob',
	'aplūp',
	'aplūr',
	'apļos',
	'apļot',
	'apļus',
	'apmal',
	'apmaļ',
	'apmāj',
	'apmās',
	'apmāt',
	'apmet',
	'apmij',
	'apmīs',
	'apmīt',
	'apmīz',
	'apmīž',
	'apnāc',
	'apnāk',
	'apnes',
	'apnīc',
	'apnīk',
	'apnoe',
	'apņem',
	'apoda',
	'apodi',
	'apodu',
	'apoga',
	'apogā',
	'apogi',
	'apogs',
	'apogu',
	'apolē',
	'apoļu',
	'apops',
	'aport',
	'apost',
	'apožu',
	'apper',
	'appil',
	'appin',
	'appīs',
	'appīt',
	'apput',
	'appūs',
	'appūš',
	'appūt',
	'aprāj',
	'aprās',
	'aprāt',
	'aprec',
	'aprej',
	'aprep',
	'aprij',
	'apris',
	'aprit',
	'aprīs',
	'aprīt',
	'aproc',
	'aprod',
	'aprok',
	'apsei',
	'apses',
	'apsēj',
	'apsēm',
	'apsēs',
	'apsēt',
	'apsis',
	'apsit',
	'apste',
	'apsus',
	'apsūt',
	'apšuj',
	'apšūs',
	'apšūt',
	'aptek',
	'aptēs',
	'aptēš',
	'aptēt',
	'aptin',
	'aptīs',
	'aptīt',
	'aptur',
	'apupe',
	'apure',
	'apuss',
	'apuze',
	'apūle',
	'apvāz',
	'apvāž',
	'apved',
	'apvel',
	'apveļ',
	'apvem',
	'apver',
	'apvēz',
	'apvēž',
	'apvij',
	'apviz',
	'apvīs',
	'apvīt',
	'apzel',
	'apzeļ',
	'apzog',
	'apžūs',
	'apžūt',
	'araba',
	'arača',
	'arada',
	'arads',
	'arafa',
	'araks',
	'arala',
	'arams',
	'arani',
	'araps',
	'arara',
	'arari',
	'arasa',
	'arats',
	'arava',
	'arāba',
	'arābā',
	'arābe',
	'arābi',
	'arābs',
	'arābu',
	'arāja',
	'arājā',
	'arāji',
	'arājs',
	'arāju',
	'arāka',
	'arāle',
	'arāls',
	'arbet',
	'arbor',
	'ardea',
	'areca',
	'areco',
	'arejs',
	'areka',
	'arekā',
	'areku',
	'arele',
	'aresa',
	'aress',
	'arējs',
	'arēna',
	'arēnā',
	'arēni',
	'arēns',
	'arēnu',
	'arfai',
	'arfas',
	'arfām',
	'arfās',
	'argha',
	'argot',
	'arika',
	'arikā',
	'ariki',
	'ariks',
	'ariku',
	'arils',
	'arima',
	'arimi',
	'arion',
	'arisa',
	'arīds',
	'arkai',
	'arkas',
	'arkām',
	'arkās',
	'arkla',
	'arklā',
	'arkli',
	'arkls',
	'arklu',
	'arķis',
	'armēt',
	'aroda',
	'arodā',
	'arodi',
	'arods',
	'arodu',
	'aroma',
	'aromā',
	'aromi',
	'aroms',
	'aromu',
	'arona',
	'arone',
	'arras',
	'arska',
	'artha',
	'artuā',
	'aruba',
	'aruma',
	'arumā',
	'arumi',
	'arums',
	'arumu',
	'aruna',
	'aruru',
	'arusi',
	'aruša',
	'arūzs',
	'asaba',
	'asaga',
	'asahi',
	'asais',
	'asajā',
	'asaka',
	'asakā',
	'asaku',
	'asala',
	'asama',
	'asara',
	'asarā',
	'asare',
	'asari',
	'asarī',
	'asaro',
	'asars',
	'asaru',
	'asāks',
	'asāni',
	'ascii',
	'asean',
	'asena',
	'asens',
	'asēja',
	'asēji',
	'asēju',
	'asēsi',
	'asēšu',
	'asēta',
	'asētā',
	'asēti',
	'asēto',
	'asēts',
	'asētu',
	'asiem',
	'asina',
	'asini',
	'asinī',
	'asins',
	'asinu',
	'asiņa',
	'asiņi',
	'asiņo',
	'asiņu',
	'asīra',
	'asīte',
	'asīze',
	'asnam',
	'asnis',
	'asnos',
	'asnot',
	'asnus',
	'asoms',
	'asona',
	'asonā',
	'asoni',
	'asons',
	'asonu',
	'asosa',
	'asote',
	'assai',
	'astei',
	'aster',
	'astes',
	'astēm',
	'astēs',
	'astma',
	'astmā',
	'astmu',
	'astra',
	'astrā',
	'astre',
	'astri',
	'astrs',
	'astru',
	'asuka',
	'asuma',
	'asumā',
	'asumi',
	'asums',
	'asumu',
	'asupe',
	'asura',
	'asūne',
	'asūri',
	'ašais',
	'ašajā',
	'ašari',
	'ašars',
	'ašera',
	'ašers',
	'ašiem',
	'ašķis',
	'ašnis',
	'ašoka',
	'ašuma',
	'ašumā',
	'ašumi',
	'ašums',
	'ašumu',
	'ašūra',
	'ataka',
	'atals',
	'atara',
	'atare',
	'atari',
	'atars',
	'atart',
	'ataru',
	'atašs',
	'atata',
	'ataug',
	'atauj',
	'ataus',
	'ataut',
	'atāķē',
	'atāla',
	'atālā',
	'atāli',
	'atāls',
	'atālu',
	'atāre',
	'atārs',
	'atāva',
	'atāvi',
	'atāvu',
	'atbāz',
	'atbāž',
	'atber',
	'atbēg',
	'atbur',
	'atbūt',
	'atcel',
	'atceļ',
	'atcep',
	'atdeg',
	'atdim',
	'atdip',
	'atdod',
	'atdos',
	'atdot',
	'atdun',
	'atdur',
	'atdus',
	'atdūc',
	'ateja',
	'atejā',
	'ateju',
	'atels',
	'atelš',
	'atesa',
	'atēna',
	'atēnā',
	'atēno',
	'atēnu',
	'atēst',
	'atgal',
	'atgāz',
	'atgāž',
	'atgul',
	'atgūs',
	'atgūt',
	'aties',
	'atiet',
	'atiez',
	'atiež',
	'atijs',
	'atika',
	'atila',
	'atirs',
	'atirt',
	'atiru',
	'atiss',
	'atīdi',
	'atīdu',
	'atīra',
	'atīri',
	'atīru',
	'atjāj',
	'atjās',
	'atjāt',
	'atjoz',
	'atjož',
	'atkal',
	'atkaļ',
	'atkan',
	'atkar',
	'atkāp',
	'atkin',
	'atkod',
	'atkop',
	'atkož',
	'atkūp',
	'atķer',
	'atlas',
	'atlec',
	'atlej',
	'atlīp',
	'atlīs',
	'atlīt',
	'atlob',
	'atlūp',
	'atļūc',
	'atļūk',
	'atmāj',
	'atmās',
	'atmāt',
	'atmet',
	'atmēz',
	'atmēž',
	'atmin',
	'atmīs',
	'atmīt',
	'atmūc',
	'atmūk',
	'atnāc',
	'atnāk',
	'atnes',
	'atņem',
	'atola',
	'atolā',
	'atoli',
	'atols',
	'atolu',
	'atoma',
	'atomā',
	'atomi',
	'atoms',
	'atomu',
	'atona',
	'atoni',
	'atons',
	'atoro',
	'ators',
	'atosa',
	'atpin',
	'atpīs',
	'atpīt',
	'atput',
	'atpūš',
	'atpūt',
	'atrej',
	'atrēc',
	'atrij',
	'atris',
	'atrit',
	'atrīb',
	'atrīs',
	'atrīt',
	'atroc',
	'atrod',
	'atrok',
	'atrūc',
	'atsāc',
	'atsāk',
	'atsēž',
	'atsit',
	'atsīc',
	'atšuj',
	'atšūs',
	'atšūt',
	'attac',
	'attek',
	'attin',
	'attīs',
	'attīt',
	'attop',
	'attur',
	'atuls',
	'atums',
	'atuto',
	'atvāc',
	'atvāz',
	'atvāž',
	'atved',
	'atvel',
	'atveļ',
	'atvem',
	'atver',
	'atvēl',
	'atvēz',
	'atvēž',
	'atvij',
	'atvil',
	'atviļ',
	'atviz',
	'atvīd',
	'atvīs',
	'atvīt',
	'atzel',
	'atzeļ',
	'atzib',
	'atzīs',
	'atzīt',
	'aubei',
	'aubes',
	'aubēm',
	'aubēs',
	'aubju',
	'audām',
	'audās',
	'audāt',
	'audis',
	'audīs',
	'audos',
	'audra',
	'audze',
	'audzē',
	'audzi',
	'audzu',
	'audže',
	'audžu',
	'auers',
	'augai',
	'augam',
	'augas',
	'augat',
	'augām',
	'augās',
	'augāt',
	'augie',
	'augli',
	'auglī',
	'augļa',
	'augļi',
	'augļo',
	'augļu',
	'augos',
	'augot',
	'augsi',
	'augša',
	'augšā',
	'augšu',
	'augta',
	'augtā',
	'augti',
	'augto',
	'augts',
	'augtu',
	'augus',
	'auģis',
	'aujam',
	'aujas',
	'aujat',
	'aujos',
	'aujot',
	'aukai',
	'aukas',
	'aukām',
	'aukās',
	'aukla',
	'auklā',
	'aukle',
	'auklē',
	'aukli',
	'auklī',
	'auklu',
	'aukļa',
	'aukļi',
	'aukļu',
	'aukot',
	'aukst',
	'aulai',
	'aulam',
	'aulas',
	'aulām',
	'aulās',
	'aulim',
	'aulis',
	'aulla',
	'aulos',
	'aulus',
	'auļis',
	'auļos',
	'auļot',
	'auļus',
	'aumež',
	'aunam',
	'aunos',
	'aunus',
	'aures',
	'aurēs',
	'aurēt',
	'auris',
	'auros',
	'aurot',
	'ausās',
	'ausij',
	'ausim',
	'ausis',
	'ausit',
	'ausīm',
	'ausīs',
	'ausīt',
	'ausli',
	'auslī',
	'ausma',
	'ausmā',
	'ausmu',
	'ausos',
	'austa',
	'austā',
	'austi',
	'austo',
	'austs',
	'austu',
	'auszē',
	'aušai',
	'aušas',
	'aušām',
	'aušās',
	'aušāt',
	'aušļa',
	'aušļi',
	'aušļu',
	'aušos',
	'aušot',
	'autai',
	'autam',
	'autas',
	'autām',
	'autās',
	'autie',
	'autij',
	'autis',
	'autīm',
	'autīs',
	'autos',
	'autrs',
	'autus',
	'auzaa',
	'auzaā',
	'auzai',
	'auzas',
	'auzau',
	'auzām',
	'auzās',
	'aužam',
	'aužas',
	'aužat',
	'aužos',
	'aužot',
	'avals',
	'avani',
	'avans',
	'avarē',
	'avasa',
	'avāra',
	'avārā',
	'avāri',
	'avārs',
	'avāru',
	'avena',
	'avene',
	'avenē',
	'aveni',
	'avens',
	'aveņi',
	'aveņu',
	'avēns',
	'aviem',
	'avila',
	'avisi',
	'avizo',
	'avīši',
	'avīza',
	'avīze',
	'avīzē',
	'avīzi',
	'avīžu',
	'avola',
	'avota',
	'avotā',
	'avote',
	'avoti',
	'avots',
	'avotu',
	'azars',
	'azasa',
	'azāna',
	'azānā',
	'azāni',
	'azāns',
	'azānu',
	'azeri',
	'azīdi',
	'azoja',
	'azojā',
	'azoji',
	'azojs',
	'azoju',
	'azola',
	'azori',
	'azošu',
	'azota',
	'azotā',
	'azote',
	'azotē',
	'azoti',
	'azots',
	'azotu',
	'azova',
	'azuga',
	'azurs',
	'azūra',
	'ažāna',
	'ažēna',
	'ažūra',
	'ažūrā',
	'ažūri',
	'ažūro',
	'ažūrs',
	'ažūru',
	'ābece',
	'ābecē',
	'ābeci',
	'ābeču',
	'ābele',
	'ābelē',
	'ābeli',
	'ābels',
	'ābeļi',
	'ābeļu',
	'ābice',
	'ābola',
	'ābolā',
	'āboli',
	'ābols',
	'ābolu',
	'ābuls',
	'ādams',
	'ādats',
	'ādaži',
	'ādele',
	'ādere',
	'āderē',
	'āderi',
	'āderu',
	'ādiņa',
	'ādiņu',
	'ādžņa',
	'āgama',
	'āhene',
	'ājats',
	'ākāša',
	'āklim',
	'āklis',
	'ākļos',
	'ākļus',
	'āksta',
	'ākstā',
	'āksti',
	'āksts',
	'ākstu',
	'āķēja',
	'āķēji',
	'āķēju',
	'āķēsi',
	'āķēšu',
	'āķēta',
	'āķētā',
	'āķēti',
	'āķēto',
	'āķēts',
	'āķētu',
	'āķiem',
	'āķīga',
	'āķīgā',
	'āķīgi',
	'āķīgo',
	'āķīgs',
	'āķīgu',
	'āķīša',
	'āķīši',
	'āķīšu',
	'āķīti',
	'āķītī',
	'ālata',
	'ālava',
	'ālavā',
	'ālave',
	'ālavi',
	'ālavo',
	'ālavs',
	'ālavu',
	'ālene',
	'āleri',
	'ālsta',
	'āmars',
	'āmens',
	'āmiņa',
	'āmjos',
	'āmjus',
	'āmuli',
	'āmulī',
	'āmuļa',
	'āmuļi',
	'āmuļu',
	'āmura',
	'āmurā',
	'āmuri',
	'āmurs',
	'āmuru',
	'āpana',
	'āpsim',
	'āpsis',
	'āpšos',
	'āpšus',
	'āpupe',
	'ārati',
	'ārava',
	'ārdām',
	'ārdās',
	'ārdāt',
	'ārdēs',
	'ārdēt',
	'ārdim',
	'ārdis',
	'ārdīs',
	'ārdīt',
	'ārdos',
	'ārdot',
	'ārene',
	'ārēji',
	'ārējs',
	'āriem',
	'ārija',
	'ārijā',
	'ārijs',
	'āriju',
	'āriņa',
	'āriņi',
	'ārišu',
	'ārīga',
	'ārīgā',
	'ārīgi',
	'ārīgo',
	'ārīgs',
	'ārīgu',
	'ārīte',
	'ārods',
	'ārons',
	'ārpus',
	'ārsta',
	'ārstā',
	'ārste',
	'ārstē',
	'ārsti',
	'ārsts',
	'ārstu',
	'ārumi',
	'āržos',
	'āržus',
	'āsana',
	'ātrai',
	'ātram',
	'ātras',
	'ātrām',
	'ātrās',
	'ātrie',
	'ātros',
	'ātrus',
	'āveša',
	'āvien',
	'āvies',
	'āvīgs',
	'āvusi',
	'āvusī',
	'āvuša',
	'āvušā',
	'āvuši',
	'āvušo',
	'āvušu',
	'āzene',
	'āzēja',
	'āzēji',
	'āzēju',
	'āzēsi',
	'āzēšu',
	'āzēta',
	'āzētā',
	'āzēti',
	'āzēto',
	'āzēts',
	'āzētu',
	'āzija',
	'āzīša',
	'āzīši',
	'āzīšu',
	'āzīti',
	'āzītī',
	'āžiem',
	'baals',
	'baāls',
	'babāc',
	'babis',
	'babka',
	'babki',
	'babri',
	'bacbu',
	'bacca',
	'bačka',
	'badam',
	'badāc',
	'badām',
	'badās',
	'badāt',
	'badīs',
	'badīt',
	'bados',
	'badot',
	'badus',
	'baena',
	'bafra',
	'bafta',
	'bagio',
	'bagua',
	'bagzi',
	'bagzī',
	'bagža',
	'bagži',
	'bagžu',
	'bahāi',
	'bahts',
	'baida',
	'baidā',
	'baidi',
	'baids',
	'baidu',
	'baiga',
	'baigā',
	'baigi',
	'baigo',
	'baigs',
	'baigu',
	'baihe',
	'baihu',
	'baija',
	'baika',
	'baikā',
	'baiki',
	'baiks',
	'baiku',
	'baila',
	'baile',
	'bailē',
	'baili',
	'bails',
	'baiļa',
	'baiļā',
	'baiļi',
	'baiļo',
	'baiļš',
	'baiļu',
	'baime',
	'bairi',
	'baisa',
	'baisā',
	'baisi',
	'baiso',
	'baiss',
	'baisu',
	'baita',
	'baitā',
	'baiti',
	'baits',
	'baitu',
	'bakaa',
	'bakaā',
	'bakai',
	'bakam',
	'bakas',
	'bakau',
	'bakām',
	'bakbo',
	'bakeu',
	'bakhs',
	'bakos',
	'bakss',
	'bakst',
	'bakši',
	'bakti',
	'bakus',
	'baķes',
	'baķis',
	'balas',
	'balda',
	'balde',
	'balēs',
	'balēt',
	'balga',
	'balha',
	'balja',
	'balka',
	'balks',
	'balle',
	'ballē',
	'balli',
	'ballu',
	'balod',
	'balos',
	'balot',
	'balsa',
	'balse',
	'balsī',
	'balso',
	'balss',
	'balta',
	'baltā',
	'balti',
	'balto',
	'balts',
	'baltu',
	'balus',
	'balva',
	'balvā',
	'balve',
	'balvi',
	'balvu',
	'balze',
	'baļķa',
	'baļķe',
	'baļķi',
	'baļķī',
	'baļķu',
	'baļļa',
	'baļļā',
	'baļļu',
	'bamba',
	'banda',
	'bandā',
	'bande',
	'bandi',
	'bands',
	'bandu',
	'banga',
	'bangā',
	'bangi',
	'bango',
	'bangs',
	'bangu',
	'banis',
	'banja',
	'banjo',
	'banka',
	'bankā',
	'banko',
	'banks',
	'banku',
	'banši',
	'banšu',
	'bante',
	'bantē',
	'banti',
	'bants',
	'bantu',
	'baņķa',
	'bapsi',
	'bapsī',
	'bapša',
	'bapši',
	'bapšu',
	'baram',
	'baras',
	'barat',
	'barbe',
	'barbi',
	'barbs',
	'barcs',
	'barča',
	'barda',
	'bardā',
	'bardi',
	'bardo',
	'bards',
	'bardu',
	'barga',
	'bargā',
	'bargi',
	'bargo',
	'bargs',
	'bargu',
	'barka',
	'barkā',
	'barku',
	'barma',
	'barno',
	'barns',
	'baros',
	'barot',
	'barta',
	'barus',
	'barža',
	'basai',
	'basam',
	'basas',
	'basām',
	'basās',
	'basic',
	'basie',
	'basij',
	'basis',
	'basīm',
	'basīs',
	'baska',
	'baskā',
	'baski',
	'basks',
	'basku',
	'basma',
	'basmā',
	'basmu',
	'basņa',
	'basos',
	'basra',
	'basso',
	'basta',
	'basus',
	'baška',
	'baški',
	'bašķi',
	'bašļi',
	'bašņa',
	'Batai',
	'Batas',
	'Batām',
	'Batās',
	'batls',
	'batņi',
	'batri',
	'batun',
	'bauči',
	'bauda',
	'baudā',
	'baudi',
	'baudu',
	'bauga',
	'baukš',
	'baule',
	'bauli',
	'bauls',
	'bauma',
	'baumā',
	'baumo',
	'baumu',
	'bauņi',
	'bauņu',
	'bauri',
	'bauro',
	'bauru',
	'bauza',
	'bauze',
	'bauža',
	'bažaa',
	'bažaā',
	'bažai',
	'bažas',
	'bažau',
	'bākai',
	'bākas',
	'bākām',
	'bākās',
	'bālai',
	'bālam',
	'bālas',
	'bālām',
	'bālās',
	'bālēs',
	'bālēt',
	'bālie',
	'bālin',
	'bālos',
	'bālus',
	'bānis',
	'bārai',
	'bāram',
	'bāras',
	'bārām',
	'bārās',
	'bārāt',
	'bārda',
	'bārdā',
	'bārdu',
	'bārie',
	'bārim',
	'bāris',
	'bāros',
	'bārsi',
	'bāršu',
	'bārta',
	'bārtā',
	'bārti',
	'bārto',
	'bārts',
	'bārtu',
	'bārus',
	'Bātai',
	'Bātas',
	'Bātām',
	'Bātās',
	'bāzām',
	'bāzās',
	'bāzāt',
	'bāzei',
	'bāzes',
	'bāzēm',
	'bāzēs',
	'bāzēt',
	'bāzis',
	'bāzīs',
	'bāzos',
	'bāzta',
	'bāztā',
	'bāzti',
	'bāzto',
	'bāzts',
	'bāztu',
	'bāžam',
	'bāžas',
	'bāžat',
	'bāžos',
	'bāžot',
	'bebis',
	'bebra',
	'bebrā',
	'bebri',
	'bebrs',
	'bebru',
	'bebža',
	'bedam',
	'bedat',
	'bedām',
	'bedāt',
	'bedis',
	'bedīs',
	'bedīt',
	'bedot',
	'bedre',
	'bedrē',
	'bedri',
	'bedrs',
	'bedru',
	'bedža',
	'bedži',
	'begaķ',
	'beģis',
	'behra',
	'beibe',
	'beice',
	'beicē',
	'beici',
	'beiču',
	'beida',
	'beidz',
	'beigs',
	'beigt',
	'beika',
	'beils',
	'beira',
	'beite',
	'beits',
	'beiži',
	'bejam',
	'bejos',
	'bejus',
	'bekai',
	'bekam',
	'bekas',
	'bekām',
	'bekās',
	'bekli',
	'bekos',
	'bekot',
	'bekši',
	'bekus',
	'belam',
	'belau',
	'belaz',
	'belci',
	'belga',
	'belis',
	'bella',
	'belos',
	'belts',
	'belus',
	'belza',
	'belzi',
	'belzt',
	'belzu',
	'belžu',
	'beļci',
	'beļeg',
	'beļģi',
	'beļģu',
	'bemba',
	'bembi',
	'benbu',
	'benči',
	'benčs',
	'benda',
	'bende',
	'bendē',
	'bendi',
	'benga',
	'benjē',
	'bensi',
	'benša',
	'benue',
	'benze',
	'benžu',
	'beņķa',
	'beņķe',
	'beram',
	'beras',
	'berat',
	'berda',
	'beres',
	'berga',
	'bergs',
	'berģi',
	'beris',
	'berit',
	'berka',
	'berkā',
	'berma',
	'berne',
	'beros',
	'berot',
	'berra',
	'berst',
	'berta',
	'berti',
	'berza',
	'berze',
	'berzē',
	'berzi',
	'berzt',
	'berzu',
	'beržē',
	'beržu',
	'besis',
	'besīt',
	'besta',
	'bestā',
	'besti',
	'besto',
	'bests',
	'bestu',
	'bezda',
	'bezde',
	'bezdē',
	'bēbis',
	'bēcis',
	'bēdas',
	'bēdās',
	'bēdāt',
	'bēgam',
	'bēgat',
	'bēgām',
	'bēgāt',
	'bēgle',
	'bēglē',
	'bēgli',
	'bēglī',
	'bēgļa',
	'bēgļi',
	'bēgļu',
	'bēgot',
	'bēgsi',
	'bēgšu',
	'bēgta',
	'bēgtā',
	'bēgti',
	'bēgto',
	'bēgts',
	'bēgtu',
	'bēņas',
	'bērai',
	'bēram',
	'bēras',
	'bērām',
	'bērās',
	'bērāt',
	'bērea',
	'bēreā',
	'bērei',
	'bēres',
	'bēreu',
	'bērie',
	'bērim',
	'bēris',
	'bērka',
	'bērli',
	'bērna',
	'bērnā',
	'bērni',
	'bērnī',
	'bērns',
	'bērnu',
	'bēros',
	'bērsi',
	'bēršu',
	'bērta',
	'bērtā',
	'bērti',
	'bērto',
	'bērts',
	'bērtu',
	'bērus',
	'bērza',
	'bērzā',
	'bērze',
	'bērzi',
	'bērzs',
	'bērzu',
	'bērži',
	'bēšai',
	'bēšam',
	'bēšas',
	'bēšām',
	'bēšās',
	'bēšie',
	'bēšos',
	'bēšus',
	'bētai',
	'bētas',
	'bētām',
	'bētās',
	'bēzjē',
	'bhaga',
	'bhaja',
	'bhava',
	'bhīma',
	'bhoga',
	'bhogi',
	'bhogs',
	'bibis',
	'bibla',
	'bička',
	'bičke',
	'bičot',
	'bidlo',
	'bidzi',
	'bidzī',
	'bidža',
	'bidži',
	'bidžu',
	'bieda',
	'biedā',
	'biedē',
	'biedi',
	'bieds',
	'biedu',
	'biešu',
	'biete',
	'bietē',
	'bieti',
	'bieza',
	'biezā',
	'bieze',
	'biezē',
	'biezi',
	'biezo',
	'biezs',
	'biezu',
	'bieža',
	'biežā',
	'bieži',
	'biežo',
	'biežs',
	'biežu',
	'biģis',
	'bijām',
	'bijās',
	'bijāt',
	'bijis',
	'bijos',
	'bijou',
	'bikiņ',
	'bikla',
	'biklā',
	'bikli',
	'biklo',
	'bikls',
	'biklu',
	'biksa',
	'biksi',
	'bikss',
	'bikši',
	'biktē',
	'bikts',
	'biķis',
	'bilda',
	'bilde',
	'bildē',
	'bildi',
	'bildu',
	'bilga',
	'bilge',
	'bilha',
	'billa',
	'bille',
	'bills',
	'bilst',
	'bilžu',
	'biļļu',
	'bimba',
	'bimbā',
	'bimbo',
	'bimco',
	'bimss',
	'binah',
	'binde',
	'bindi',
	'bindu',
	'binga',
	'bingo',
	'bingu',
	'binta',
	'binte',
	'bintu',
	'bioko',
	'bioma',
	'biosa',
	'biosā',
	'biosi',
	'bioss',
	'biosu',
	'biota',
	'bioti',
	'bioze',
	'birām',
	'birāt',
	'birda',
	'birds',
	'birga',
	'biris',
	'birka',
	'birkā',
	'birks',
	'birku',
	'birma',
	'birra',
	'birsi',
	'birst',
	'biršu',
	'birta',
	'birtā',
	'birti',
	'birto',
	'birts',
	'birtu',
	'birza',
	'birze',
	'birzē',
	'birzi',
	'birzī',
	'birzs',
	'birzt',
	'birzu',
	'birža',
	'biržā',
	'birži',
	'biržo',
	'biržu',
	'bisbī',
	'bisei',
	'bises',
	'bisēm',
	'bisēs',
	'bison',
	'biste',
	'bišiņ',
	'bišīt',
	'biški',
	'bišku',
	'bišķi',
	'bitam',
	'bitča',
	'bitei',
	'bites',
	'bitēm',
	'bitēs',
	'bitka',
	'bitos',
	'bitus',
	'bizei',
	'bizes',
	'bizēm',
	'bizēs',
	'bizos',
	'bizot',
	'bīdām',
	'bīdās',
	'bīdāt',
	'bīdei',
	'bīdes',
	'bīdēm',
	'bīdēs',
	'bīdīs',
	'bīdīt',
	'bīdos',
	'bīdot',
	'bīgla',
	'bīgls',
	'bīles',
	'bīšos',
	'bītam',
	'bītin',
	'bītli',
	'bītls',
	'bītos',
	'bītus',
	'bjala',
	'bjazs',
	'bjora',
	'bjūta',
	'blace',
	'blaka',
	'blaks',
	'blakš',
	'blaku',
	'blamē',
	'blans',
	'blast',
	'blata',
	'blatā',
	'blate',
	'blati',
	'blats',
	'blatu',
	'blazē',
	'blaža',
	'blāda',
	'blākš',
	'blāķa',
	'blāķi',
	'blāķī',
	'blāķu',
	'blāva',
	'blāvā',
	'blāvi',
	'blāvo',
	'blāvs',
	'blāvu',
	'bleda',
	'blefa',
	'blefā',
	'blefi',
	'blefo',
	'blefs',
	'blefu',
	'bleķa',
	'bleķi',
	'bleķī',
	'bleķo',
	'bleķu',
	'blenz',
	'blenž',
	'blese',
	'blēde',
	'blēdē',
	'blēdi',
	'blēdī',
	'blēja',
	'blēji',
	'blēju',
	'blēņa',
	'blēņi',
	'blēsi',
	'blēšu',
	'blēta',
	'blētā',
	'blēti',
	'blēto',
	'blēts',
	'blētu',
	'blēža',
	'blēži',
	'blēžu',
	'blice',
	'bliez',
	'bliež',
	'blija',
	'bliks',
	'bline',
	'blins',
	'bliņa',
	'bliņā',
	'bliņu',
	'blist',
	'bliza',
	'blīda',
	'blīdi',
	'blīdu',
	'blīkš',
	'blīst',
	'blīva',
	'blīvā',
	'blīve',
	'blīvē',
	'blīvi',
	'blīvo',
	'blīvs',
	'blīvu',
	'blīza',
	'blīzi',
	'blīzt',
	'blīzu',
	'blods',
	'blogs',
	'bloka',
	'blokā',
	'bloki',
	'bloks',
	'bloku',
	'bloķē',
	'blome',
	'blomi',
	'bloņe',
	'bloss',
	'blote',
	'blots',
	'blues',
	'bluks',
	'bluķa',
	'bluķi',
	'bluķī',
	'bluķu',
	'blusa',
	'blusā',
	'blusu',
	'blūds',
	'blūks',
	'blūkš',
	'blūme',
	'blūms',
	'blūza',
	'blūzā',
	'blūze',
	'blūzē',
	'blūzi',
	'blūzs',
	'blūzu',
	'blūžu',
	'bļadj',
	'bļaha',
	'bļaka',
	'bļaši',
	'bļauj',
	'bļaus',
	'bļaut',
	'bļava',
	'bļāva',
	'bļāvi',
	'bļāvu',
	'bļini',
	'bļins',
	'bļoda',
	'bļodā',
	'bļodu',
	'boass',
	'boāss',
	'bobbi',
	'bobim',
	'bobis',
	'bobja',
	'bobji',
	'bobju',
	'bobra',
	'bocca',
	'bočka',
	'bodei',
	'bodes',
	'bodēm',
	'bodēs',
	'bodha',
	'bodhi',
	'boedo',
	'boeuf',
	'bohai',
	'bohņa',
	'boika',
	'boila',
	'boise',
	'bojai',
	'bojam',
	'bojas',
	'bojām',
	'bojās',
	'bojāt',
	'bojen',
	'bojos',
	'bojus',
	'bokas',
	'boksa',
	'boksā',
	'boksi',
	'bokss',
	'boksu',
	'bokša',
	'bokši',
	'bokšs',
	'boķis',
	'boķīt',
	'bolām',
	'bolās',
	'bolāt',
	'bolei',
	'boles',
	'bolēm',
	'bolēs',
	'bolga',
	'bolis',
	'bolīs',
	'bolīt',
	'bolos',
	'bolot',
	'bolta',
	'bolus',
	'bolvi',
	'bomba',
	'bombo',
	'bomim',
	'bomis',
	'bomja',
	'bomji',
	'bomju',
	'bomzi',
	'bomzī',
	'bomža',
	'bomžā',
	'bomži',
	'bomžs',
	'bomžu',
	'bonai',
	'bonao',
	'bonas',
	'bonām',
	'bonās',
	'bonda',
	'bondi',
	'bondī',
	'bonds',
	'bonēs',
	'bonēt',
	'bongo',
	'bonis',
	'bonka',
	'bonna',
	'bonne',
	'bonni',
	'bonpo',
	'bonza',
	'bonzā',
	'bonzu',
	'boram',
	'borba',
	'borch',
	'borda',
	'borde',
	'bordo',
	'borēt',
	'borgo',
	'borgs',
	'boris',
	'borka',
	'borna',
	'borne',
	'borno',
	'boros',
	'borra',
	'borša',
	'borta',
	'bortā',
	'borte',
	'borti',
	'borts',
	'bortu',
	'borus',
	'bosam',
	'bosna',
	'bosos',
	'bosts',
	'bosus',
	'botas',
	'botes',
	'botva',
	'boule',
	'bouza',
	'boves',
	'bozām',
	'bozās',
	'bozāt',
	'bozei',
	'bozes',
	'bozēm',
	'bozēs',
	'bozēt',
	'bozis',
	'bozīs',
	'bozos',
	'bozta',
	'boztā',
	'bozti',
	'bozto',
	'bozts',
	'boztu',
	'božam',
	'božas',
	'božat',
	'božos',
	'božot',
	'brace',
	'brača',
	'bračā',
	'braču',
	'brada',
	'bradā',
	'brads',
	'bradu',
	'braga',
	'brags',
	'braka',
	'braki',
	'braks',
	'brakš',
	'brakt',
	'braku',
	'brama',
	'brans',
	'brasa',
	'brase',
	'brasi',
	'brasī',
	'brass',
	'brasu',
	'braša',
	'brašā',
	'braši',
	'brašo',
	'brašs',
	'brašu',
	'brats',
	'brauc',
	'braut',
	'bravo',
	'brāga',
	'brāgā',
	'brāgi',
	'brāgu',
	'brāks',
	'brākš',
	'brāķa',
	'brāķē',
	'brāķi',
	'brāķī',
	'brāķu',
	'brāli',
	'brālī',
	'brāļa',
	'brāļi',
	'brāļu',
	'brāma',
	'brāza',
	'brāzi',
	'brāzt',
	'brāzu',
	'brāžu',
	'brčko',
	'break',
	'breča',
	'breda',
	'brede',
	'brega',
	'breja',
	'breks',
	'brena',
	'brese',
	'bress',
	'breša',
	'breve',
	'breza',
	'brēca',
	'brēci',
	'brēcu',
	'brēga',
	'brēka',
	'brēkā',
	'brēks',
	'brēkt',
	'brēku',
	'brēķa',
	'brēķi',
	'brēķī',
	'brēķu',
	'brica',
	'brice',
	'brici',
	'brida',
	'bridi',
	'bridu',
	'brien',
	'bries',
	'briga',
	'brigā',
	'brige',
	'brigi',
	'brigs',
	'brigu',
	'briģe',
	'briģi',
	'brika',
	'briks',
	'brikš',
	'briku',
	'brist',
	'brišs',
	'brita',
	'britā',
	'brite',
	'briti',
	'brits',
	'britu',
	'briva',
	'briza',
	'brīda',
	'brīdi',
	'brīdī',
	'brīks',
	'brīkš',
	'brīns',
	'brīva',
	'brīvā',
	'brīve',
	'brīvē',
	'brīvi',
	'brīvo',
	'brīvs',
	'brīvu',
	'brīze',
	'brīzē',
	'brīzi',
	'brīzu',
	'brīža',
	'brīži',
	'brīžu',
	'broda',
	'brodi',
	'brods',
	'brogi',
	'broka',
	'broki',
	'broli',
	'broma',
	'bromā',
	'bromi',
	'broms',
	'bromu',
	'brona',
	'broņa',
	'broņā',
	'broņu',
	'bross',
	'broša',
	'brošā',
	'brošē',
	'brošs',
	'brošu',
	'brozs',
	'broži',
	'bruds',
	'bruga',
	'bruģa',
	'bruģē',
	'bruģi',
	'bruģī',
	'bruģu',
	'bruka',
	'brukā',
	'bruki',
	'bruks',
	'brukt',
	'bruku',
	'bruno',
	'bruņa',
	'bruņi',
	'bruņo',
	'bruņu',
	'brusa',
	'brusā',
	'brusi',
	'bruss',
	'brusu',
	'bruto',
	'bruts',
	'bruža',
	'bružā',
	'bruži',
	'brūce',
	'brūcē',
	'brūci',
	'brūču',
	'brūka',
	'brūku',
	'brūna',
	'brūnā',
	'brūne',
	'brūnē',
	'brūni',
	'brūnī',
	'brūno',
	'brūns',
	'brūnu',
	'brūņa',
	'brūņi',
	'brūņu',
	'brūšu',
	'brūte',
	'brūtē',
	'brūti',
	'brūvē',
	'brūze',
	'brūzi',
	'brūzī',
	'brūža',
	'brūži',
	'brūžu',
	'bualo',
	'bubis',
	'bubns',
	'bucis',
	'bučai',
	'bučas',
	'bučām',
	'bučās',
	'bučka',
	'bučos',
	'bučot',
	'budha',
	'budhi',
	'budka',
	'budva',
	'budze',
	'budzi',
	'budzī',
	'budža',
	'budži',
	'budžu',
	'bufam',
	'buffo',
	'bufos',
	'bufus',
	'bugri',
	'buģis',
	'buhlo',
	'buhte',
	'buiva',
	'bukai',
	'bukam',
	'bukas',
	'bukām',
	'bukās',
	'bukāt',
	'buklē',
	'buknī',
	'bukņī',
	'bukos',
	'bukot',
	'buksa',
	'bukse',
	'buksē',
	'buksi',
	'buksī',
	'bukša',
	'bukši',
	'bukšs',
	'bukšu',
	'bukta',
	'bukte',
	'bukts',
	'bukus',
	'buķis',
	'bulai',
	'bulam',
	'bulas',
	'bulām',
	'bulās',
	'bulba',
	'bulbe',
	'bulbs',
	'bulča',
	'bulis',
	'bulka',
	'bulkā',
	'bulki',
	'bulks',
	'bulku',
	'bulla',
	'bullā',
	'bulli',
	'bullī',
	'bulls',
	'bullu',
	'bulļa',
	'bulļi',
	'bulļu',
	'bulos',
	'bulta',
	'bultā',
	'bulte',
	'bultē',
	'bults',
	'bultu',
	'bulus',
	'buļba',
	'buļļi',
	'bumam',
	'bumba',
	'bumbā',
	'bumbi',
	'bumbu',
	'bumos',
	'bumus',
	'bunce',
	'bunči',
	'bunds',
	'bunga',
	'bungo',
	'bunka',
	'bunta',
	'buntā',
	'bunte',
	'bunti',
	'bunts',
	'buntu',
	'buoba',
	'burai',
	'buram',
	'buras',
	'burat',
	'burām',
	'burās',
	'burāt',
	'burba',
	'burbu',
	'burda',
	'burga',
	'burgs',
	'buris',
	'burka',
	'burkā',
	'burkš',
	'burku',
	'buros',
	'burot',
	'bursa',
	'bursi',
	'burša',
	'buršā',
	'burši',
	'buršs',
	'buršu',
	'burta',
	'burtā',
	'burti',
	'burto',
	'burts',
	'burtu',
	'burun',
	'burve',
	'burvē',
	'burvi',
	'burvī',
	'burza',
	'burzi',
	'burzu',
	'burža',
	'buska',
	'busko',
	'buste',
	'busto',
	'bušma',
	'butei',
	'buteo',
	'butes',
	'butēm',
	'butēs',
	'butka',
	'butkā',
	'butku',
	'buzis',
	'būdai',
	'būdas',
	'būdām',
	'būdās',
	'būkai',
	'būkas',
	'būkām',
	'būkās',
	'būkša',
	'būkši',
	'būkšī',
	'būkšķ',
	'būkšu',
	'būrām',
	'būrās',
	'būrāt',
	'būrim',
	'būris',
	'būros',
	'būrus',
	'būsim',
	'būsis',
	'būsit',
	'būšot',
	'būtin',
	'būtla',
	'būtne',
	'būtnē',
	'būtni',
	'būtņu',
	'būvei',
	'būves',
	'būvēm',
	'būvēs',
	'būvēt',
	'būvju',
	'caici',
	'cakai',
	'cakas',
	'cakām',
	'cakās',
	'calla',
	'calms',
	'canga',
	'cangā',
	'cangu',
	'canis',
	'canna',
	'canto',
	'capen',
	'capra',
	'caram',
	'caros',
	'carte',
	'carum',
	'carus',
	'cassa',
	'casus',
	'cauna',
	'caunā',
	'caune',
	'caunu',
	'cauņi',
	'caura',
	'caurā',
	'cauri',
	'cauro',
	'caurs',
	'cauru',
	'causa',
	'cavia',
	'cālim',
	'cālis',
	'cāļos',
	'cāļus',
	'ccitt',
	'cebra',
	'cebus',
	'cedēt',
	'cedri',
	'ceham',
	'cehos',
	'cehus',
	'ceiba',
	'ceies',
	'celam',
	'celim',
	'celis',
	'celka',
	'celkā',
	'celku',
	'cella',
	'celle',
	'cellē',
	'celli',
	'celļu',
	'celma',
	'celmā',
	'celmi',
	'celms',
	'celmu',
	'celos',
	'celsi',
	'celšu',
	'celta',
	'celtā',
	'celte',
	'celti',
	'celto',
	'celts',
	'celtu',
	'celus',
	'ceļam',
	'ceļas',
	'ceļat',
	'ceļos',
	'ceļot',
	'ceļus',
	'cemme',
	'cemmē',
	'cemmi',
	'cempi',
	'cenai',
	'cenas',
	'cenām',
	'cenās',
	'centa',
	'centā',
	'centi',
	'cento',
	'cents',
	'centu',
	'cenza',
	'cenzā',
	'cenzē',
	'cenzi',
	'cenzs',
	'cenzu',
	'cepam',
	'cepas',
	'cepat',
	'cepām',
	'cepās',
	'cepāt',
	'cepin',
	'cepis',
	'cepli',
	'ceplī',
	'cepļa',
	'cepļi',
	'cepļu',
	'cepol',
	'cepos',
	'cepot',
	'cepsi',
	'cepšu',
	'cepta',
	'ceptā',
	'cepti',
	'cepto',
	'cepts',
	'ceptu',
	'ceraa',
	'ceraā',
	'cerai',
	'ceram',
	'ceras',
	'cerat',
	'cerau',
	'cerea',
	'cereā',
	'cerei',
	'ceres',
	'cereu',
	'cerēs',
	'cerēt',
	'ceros',
	'cerot',
	'certa',
	'cerus',
	'ceses',
	'cests',
	'cetri',
	'cēlai',
	'cēlam',
	'cēlas',
	'cēlām',
	'cēlās',
	'cēlāt',
	'cēlie',
	'cēlis',
	'cēlos',
	'cēlus',
	'cēris',
	'cērme',
	'cērmē',
	'cērmi',
	'cērpa',
	'cērpe',
	'cērps',
	'cērpu',
	'cēršu',
	'cērte',
	'cērtē',
	'cērti',
	'cērtu',
	'cēses',
	'cēsis',
	'cēsta',
	'chara',
	'chilo',
	'ciāna',
	'ciāns',
	'cibai',
	'cibas',
	'cibām',
	'cibās',
	'cibis',
	'cibla',
	'cicer',
	'ciema',
	'ciemā',
	'ciemi',
	'ciems',
	'ciemu',
	'ciena',
	'cienā',
	'cienī',
	'ciens',
	'cieņa',
	'cieņā',
	'cieņu',
	'ciesa',
	'ciesā',
	'ciest',
	'ciesu',
	'cieša',
	'ciešā',
	'cieši',
	'ciešo',
	'ciešs',
	'ciešu',
	'cieta',
	'cietā',
	'ciete',
	'cietē',
	'cieti',
	'cieto',
	'ciets',
	'cietu',
	'cigli',
	'ciglī',
	'cigļa',
	'cigļi',
	'cigļu',
	'cigun',
	'cikas',
	'cikām',
	'cikla',
	'ciklā',
	'cikli',
	'cikls',
	'ciklu',
	'cikos',
	'ciķis',
	'cilas',
	'cilās',
	'cilāt',
	'cilda',
	'cildi',
	'cilli',
	'cilme',
	'cilmē',
	'cilmi',
	'cilni',
	'cilnī',
	'cilpa',
	'cilpā',
	'cilpo',
	'cilpu',
	'cilšu',
	'cilta',
	'cilti',
	'ciltī',
	'cilts',
	'cilus',
	'ciļņa',
	'ciļņi',
	'ciļņu',
	'cimda',
	'cimdā',
	'cimdi',
	'cimds',
	'cimdu',
	'cimza',
	'cimze',
	'cinca',
	'cinci',
	'cindi',
	'cinga',
	'cingā',
	'cingu',
	'cinim',
	'cinis',
	'cinka',
	'cinkā',
	'cinki',
	'cinko',
	'cinks',
	'cinku',
	'cinna',
	'cinne',
	'cinte',
	'ciņos',
	'ciņus',
	'cioff',
	'cioms',
	'ciona',
	'cipāt',
	'cipst',
	'cipus',
	'circa',
	'cirea',
	'ciris',
	'cirīt',
	'cirka',
	'cirkā',
	'cirki',
	'cirks',
	'cirku',
	'cirma',
	'cirpa',
	'cirpe',
	'cirpē',
	'cirpi',
	'cirps',
	'cirpt',
	'cirpu',
	'cirst',
	'cirši',
	'ciršu',
	'cirta',
	'cirtā',
	'cirte',
	'cirtē',
	'cirti',
	'cirto',
	'cirtu',
	'cirvi',
	'cirvī',
	'cisaa',
	'cisaā',
	'cisac',
	'cisai',
	'cisas',
	'cisau',
	'cisāt',
	'cisji',
	'ciska',
	'ciskā',
	'cisku',
	'cissa',
	'cista',
	'cistā',
	'cists',
	'cistu',
	'citad',
	'citād',
	'cites',
	'citēs',
	'citēt',
	'citur',
	'citūr',
	'civis',
	'cizim',
	'cizis',
	'cižos',
	'cižus',
	'cīķis',
	'cīnās',
	'cīnos',
	'cīņai',
	'cīņas',
	'cīņām',
	'cīņās',
	'clerc',
	'clerk',
	'cloze',
	'coach',
	'cobol',
	'cocom',
	'cocos',
	'coini',
	'coins',
	'colla',
	'collā',
	'colle',
	'colls',
	'collu',
	'comes',
	'comte',
	'congi',
	'congs',
	'conte',
	'copec',
	'copēt',
	'copis',
	'copla',
	'coppe',
	'coram',
	'corda',
	'coris',
	'corno',
	'corps',
	'corso',
	'crack',
	'crash',
	'credo',
	'creme',
	'crepe',
	'cresa',
	'crest',
	'cuina',
	'cuini',
	'cuinu',
	'culpa',
	'cupis',
	'cūces',
	'cūcis',
	'cūkai',
	'cūkas',
	'cūkām',
	'cūkās',
	'cūkos',
	'cūkot',
	'cvets',
	'cvika',
	'cvora',
	'čabam',
	'čabas',
	'čabat',
	'čabēs',
	'čabēt',
	'čabot',
	'čabum',
	'čačas',
	'čačis',
	'čadca',
	'čadra',
	'čadrā',
	'čadru',
	'čagaa',
	'čagaā',
	'čagai',
	'čagas',
	'čagau',
	'čagās',
	'čagāt',
	'čaiba',
	'čaika',
	'čakao',
	'čakla',
	'čaklā',
	'čakli',
	'čaklo',
	'čakls',
	'čaklu',
	'čakra',
	'čakst',
	'čakši',
	'čakšu',
	'čalai',
	'čalas',
	'čalām',
	'čalās',
	'čalis',
	'čalīt',
	'čalma',
	'čalmā',
	'čalmu',
	'čalos',
	'čalot',
	'čalus',
	'čambi',
	'čamda',
	'čamdi',
	'čamdo',
	'čamdu',
	'čamma',
	'čampa',
	'čampu',
	'čanga',
	'čanka',
	'čanša',
	'čapus',
	'čarka',
	'čarkā',
	'čarki',
	'čarku',
	'čarna',
	'čarne',
	'časis',
	'časka',
	'čatni',
	'čatot',
	'čauga',
	'čaugā',
	'čaugi',
	'čaugo',
	'čaugs',
	'čaugu',
	'čauki',
	'čaula',
	'čaulā',
	'čaulu',
	'čauna',
	'čaupe',
	'čāgas',
	'čāpās',
	'čāpāt',
	'čāpos',
	'čāpot',
	'čārpa',
	'čāzim',
	'čāzis',
	'čāžos',
	'čāžus',
	'čechi',
	'čedžu',
	'čeins',
	'čekam',
	'čekos',
	'čekot',
	'čekus',
	'čella',
	'čellā',
	'čelli',
	'čellī',
	'čello',
	'čells',
	'čellu',
	'čelļa',
	'čelļi',
	'čelļu',
	'čemam',
	'čemos',
	'čemus',
	'čendu',
	'čenga',
	'čengs',
	'čeres',
	'černa',
	'česīt',
	'česka',
	'česke',
	'česki',
	'česno',
	'češme',
	'četri',
	'čhēla',
	'čhova',
	'čibči',
	'čiepa',
	'čiepi',
	'čiepj',
	'čieps',
	'čiept',
	'čiepu',
	'čikam',
	'čikāt',
	'čikle',
	'čikos',
	'čikse',
	'čikus',
	'čiloe',
	'činai',
	'činas',
	'činām',
	'činās',
	'činča',
	'činīt',
	'činka',
	'činks',
	'čipēs',
	'čipēt',
	'čipos',
	'čipot',
	'čipsa',
	'čipsā',
	'čipsi',
	'čipss',
	'čipsu',
	'čirka',
	'čirks',
	'čista',
	'čitre',
	'čiuļi',
	'čības',
	'čīčas',
	'čīčis',
	'čīgās',
	'čīgāt',
	'čīkst',
	'čīlei',
	'čīles',
	'čīlēm',
	'čīlēs',
	'člopa',
	'čoini',
	'čokam',
	'čokos',
	'čokus',
	'čolka',
	'čooju',
	'čorba',
	'čorlu',
	'čorna',
	'čorts',
	'čotka',
	'čotko',
	'čotna',
	'čubas',
	'čubis',
	'čučam',
	'čučat',
	'čučāt',
	'čučēs',
	'čučēt',
	'čučot',
	'čuhņa',
	'čukča',
	'čukčā',
	'čukči',
	'čukčs',
	'čukču',
	'čukna',
	'čukst',
	'čulča',
	'čulga',
	'čulgā',
	'čulgu',
	'čulka',
	'čumam',
	'čumat',
	'čumēs',
	'čumēt',
	'čumot',
	'čunga',
	'čunku',
	'čupai',
	'čupas',
	'čupām',
	'čupās',
	'čupra',
	'čuprā',
	'čupri',
	'čuprs',
	'čupru',
	'čurai',
	'čuras',
	'čurām',
	'čurās',
	'čurāt',
	'čurka',
	'čurks',
	'čurni',
	'čurnu',
	'čusli',
	'čuslī',
	'čuška',
	'čušļa',
	'čušļi',
	'čušļu',
	'čužai',
	'čužas',
	'čužām',
	'čužās',
	'čūkst',
	'čūlai',
	'čūlas',
	'čūlām',
	'čūlās',
	'čūlāt',
	'čūlos',
	'čūlot',
	'čūska',
	'čūskā',
	'čūsku',
	'dabai',
	'dabar',
	'dabas',
	'dabām',
	'dabās',
	'dabīt',
	'dabon',
	'dabūn',
	'dabūs',
	'dabūt',
	'dačka',
	'dadzi',
	'dadzī',
	'dadzu',
	'dadža',
	'dadži',
	'dadžu',
	'dafne',
	'dagda',
	'dagla',
	'daglā',
	'dagli',
	'daglī',
	'daglo',
	'dagls',
	'daglu',
	'dagļa',
	'dagļi',
	'dagļu',
	'daģis',
	'dahla',
	'dahme',
	'dahra',
	'daibe',
	'daiči',
	'daiet',
	'daiga',
	'daija',
	'daika',
	'daikā',
	'daiki',
	'daiks',
	'daiku',
	'daile',
	'dailē',
	'daili',
	'daiļa',
	'daiļā',
	'daiļi',
	'daiļo',
	'daiļš',
	'daiļu',
	'daims',
	'daina',
	'dainā',
	'daino',
	'dainu',
	'daiņa',
	'daiņo',
	'dairi',
	'dairs',
	'daiva',
	'daivā',
	'daivi',
	'daivu',
	'dajoš',
	'dajot',
	'daksa',
	'dakša',
	'dakšā',
	'dakšo',
	'dakšu',
	'dakte',
	'dakts',
	'dalām',
	'dalās',
	'dalāt',
	'dalba',
	'dalbe',
	'dalis',
	'dalīs',
	'dalīt',
	'dalma',
	'daloa',
	'dalos',
	'dalot',
	'daļai',
	'daļas',
	'daļām',
	'daļās',
	'dambē',
	'dambi',
	'dambī',
	'damme',
	'dampi',
	'dampī',
	'danca',
	'danci',
	'dancī',
	'danco',
	'danča',
	'danči',
	'danču',
	'danda',
	'dandī',
	'danga',
	'dangā',
	'dangs',
	'dangu',
	'danis',
	'danka',
	'danli',
	'dansa',
	'danse',
	'danza',
	'daram',
	'darām',
	'darās',
	'darāt',
	'darba',
	'darbā',
	'darbi',
	'darbs',
	'darbu',
	'dards',
	'dargi',
	'darīs',
	'darīt',
	'darma',
	'daros',
	'darot',
	'darsī',
	'darva',
	'darvā',
	'darvo',
	'darvu',
	'dasju',
	'dasti',
	'dasto',
	'daška',
	'datēs',
	'datēt',
	'datne',
	'datnē',
	'datni',
	'datņu',
	'daube',
	'dauda',
	'daudz',
	'dauga',
	'daugi',
	'dauna',
	'dauni',
	'dauri',
	'dauru',
	'dausa',
	'dausā',
	'dausu',
	'dauza',
	'dauzi',
	'dauzu',
	'daūds',
	'davai',
	'davao',
	'dažai',
	'dādas',
	'dādra',
	'dālet',
	'dāļās',
	'dāļāt',
	'dāmai',
	'dāmas',
	'dāmām',
	'dāmās',
	'dānim',
	'dānis',
	'dāņos',
	'dāņus',
	'dārbi',
	'dārda',
	'dārdā',
	'dārdi',
	'dārds',
	'dārdu',
	'dārga',
	'dārgi',
	'dārgs',
	'dāris',
	'dārks',
	'dārte',
	'dārti',
	'dārts',
	'dārza',
	'dārzā',
	'dārzi',
	'dārzs',
	'dārzu',
	'dāsas',
	'dāsna',
	'dāsnā',
	'dāsni',
	'dāsno',
	'dāsns',
	'dāsnu',
	'dāvās',
	'dāvāt',
	'dāvji',
	'dbase',
	'debre',
	'decca',
	'dedza',
	'dedze',
	'dedzi',
	'dedzu',
	'degai',
	'degas',
	'degām',
	'degās',
	'degli',
	'deglī',
	'degļa',
	'degļi',
	'degļu',
	'degot',
	'degra',
	'degsi',
	'degšu',
	'degta',
	'degtā',
	'degti',
	'degto',
	'degts',
	'degtu',
	'deģis',
	'dejai',
	'dejam',
	'dejas',
	'dejat',
	'dejām',
	'dejās',
	'dejāt',
	'dejis',
	'dejos',
	'dejot',
	'dekai',
	'dekam',
	'dekas',
	'dekām',
	'dekās',
	'dekos',
	'dekus',
	'deķis',
	'deldē',
	'deldz',
	'deles',
	'delfi',
	'delgs',
	'delgt',
	'delia',
	'della',
	'delma',
	'delmā',
	'delmi',
	'delms',
	'delmu',
	'delna',
	'delnā',
	'delnu',
	'delta',
	'deltā',
	'deltu',
	'delve',
	'demba',
	'demre',
	'dendi',
	'dendī',
	'denei',
	'denes',
	'denēm',
	'denēs',
	'denēt',
	'denga',
	'denge',
	'dengē',
	'dengi',
	'dengu',
	'denis',
	'denja',
	'denjē',
	'dents',
	'denža',
	'denži',
	'denžu',
	'depsi',
	'depsī',
	'depša',
	'depši',
	'depšu',
	'deram',
	'derat',
	'derbi',
	'derdz',
	'derēs',
	'derēt',
	'dergs',
	'dergt',
	'derma',
	'dermā',
	'dermu',
	'derna',
	'derot',
	'desai',
	'desas',
	'desām',
	'desās',
	'desna',
	'desot',
	'detva',
	'devai',
	'devas',
	'devām',
	'devās',
	'devāt',
	'devis',
	'devos',
	'dezim',
	'dezis',
	'dežos',
	'dežus',
	'dēdam',
	'dēdat',
	'dēdēs',
	'dēdēt',
	'dēdot',
	'dēgļi',
	'dējam',
	'dējas',
	'dējat',
	'dējām',
	'dējās',
	'dējāt',
	'dējis',
	'dējos',
	'dējot',
	'dēkai',
	'dēkas',
	'dēkām',
	'dēkās',
	'dēkla',
	'dēkle',
	'dēlam',
	'dēlei',
	'dēles',
	'dēlēm',
	'dēlēs',
	'dēlim',
	'dēlis',
	'dēlos',
	'dēlus',
	'dēļās',
	'dēļos',
	'dēļus',
	'dēsim',
	'dēsit',
	'dēsta',
	'dēstā',
	'dēsti',
	'dēstī',
	'dēsts',
	'dēstu',
	'dēšos',
	'dēšot',
	'dētai',
	'dētam',
	'dētas',
	'dētām',
	'dētās',
	'dētie',
	'dētos',
	'dētus',
	'dēvāt',
	'dēvēs',
	'dēvēt',
	'dhoti',
	'diane',
	'diāde',
	'diāna',
	'dibas',
	'dibra',
	'dibuā',
	'didas',
	'dieba',
	'diebi',
	'diebj',
	'diebs',
	'diebt',
	'diebu',
	'diedz',
	'diega',
	'diegā',
	'diegi',
	'diegs',
	'diegt',
	'diegu',
	'diels',
	'diena',
	'dienā',
	'dieni',
	'dienu',
	'diesi',
	'diešu',
	'dieta',
	'dietā',
	'dieti',
	'dieto',
	'diets',
	'dietu',
	'dieva',
	'dievā',
	'dieve',
	'dievē',
	'dievi',
	'dievs',
	'dievu',
	'diezg',
	'diezi',
	'diēta',
	'diētā',
	'diētu',
	'diēza',
	'diēzā',
	'diēze',
	'diēzi',
	'diēzs',
	'diēzu',
	'dikļi',
	'dikša',
	'diktē',
	'dikti',
	'dikts',
	'diķis',
	'dilām',
	'dilāt',
	'dilba',
	'dilbā',
	'dilbi',
	'dilbs',
	'dilbu',
	'dilda',
	'dildo',
	'dilis',
	'dille',
	'dillē',
	'dilli',
	'dilļu',
	'dilsi',
	'dilst',
	'dilšu',
	'dilta',
	'diltā',
	'dilti',
	'dilto',
	'dilts',
	'diltu',
	'dilum',
	'dimai',
	'dimam',
	'dimas',
	'dimat',
	'dimām',
	'dimās',
	'dimāt',
	'dimba',
	'dimbā',
	'dimbu',
	'dimda',
	'dimdā',
	'dimdi',
	'dimds',
	'dimdu',
	'dimēs',
	'dimēt',
	'dimis',
	'dimka',
	'dimma',
	'dimot',
	'dimsi',
	'dimšu',
	'dimta',
	'dimtā',
	'dimti',
	'dimto',
	'dimts',
	'dimtu',
	'dimza',
	'dinam',
	'dinar',
	'dinga',
	'dingi',
	'dingo',
	'dingu',
	'dinos',
	'dinus',
	'diode',
	'diodē',
	'diodi',
	'diods',
	'dioli',
	'diona',
	'dione',
	'diožu',
	'dipam',
	'dipat',
	'dipēs',
	'dipēt',
	'dipot',
	'dirba',
	'dirka',
	'dirni',
	'dirnu',
	'dirsa',
	'dirsi',
	'dirst',
	'dirsu',
	'diršu',
	'disis',
	'diska',
	'diskā',
	'diski',
	'disko',
	'disks',
	'disku',
	'ditto',
	'divai',
	'divja',
	'dižai',
	'dižam',
	'dižas',
	'dižām',
	'dižās',
	'dižen',
	'dižie',
	'dižos',
	'dižus',
	'dīcam',
	'dīcat',
	'dīcām',
	'dīcāt',
	'dīcis',
	'dīcot',
	'dīdām',
	'dīdās',
	'dīdāt',
	'dīdīs',
	'dīdīt',
	'dīdos',
	'dīdot',
	'dīdzē',
	'dīgām',
	'dīgāt',
	'dīgli',
	'dīglī',
	'dīgļa',
	'dīgļi',
	'dīgļu',
	'dīgsi',
	'dīgst',
	'dīgšu',
	'dīgta',
	'dīgtā',
	'dīgti',
	'dīgto',
	'dīgts',
	'dīgtu',
	'dīkai',
	'dīkam',
	'dīkas',
	'dīkām',
	'dīkās',
	'dīkie',
	'dīkos',
	'dīksi',
	'dīkšā',
	'dīkšu',
	'dīkta',
	'dīktā',
	'dīkti',
	'dīkto',
	'dīkts',
	'dīktu',
	'dīkus',
	'dīķis',
	'dīlim',
	'dīlis',
	'dīlot',
	'dīļos',
	'dīļus',
	'dīrās',
	'dīrāt',
	'dīvai',
	'dīvam',
	'dīvas',
	'dīvām',
	'dīvās',
	'dīves',
	'dīvie',
	'dīvos',
	'dīvus',
	'djaks',
	'djepa',
	'djogi',
	'djoma',
	'djrse',
	'dobai',
	'dobam',
	'dobas',
	'dobām',
	'dobās',
	'dobāt',
	'dobei',
	'dobes',
	'dobēm',
	'dobēs',
	'dobie',
	'dobiķ',
	'dobis',
	'dobja',
	'dobjā',
	'dobji',
	'dobjo',
	'dobjš',
	'dobju',
	'dobos',
	'dobra',
	'dobre',
	'dobsi',
	'dobšu',
	'dobta',
	'dobtā',
	'dobti',
	'dobto',
	'dobts',
	'dobtu',
	'dobus',
	'docēs',
	'docēt',
	'dodam',
	'dodas',
	'dodat',
	'dodos',
	'dodot',
	'dodža',
	'dodžā',
	'dodži',
	'dodžs',
	'dodžu',
	'dogam',
	'dogma',
	'dogmā',
	'dogmu',
	'dogos',
	'dogus',
	'doina',
	'doinā',
	'doinu',
	'doira',
	'dojķi',
	'dokam',
	'dokos',
	'dokot',
	'doksa',
	'dokti',
	'dokus',
	'doķis',
	'dolce',
	'doles',
	'dolna',
	'dolne',
	'dolni',
	'dolus',
	'domai',
	'domam',
	'domas',
	'domām',
	'domās',
	'domāt',
	'dombe',
	'domei',
	'domes',
	'domēm',
	'domēs',
	'domju',
	'domna',
	'domnā',
	'domnu',
	'domos',
	'domra',
	'domrā',
	'domru',
	'domus',
	'donai',
	'donam',
	'donas',
	'donām',
	'donās',
	'dones',
	'donēt',
	'donga',
	'dongā',
	'dongi',
	'dongs',
	'dongu',
	'donim',
	'donis',
	'donja',
	'donjā',
	'donju',
	'donka',
	'donna',
	'donnā',
	'donns',
	'donnu',
	'donos',
	'donus',
	'doņos',
	'doņus',
	'dorba',
	'dorbe',
	'dorbs',
	'dorei',
	'dores',
	'dorēm',
	'dorēs',
	'dorfs',
	'dorji',
	'dorna',
	'dorra',
	'dorša',
	'doršā',
	'dorši',
	'doršs',
	'doršu',
	'dosim',
	'dosit',
	'dosjē',
	'doski',
	'došos',
	'došot',
	'dotai',
	'dotam',
	'dotas',
	'dotām',
	'dotās',
	'dotēs',
	'dotēt',
	'dotie',
	'dotos',
	'dotus',
	'douru',
	'dozai',
	'dozas',
	'dozām',
	'dozās',
	'dozei',
	'dozes',
	'dozēm',
	'dozēs',
	'dozēt',
	'draba',
	'draco',
	'draga',
	'dragā',
	'dragu',
	'draka',
	'draks',
	'drama',
	'drams',
	'drapa',
	'drapā',
	'drape',
	'drapē',
	'drapi',
	'draps',
	'drapu',
	'drasē',
	'draud',
	'drava',
	'dravā',
	'dravu',
	'draza',
	'drazā',
	'drazu',
	'dražē',
	'drāga',
	'drāma',
	'drāmā',
	'drāmu',
	'drāna',
	'drānā',
	'drānu',
	'drāšu',
	'drāte',
	'drāti',
	'drātī',
	'drāts',
	'drāza',
	'drāzi',
	'drāzt',
	'drāzu',
	'drāžu',
	'drebi',
	'drebu',
	'dredi',
	'dreja',
	'drejs',
	'dreks',
	'drena',
	'drenā',
	'drene',
	'drenē',
	'drenu',
	'dresē',
	'dress',
	'dreve',
	'drēbe',
	'drēbē',
	'drēbi',
	'drēņi',
	'driba',
	'drice',
	'driķe',
	'driķi',
	'drila',
	'drilā',
	'drili',
	'drils',
	'drilu',
	'drina',
	'dripa',
	'dripe',
	'drisa',
	'driva',
	'drive',
	'drīdz',
	'drīle',
	'drīve',
	'drīvē',
	'drīvi',
	'drīza',
	'drīzā',
	'drīzi',
	'drīzo',
	'drīzs',
	'drīzu',
	'drofa',
	'droga',
	'drogā',
	'drogu',
	'droma',
	'drona',
	'drone',
	'drons',
	'drope',
	'drosa',
	'dross',
	'droša',
	'drošā',
	'droši',
	'drošo',
	'drošs',
	'drošu',
	'drova',
	'drugs',
	'druka',
	'drukā',
	'druku',
	'druķi',
	'drupa',
	'drupi',
	'drups',
	'drupt',
	'drupu',
	'druva',
	'druvā',
	'druve',
	'druvu',
	'druzi',
	'druzu',
	'druža',
	'drūma',
	'drūmā',
	'drūme',
	'drūmi',
	'drūmo',
	'drūms',
	'drūmu',
	'drūpi',
	'drūpu',
	'drūza',
	'drūzā',
	'drūzu',
	'duala',
	'duali',
	'duata',
	'duatā',
	'duatu',
	'duāla',
	'duālā',
	'duāli',
	'duālī',
	'duālo',
	'duāls',
	'duālu',
	'duāļa',
	'duāļi',
	'duāļu',
	'dubes',
	'dublē',
	'dubli',
	'dublī',
	'dubls',
	'dubļa',
	'dubļi',
	'dubļu',
	'dubna',
	'dubno',
	'dubra',
	'ducim',
	'ducis',
	'dučos',
	'dučus',
	'dudka',
	'dudna',
	'dueli',
	'duelī',
	'dueļa',
	'dueļi',
	'dueļu',
	'duena',
	'dueņa',
	'duero',
	'dueta',
	'duetā',
	'dueti',
	'duets',
	'duetu',
	'duins',
	'dukai',
	'dukas',
	'dukām',
	'dukās',
	'dukha',
	'dukla',
	'dukšu',
	'dukte',
	'dukts',
	'dulbe',
	'dulbs',
	'dulis',
	'dulla',
	'dullā',
	'dulle',
	'dulli',
	'dullo',
	'dulls',
	'dullu',
	'dulni',
	'dulns',
	'duļķa',
	'duļķe',
	'duļķi',
	'duļķī',
	'duļķo',
	'duļķu',
	'duļļi',
	'dumai',
	'dumam',
	'dumas',
	'dumām',
	'dumās',
	'dumie',
	'dumja',
	'dumjā',
	'dumji',
	'dumjo',
	'dumjš',
	'dumju',
	'dumka',
	'dumkā',
	'dumku',
	'dumos',
	'dumpi',
	'dumpī',
	'dumpo',
	'dumus',
	'dunai',
	'dunam',
	'dunas',
	'dunat',
	'dunām',
	'dunās',
	'dunci',
	'duncī',
	'dunča',
	'dunči',
	'dunču',
	'dunda',
	'dundi',
	'dundu',
	'dunēs',
	'dunēt',
	'dungo',
	'dunka',
	'dunkā',
	'dunko',
	'dunku',
	'dunot',
	'dunte',
	'duņka',
	'duole',
	'duolē',
	'duoli',
	'duolo',
	'duoļu',
	'dupes',
	'duplo',
	'dupļi',
	'dupsi',
	'dupsī',
	'dupša',
	'dupši',
	'dupšu',
	'duram',
	'duras',
	'durat',
	'durba',
	'durbā',
	'durbe',
	'durbu',
	'dures',
	'durga',
	'duris',
	'durka',
	'durni',
	'duros',
	'durot',
	'durra',
	'dursi',
	'duršu',
	'durta',
	'durtā',
	'durti',
	'durto',
	'durts',
	'durtu',
	'durvi',
	'durvī',
	'dusai',
	'dusam',
	'dusas',
	'dusat',
	'dusām',
	'dusās',
	'dusēs',
	'dusēt',
	'dusma',
	'dusmā',
	'dusmo',
	'dusmu',
	'dusot',
	'dusts',
	'dušai',
	'dušas',
	'dušām',
	'dušās',
	'dutka',
	'dutra',
	'duvra',
	'dūcam',
	'dūcat',
	'dūcām',
	'dūcāt',
	'dūcim',
	'dūcis',
	'dūcot',
	'dūčos',
	'dūčus',
	'dūdaa',
	'dūdaā',
	'dūdai',
	'dūdas',
	'dūdau',
	'dūdām',
	'dūdās',
	'dūdos',
	'dūdot',
	'dūjai',
	'dūjas',
	'dūjām',
	'dūjās',
	'dūkas',
	'dūkos',
	'dūkot',
	'dūksi',
	'dūkšu',
	'dūkta',
	'dūktā',
	'dūkti',
	'dūkto',
	'dūkts',
	'dūktu',
	'dūķis',
	'dūlēt',
	'dūlot',
	'dūmai',
	'dūmam',
	'dūmas',
	'dūmām',
	'dūmās',
	'dūmos',
	'dūmot',
	'dūmus',
	'dūnas',
	'dūnis',
	'dūņaa',
	'dūņaā',
	'dūņai',
	'dūņas',
	'dūņau',
	'dūram',
	'dūras',
	'dūrām',
	'dūrās',
	'dūrāt',
	'dūrei',
	'dūres',
	'dūrēm',
	'dūrēs',
	'dūrēt',
	'dūris',
	'dūros',
	'dūrus',
	'dūšai',
	'dūšas',
	'dūšām',
	'dūšās',
	'dūzim',
	'dūzis',
	'dūžos',
	'dūžus',
	'dvaka',
	'dvakā',
	'dvaku',
	'dvali',
	'dvans',
	'dvari',
	'dvars',
	'dvaša',
	'dvašā',
	'dvašo',
	'dvašu',
	'dvero',
	'dvesa',
	'dvesi',
	'dvesī',
	'dvest',
	'dvesu',
	'dveša',
	'dveši',
	'dvešu',
	'dvina',
	'dvīga',
	'dvīne',
	'dvīnē',
	'dvīni',
	'dvīņi',
	'dvīņu',
	'dvora',
	'dvura',
	'dzeds',
	'dzega',
	'dzegā',
	'dzegu',
	'dzeja',
	'dzejā',
	'dzejo',
	'dzeju',
	'dzela',
	'dzeli',
	'dzelī',
	'dzels',
	'dzelt',
	'dzeļa',
	'dzeļi',
	'dzeļu',
	'dzena',
	'dzenā',
	'dzeni',
	'dzenī',
	'dzens',
	'dzenu',
	'dzeņa',
	'dzeņi',
	'dzeņu',
	'dzera',
	'dzers',
	'dzert',
	'dzeru',
	'dzese',
	'dzesē',
	'dzest',
	'dzeta',
	'dzezi',
	'dzezī',
	'dzeža',
	'dzeži',
	'dzežu',
	'dzēla',
	'dzēli',
	'dzēlu',
	'dzēra',
	'dzēri',
	'dzērt',
	'dzēru',
	'dzēsa',
	'dzēse',
	'dzēsē',
	'dzēsi',
	'dzēst',
	'dzēsu',
	'dzēšu',
	'dzibo',
	'dzied',
	'dziet',
	'dzija',
	'dzijā',
	'dziju',
	'dziks',
	'dzila',
	'dziļa',
	'dziļā',
	'dziļi',
	'dziļo',
	'dziļš',
	'dziļu',
	'dzima',
	'dzimi',
	'dzims',
	'dzimt',
	'dzimu',
	'dzina',
	'dzini',
	'dzinu',
	'dziņa',
	'dziņā',
	'dziņu',
	'dzira',
	'dzirā',
	'dzird',
	'dzire',
	'dzirt',
	'dziru',
	'dzisa',
	'dzisā',
	'dzisi',
	'dzist',
	'dzisu',
	'dziva',
	'dzive',
	'dzivs',
	'dzībe',
	'dzīga',
	'dzīle',
	'dzīlē',
	'dzīli',
	'dzīļu',
	'dzīne',
	'dzīro',
	'dzīsi',
	'dzīšu',
	'dzīta',
	'dzītā',
	'dzīti',
	'dzīto',
	'dzīts',
	'dzītu',
	'dzīva',
	'dzīvā',
	'dzīve',
	'dzīvē',
	'dzīvi',
	'dzīvo',
	'dzīvs',
	'dzīvu',
	'dzjao',
	'dzota',
	'dzotā',
	'dzoti',
	'dzots',
	'dzotu',
	'džaga',
	'džaja',
	'džalo',
	'džama',
	'džana',
	'džans',
	'džapa',
	'džāra',
	'džebs',
	'džeka',
	'džekā',
	'džeki',
	'džeks',
	'džeku',
	'džema',
	'džemā',
	'džemi',
	'džems',
	'džemu',
	'džess',
	'džets',
	'džeza',
	'džezā',
	'džezi',
	'džezs',
	'džezu',
	'džida',
	'džiga',
	'džigs',
	'džila',
	'džima',
	'džimi',
	'džina',
	'džinā',
	'džini',
	'džins',
	'džinu',
	'džipa',
	'džipā',
	'džipi',
	'džips',
	'džipu',
	'džīgā',
	'džīla',
	'džīna',
	'džīva',
	'džīvā',
	'džīvu',
	'džobs',
	'džodo',
	'džoli',
	'džons',
	'džosa',
	'džude',
	'džudo',
	'džuma',
	'džuni',
	'džura',
	'džuse',
	'džuta',
	'džutā',
	'džutu',
	'džūba',
	'džūda',
	'džūks',
	'džūno',
	'ebene',
	'ebeni',
	'ebens',
	'ebisu',
	'ebola',
	'eboli',
	'ebols',
	'eboņi',
	'ecdis',
	'ecēja',
	'ecēji',
	'ecēju',
	'ecēsi',
	'ecēša',
	'ecēšā',
	'ecēšu',
	'ecēta',
	'ecētā',
	'ecēti',
	'ecēto',
	'ecēts',
	'ecētu',
	'echis',
	'eckau',
	'ecole',
	'edama',
	'edeka',
	'edesa',
	'edēma',
	'edēmā',
	'edēmu',
	'edils',
	'edips',
	'edoma',
	'efeja',
	'efejā',
	'efeju',
	'efesa',
	'efiap',
	'efods',
	'efors',
	'egadu',
	'egeja',
	'egejs',
	'egera',
	'egila',
	'egina',
	'eglei',
	'egles',
	'eglēm',
	'eglēs',
	'eglis',
	'ehīns',
	'eidim',
	'eidis',
	'eidos',
	'eikss',
	'eikša',
	'eimsa',
	'einis',
	'eižos',
	'eižus',
	'ejama',
	'ejamā',
	'ejami',
	'ejamo',
	'ejams',
	'ejamu',
	'ejiet',
	'ejina',
	'ejiņa',
	'ejoša',
	'ejošā',
	'ejoši',
	'ejošo',
	'ejošs',
	'ejošu',
	'ekale',
	'ekers',
	'ekijs',
	'ekipē',
	'ekiti',
	'eksis',
	'elara',
	'eleja',
	'elena',
	'eleva',
	'elēja',
	'elfas',
	'elija',
	'eliņa',
	'eliņā',
	'eliņi',
	'eliņš',
	'eliņu',
	'elisa',
	'elise',
	'elišu',
	'elite',
	'elitē',
	'eliti',
	'elkus',
	'elles',
	'eloge',
	'elpām',
	'elpis',
	'elpos',
	'elpot',
	'elsaa',
	'elsaā',
	'elsai',
	'elsas',
	'elsau',
	'elsām',
	'elsās',
	'elsāt',
	'elsim',
	'elsis',
	'elsīs',
	'elsos',
	'elsot',
	'elsta',
	'elstā',
	'elste',
	'elsti',
	'elsto',
	'elsts',
	'elstu',
	'elšam',
	'elšat',
	'elšos',
	'elšot',
	'elšus',
	'eluls',
	'elvgf',
	'eļļos',
	'eļļot',
	'embla',
	'emena',
	'emera',
	'emerā',
	'emeri',
	'emers',
	'emeru',
	'emeta',
	'emitē',
	'emīra',
	'emīrā',
	'emīri',
	'emīrs',
	'emīru',
	'encis',
	'endas',
	'endra',
	'enejs',
	'eniki',
	'enisa',
	'enohs',
	'entie',
	'entre',
	'enugu',
	'eņģis',
	'eolai',
	'eolam',
	'eolas',
	'eolām',
	'eolās',
	'eolie',
	'eolos',
	'eolus',
	'epens',
	'epika',
	'epikā',
	'epiku',
	'epiķe',
	'epīra',
	'epoch',
	'epoha',
	'epohā',
	'epohē',
	'epohu',
	'epona',
	'eposa',
	'eposā',
	'eposi',
	'eposs',
	'eposu',
	'eraka',
	'erans',
	'erato',
	'erebs',
	'ergas',
	'erica',
	'eriče',
	'eriņi',
	'erīda',
	'erkul',
	'erlaa',
	'ermes',
	'erodē',
	'erosa',
	'erosā',
	'erosi',
	'eross',
	'erosu',
	'erots',
	'erras',
	'error',
	'eruca',
	'eruka',
	'erzja',
	'erzji',
	'eseja',
	'esejā',
	'eseju',
	'esene',
	'eseni',
	'esere',
	'eserē',
	'eseri',
	'eseru',
	'esiet',
	'esila',
	'esība',
	'esībā',
	'esību',
	'esīvs',
	'esoša',
	'esošā',
	'esoši',
	'esošo',
	'esošs',
	'esošu',
	'estrs',
	'etana',
	'etapa',
	'etapā',
	'etapi',
	'etaps',
	'etapu',
	'etāna',
	'etānā',
	'etāni',
	'etāns',
	'etānu',
	'etēns',
	'etila',
	'etilā',
	'etili',
	'etils',
	'etilu',
	'etīde',
	'etīdē',
	'etīdi',
	'etīžu',
	'etoss',
	'etoša',
	'etufē',
	'eudia',
	'euiss',
	'eures',
	'evena',
	'evenā',
	'eveni',
	'evens',
	'evenu',
	'evija',
	'evora',
	'ezars',
	'ezera',
	'ezerā',
	'ezere',
	'ezeri',
	'ezers',
	'ezeru',
	'ezēni',
	'ezine',
	'ezīga',
	'ezīgā',
	'ezīgi',
	'ezīgo',
	'ezīgs',
	'ezīgu',
	'ežeņs',
	'ežuks',
	'ežupe',
	'ēciņa',
	'ēdama',
	'ēdamā',
	'ēdami',
	'ēdamo',
	'ēdams',
	'ēdamu',
	'ēdāja',
	'ēdājā',
	'ēdāji',
	'ēdājs',
	'ēdāju',
	'ēdene',
	'ēdere',
	'ēdēja',
	'ēdējā',
	'ēdēji',
	'ēdējs',
	'ēdēju',
	'ēdien',
	'ēdies',
	'ēdiet',
	'ēdina',
	'ēdini',
	'ēdinu',
	'ēdīsi',
	'ēdīšu',
	'ēdole',
	'ēdoša',
	'ēdošā',
	'ēdoši',
	'ēdošo',
	'ēdošs',
	'ēdošu',
	'ēdums',
	'ēdusi',
	'ēdusī',
	'ēduša',
	'ēdušā',
	'ēduši',
	'ēdušo',
	'ēdušu',
	'ēķina',
	'ēnava',
	'ēnoja',
	'ēnoji',
	'ēnoju',
	'ēnosi',
	'ēnošu',
	'ēnota',
	'ēnotā',
	'ēnoti',
	'ēnoto',
	'ēnots',
	'ēnotu',
	'ērcis',
	'ērgļi',
	'ērika',
	'ēriks',
	'ērķis',
	'ēstai',
	'ēstam',
	'ēstas',
	'ēstām',
	'ēstās',
	'ēstie',
	'ēstos',
	'ēstus',
	'ēšana',
	'ēšanā',
	'ēšanu',
	'ētera',
	'ēterā',
	'ēteri',
	'ēters',
	'ēteru',
	'ētika',
	'ētikā',
	'ētiku',
	'ētoss',
	'ēvele',
	'ēvelē',
	'ēveli',
	'ēveļu',
	'ēzavs',
	'ēzops',
	'fabri',
	'fagus',
	'faifs',
	'faila',
	'failā',
	'faili',
	'fails',
	'failu',
	'fakjū',
	'faksa',
	'faksā',
	'faksi',
	'fakss',
	'faksu',
	'fakšu',
	'fakti',
	'faktī',
	'fakts',
	'faķis',
	'falce',
	'falco',
	'falla',
	'fallā',
	'falle',
	'fallē',
	'falli',
	'falls',
	'fallu',
	'falļu',
	'falši',
	'falšs',
	'falte',
	'fanda',
	'fandi',
	'fangi',
	'fango',
	'fanki',
	'fankī',
	'fanks',
	'fanot',
	'fanta',
	'fanti',
	'fanza',
	'fanzā',
	'fanzu',
	'farca',
	'fards',
	'farma',
	'farsa',
	'farsā',
	'farse',
	'farsi',
	'farss',
	'farsu',
	'farts',
	'fasēs',
	'fasēt',
	'fasti',
	'fatah',
	'fatms',
	'fatsa',
	'fatva',
	'fauls',
	'fauna',
	'faunā',
	'fauni',
	'fauns',
	'faunu',
	'favus',
	'fāķis',
	'fārēt',
	'fārgo',
	'fāris',
	'febre',
	'fecit',
	'fedja',
	'fegen',
	'feina',
	'feinā',
	'feini',
	'feino',
	'feins',
	'feinu',
	'feira',
	'feiss',
	'feits',
	'fejai',
	'fejas',
	'fejām',
	'fejās',
	'felis',
	'femme',
	'feods',
	'ferde',
	'feres',
	'ferio',
	'ferma',
	'fermā',
	'fermi',
	'fermo',
	'fermu',
	'feska',
	'feskā',
	'fesku',
	'fests',
	'fēcea',
	'fēceā',
	'fēcei',
	'fēces',
	'fēceu',
	'fēnot',
	'fiati',
	'fiats',
	'fiāno',
	'fibra',
	'fibrā',
	'fibru',
	'ficus',
	'fides',
	'fidži',
	'fieri',
	'fiero',
	'figņa',
	'fiksa',
	'fiksā',
	'fiksē',
	'fiksi',
	'fikss',
	'fiksu',
	'filca',
	'filcā',
	'filci',
	'filcs',
	'filcu',
	'filči',
	'filēt',
	'filia',
	'filke',
	'filki',
	'filla',
	'filma',
	'filmā',
	'filmē',
	'films',
	'filmu',
	'filou',
	'finis',
	'finka',
	'finna',
	'finnā',
	'finne',
	'finns',
	'finnu',
	'finta',
	'firma',
	'firmā',
	'firms',
	'firmu',
	'firns',
	'firte',
	'fiska',
	'fiskā',
	'fiski',
	'fisks',
	'fisku',
	'fiška',
	'fizča',
	'fizim',
	'fizis',
	'fižos',
	'fižus',
	'fīrēt',
	'fīzim',
	'fīzis',
	'fīžos',
	'fīžus',
	'fjuki',
	'flaga',
	'flagā',
	'flags',
	'flagu',
	'flair',
	'flaks',
	'flāma',
	'flāmā',
	'flāmi',
	'flāms',
	'flāmu',
	'flena',
	'fleša',
	'flešs',
	'fleur',
	'flips',
	'flišs',
	'flīze',
	'flīzē',
	'flīzi',
	'flīžu',
	'flome',
	'flops',
	'flora',
	'florā',
	'floro',
	'flors',
	'floru',
	'flote',
	'floto',
	'flots',
	'foajē',
	'fočēt',
	'fodža',
	'fogts',
	'foibe',
	'foini',
	'foksa',
	'fokss',
	'fokus',
	'folia',
	'folie',
	'folio',
	'folks',
	'follā',
	'fomas',
	'fomes',
	'fomka',
	'fonce',
	'fonda',
	'fondā',
	'fondi',
	'fondī',
	'fonds',
	'fondu',
	'fonts',
	'force',
	'fords',
	'forio',
	'forli',
	'forma',
	'formā',
	'formē',
	'formi',
	'forms',
	'formu',
	'forsa',
	'forsē',
	'forši',
	'foršs',
	'forta',
	'fortā',
	'forte',
	'forti',
	'forts',
	'fortu',
	'forum',
	'forza',
	'fotka',
	'fouls',
	'fouta',
	'fraka',
	'frakā',
	'fraku',
	'frams',
	'franc',
	'frāna',
	'frāze',
	'frāzē',
	'frāzi',
	'frāžu',
	'freda',
	'freja',
	'frejs',
	'frena',
	'frese',
	'frēze',
	'frēzē',
	'frēzi',
	'frēžī',
	'frēžu',
	'frica',
	'friča',
	'friga',
	'frija',
	'friši',
	'frišs',
	'frite',
	'frizē',
	'frīks',
	'frīze',
	'frīzē',
	'frīzi',
	'frīzs',
	'frīzu',
	'frīžu',
	'froli',
	'froma',
	'frotē',
	'frugi',
	'ftīze',
	'ftīzē',
	'ftīzi',
	'ftīžu',
	'fuajē',
	'fucis',
	'fucus',
	'fudzi',
	'fuego',
	'fuetē',
	'fuflo',
	'fukui',
	'fulbe',
	'fulbi',
	'fulda',
	'funds',
	'fungi',
	'funts',
	'fuoco',
	'fūgas',
	'fūzim',
	'fūzis',
	'fūžos',
	'fūžus',
	'gabal',
	'gabri',
	'gabro',
	'gadās',
	'gadīt',
	'gados',
	'gadus',
	'gaeta',
	'gafsa',
	'gagea',
	'gagra',
	'gaiba',
	'gaida',
	'gaidā',
	'gaide',
	'gaidē',
	'gaidi',
	'gaidu',
	'gaika',
	'gaiķi',
	'gaile',
	'gailē',
	'gaili',
	'gails',
	'gaiļi',
	'gaiļu',
	'gaima',
	'gaina',
	'gainī',
	'gaiņā',
	'gaiņi',
	'gaisa',
	'gaisā',
	'gaisi',
	'gaiss',
	'gaist',
	'gaisu',
	'gaiša',
	'gaišā',
	'gaiši',
	'gaišo',
	'gaišs',
	'gaišu',
	'gaita',
	'gaitā',
	'gaite',
	'gaits',
	'gaitu',
	'gaižu',
	'galba',
	'galda',
	'galdā',
	'galdi',
	'galds',
	'galdu',
	'galea',
	'galen',
	'galēs',
	'galēt',
	'galgt',
	'galla',
	'gallā',
	'galle',
	'galli',
	'galls',
	'gallu',
	'galma',
	'galmā',
	'galmi',
	'galms',
	'galmu',
	'galot',
	'galva',
	'galvā',
	'galvo',
	'galvu',
	'gamba',
	'gambe',
	'gamin',
	'gamla',
	'gamma',
	'gammā',
	'gamme',
	'gammu',
	'ganām',
	'ganās',
	'ganāt',
	'ganda',
	'gandē',
	'gandi',
	'ganga',
	'ganīs',
	'ganīt',
	'ganka',
	'ganos',
	'ganot',
	'ganta',
	'gants',
	'gaņoa',
	'gaņsu',
	'gaons',
	'garai',
	'garam',
	'garas',
	'garām',
	'garās',
	'garba',
	'garca',
	'garce',
	'garda',
	'gardā',
	'garde',
	'gardi',
	'gardo',
	'gards',
	'gardu',
	'garga',
	'garie',
	'garmo',
	'garna',
	'garnē',
	'garni',
	'garos',
	'garot',
	'garša',
	'garšā',
	'garši',
	'garšo',
	'garšu',
	'garuā',
	'garum',
	'garus',
	'garva',
	'gasts',
	'gatas',
	'gatva',
	'gatve',
	'gatvē',
	'gatvi',
	'gaučo',
	'gauda',
	'gaudā',
	'gaudi',
	'gaudo',
	'gauds',
	'gaudu',
	'gauga',
	'gauja',
	'gaula',
	'gaume',
	'gaumē',
	'gaumi',
	'gaura',
	'gaurā',
	'gauri',
	'gaurs',
	'gauru',
	'gausa',
	'gausā',
	'gausi',
	'gausī',
	'gauso',
	'gauss',
	'gaust',
	'gausu',
	'gauša',
	'gauši',
	'gauza',
	'gauze',
	'gauža',
	'gaužā',
	'gauži',
	'gaužo',
	'gaužs',
	'gaužu',
	'gavēs',
	'gavēt',
	'gavia',
	'gazni',
	'gādās',
	'gādāt',
	'gāgaa',
	'gāgaā',
	'gāgai',
	'gāgas',
	'gāgau',
	'gāgās',
	'gāgāt',
	'gājām',
	'gājāt',
	'gājis',
	'gānām',
	'gānās',
	'gānāt',
	'gānīs',
	'gānīt',
	'gānos',
	'gānot',
	'gārda',
	'gārde',
	'gārdz',
	'gārgs',
	'gārgt',
	'gārsa',
	'gārsā',
	'gārse',
	'gārsu',
	'gārša',
	'gāršā',
	'gāršs',
	'gāršu',
	'gāses',
	'gāšus',
	'gātas',
	'gāzām',
	'gāzās',
	'gāzāt',
	'gāzēs',
	'gāzēt',
	'gāzis',
	'gāzīs',
	'gāzma',
	'gāzmā',
	'gāzme',
	'gāzmē',
	'gāzmi',
	'gāzmu',
	'gāzos',
	'gāzta',
	'gāztā',
	'gāzti',
	'gāzto',
	'gāzts',
	'gāztu',
	'gāžam',
	'gāžas',
	'gāžat',
	'gāžos',
	'gāžot',
	'gdiņa',
	'gebri',
	'gebze',
	'geims',
	'geiša',
	'geišā',
	'geišu',
	'geits',
	'geiza',
	'gelee',
	'gemma',
	'genca',
	'genka',
	'genre',
	'genro',
	'gente',
	'genua',
	'genus',
	'gerca',
	'gerda',
	'gergo',
	'gerki',
	'gerla',
	'germi',
	'geste',
	'getra',
	'getrā',
	'getru',
	'gēste',
	'gēsti',
	'ghata',
	'gifts',
	'gilde',
	'gimle',
	'ginka',
	'ginkā',
	'ginki',
	'ginks',
	'ginku',
	'ginta',
	'ginza',
	'girbe',
	'girla',
	'girle',
	'girlo',
	'girne',
	'girsa',
	'girsu',
	'gisco',
	'gisis',
	'gīmel',
	'glabā',
	'glace',
	'glase',
	'glaud',
	'glauž',
	'glavi',
	'glaze',
	'glazē',
	'glazs',
	'glāba',
	'glābi',
	'glābj',
	'glābs',
	'glābt',
	'glābu',
	'glāna',
	'glāss',
	'glāst',
	'glāza',
	'glāze',
	'glāzē',
	'glāzi',
	'glāžu',
	'gleba',
	'gleja',
	'glejā',
	'gleji',
	'glejs',
	'gleju',
	'glema',
	'gleme',
	'glemi',
	'gleta',
	'gletā',
	'gleti',
	'glets',
	'gletu',
	'glija',
	'glijā',
	'gliju',
	'glika',
	'glina',
	'glisē',
	'glita',
	'glits',
	'glīši',
	'glīta',
	'glītā',
	'glīti',
	'glīto',
	'glīts',
	'glītu',
	'glīze',
	'globs',
	'glods',
	'glosa',
	'glots',
	'gluda',
	'gludā',
	'gludi',
	'gludo',
	'gluds',
	'gludu',
	'gluma',
	'glumā',
	'glumē',
	'glumi',
	'glumo',
	'glums',
	'glumt',
	'glumu',
	'glupa',
	'glupā',
	'glupi',
	'glupo',
	'glups',
	'glupu',
	'gluži',
	'glūda',
	'glūdā',
	'glūdu',
	'glūna',
	'glūnā',
	'glūni',
	'glūns',
	'glūnu',
	'glūņa',
	'glūņā',
	'glūņu',
	'gļeta',
	'gļetā',
	'gļeti',
	'gļeto',
	'gļets',
	'gļetu',
	'gļēva',
	'gļēvā',
	'gļēvi',
	'gļēvo',
	'gļēvs',
	'gļēvu',
	'gļota',
	'gļuks',
	'gmdss',
	'gmina',
	'gnēze',
	'gnīda',
	'gnīdā',
	'gnīdu',
	'gnoči',
	'gnoma',
	'gnomā',
	'gnome',
	'gnomi',
	'gnoms',
	'gnomu',
	'gnoze',
	'gnozē',
	'gnozi',
	'gnožu',
	'gobas',
	'gobio',
	'gobis',
	'godam',
	'godās',
	'godāt',
	'gofra',
	'gofrē',
	'goija',
	'gojes',
	'gojim',
	'golan',
	'goldi',
	'golfa',
	'golfā',
	'golfe',
	'golfi',
	'golfs',
	'golfu',
	'golts',
	'golva',
	'gombe',
	'gonda',
	'gondi',
	'gonga',
	'gongā',
	'gongi',
	'gongs',
	'gongu',
	'gonka',
	'gonna',
	'gorām',
	'gorās',
	'gorāt',
	'gords',
	'gorga',
	'gorgo',
	'goris',
	'gorīs',
	'gorīt',
	'gorka',
	'gorki',
	'gorna',
	'goros',
	'gorot',
	'gorta',
	'gorža',
	'gosta',
	'gosti',
	'gosts',
	'gotas',
	'gouda',
	'govno',
	'gozēs',
	'gozēt',
	'gozīt',
	'grabe',
	'grabi',
	'grabu',
	'gračs',
	'grada',
	'grado',
	'grafa',
	'grafā',
	'grafē',
	'grafi',
	'grafs',
	'grafu',
	'grama',
	'gramā',
	'grami',
	'grams',
	'gramu',
	'grana',
	'grand',
	'grano',
	'grans',
	'grapa',
	'grape',
	'graps',
	'grasa',
	'graši',
	'grašs',
	'graud',
	'grauj',
	'graus',
	'graut',
	'grauz',
	'grauž',
	'grava',
	'gravā',
	'grave',
	'gravē',
	'gravu',
	'graži',
	'grāba',
	'grābā',
	'grābi',
	'grābj',
	'grābs',
	'grābt',
	'grābu',
	'grāca',
	'grāda',
	'grādā',
	'grādi',
	'grāds',
	'grādu',
	'grāfs',
	'grāja',
	'grāls',
	'grāms',
	'grāna',
	'grāns',
	'grāši',
	'grāšu',
	'grāte',
	'grātē',
	'grāti',
	'grāva',
	'grāvi',
	'grāvo',
	'grāvs',
	'grāvu',
	'greba',
	'grebe',
	'grebi',
	'grebj',
	'grebo',
	'grebs',
	'grebt',
	'grebu',
	'greco',
	'greča',
	'grečo',
	'grejs',
	'grels',
	'gremd',
	'gremo',
	'gremu',
	'gremz',
	'gremž',
	'grene',
	'grese',
	'greve',
	'grēda',
	'grēdā',
	'grēdu',
	'grēka',
	'grēkā',
	'grēki',
	'grēko',
	'grēks',
	'grēku',
	'grēms',
	'grēno',
	'grēns',
	'grēve',
	'grēvē',
	'grēvi',
	'griba',
	'gribā',
	'gribe',
	'gribi',
	'gribu',
	'griča',
	'grida',
	'griez',
	'griež',
	'grifa',
	'grifā',
	'grifi',
	'grifs',
	'grifu',
	'grigi',
	'griķi',
	'grila',
	'grilā',
	'grile',
	'grili',
	'grill',
	'grils',
	'grilu',
	'grima',
	'grimā',
	'grimē',
	'grimi',
	'grimo',
	'grims',
	'grimt',
	'grimu',
	'grins',
	'griņī',
	'griņš',
	'gripa',
	'gripā',
	'gripe',
	'grips',
	'gripu',
	'griss',
	'griva',
	'griža',
	'grība',
	'grībā',
	'grībi',
	'grību',
	'grīda',
	'grīdā',
	'grīds',
	'grīdu',
	'grīli',
	'grīļi',
	'grīna',
	'grīnā',
	'grīnē',
	'grīni',
	'grīno',
	'grīns',
	'grīnu',
	'grīņa',
	'grīņi',
	'grīst',
	'grīva',
	'grīvā',
	'grīvi',
	'grīvs',
	'grīvu',
	'grīzt',
	'grīži',
	'groba',
	'grobe',
	'grobs',
	'groda',
	'grodā',
	'grodi',
	'grodo',
	'grods',
	'grodu',
	'grogs',
	'grohs',
	'groka',
	'grokā',
	'groki',
	'groks',
	'groku',
	'groma',
	'gromā',
	'gromi',
	'groms',
	'gromu',
	'grona',
	'grons',
	'groom',
	'grope',
	'gropē',
	'gropi',
	'grosa',
	'grosā',
	'grose',
	'grosi',
	'gross',
	'grosu',
	'grosz',
	'groša',
	'grošu',
	'grota',
	'grotā',
	'grote',
	'grotē',
	'groti',
	'grots',
	'grotu',
	'grout',
	'grova',
	'groza',
	'grozā',
	'groze',
	'grozi',
	'grozs',
	'grozu',
	'groži',
	'grožo',
	'grožs',
	'grums',
	'gruņa',
	'grupa',
	'grupā',
	'grupe',
	'grupē',
	'grupi',
	'grupu',
	'gruva',
	'gruvi',
	'gruvu',
	'gruzd',
	'gruzi',
	'gruža',
	'gruži',
	'gružo',
	'gružu',
	'grūba',
	'grūbā',
	'grūbe',
	'grūbi',
	'grūbu',
	'grūda',
	'grūdi',
	'grūdu',
	'grūms',
	'grūsi',
	'grūst',
	'grūši',
	'grūšu',
	'grūta',
	'grūtā',
	'grūti',
	'grūto',
	'grūts',
	'grūtu',
	'grūva',
	'grūžu',
	'guama',
	'guano',
	'guaša',
	'guašā',
	'guašu',
	'guava',
	'guavā',
	'guavu',
	'gubām',
	'gubio',
	'gubos',
	'gubot',
	'gudis',
	'gudra',
	'gudrā',
	'gudre',
	'gudri',
	'gudro',
	'gudrs',
	'gudru',
	'guera',
	'guģis',
	'guide',
	'gujva',
	'gulag',
	'gulai',
	'gulam',
	'gulat',
	'gulda',
	'guldi',
	'guldu',
	'guldz',
	'gulēs',
	'gulēt',
	'gulgo',
	'gulgs',
	'gulgt',
	'gulka',
	'gulot',
	'gulsi',
	'gulst',
	'gulšu',
	'gulta',
	'gultā',
	'gulti',
	'gulto',
	'gults',
	'gultu',
	'gulus',
	'guļas',
	'guļus',
	'gumām',
	'gumāt',
	'gumba',
	'gumbs',
	'gumda',
	'gumdi',
	'gumdu',
	'gumis',
	'gumma',
	'gummā',
	'gummu',
	'gumps',
	'gumsi',
	'gumst',
	'gumšu',
	'gumta',
	'gumtā',
	'gumti',
	'gumto',
	'gumts',
	'gumtu',
	'gumza',
	'gumzi',
	'gumzu',
	'gunas',
	'gunda',
	'gundu',
	'gurām',
	'gurāt',
	'gurda',
	'gurdā',
	'gurdi',
	'gurdo',
	'gurds',
	'gurdu',
	'guris',
	'gurka',
	'gurki',
	'gurkš',
	'gurķa',
	'gurķi',
	'gurķī',
	'gurķu',
	'gurls',
	'gurna',
	'gurnā',
	'gurni',
	'gurns',
	'gurnu',
	'gursi',
	'gurst',
	'guršu',
	'gurta',
	'gurtā',
	'gurti',
	'gurto',
	'gurts',
	'gurtu',
	'gusau',
	'guska',
	'gusļi',
	'gusto',
	'gutas',
	'gutta',
	'guvām',
	'guvāt',
	'guvis',
	'guzas',
	'guzna',
	'guznā',
	'guznu',
	'gužas',
	'gūgle',
	'gūglē',
	'gūgli',
	'gūgļu',
	'gūlas',
	'gūlām',
	'gūlās',
	'gūlāt',
	'gūlis',
	'gūlos',
	'gūsim',
	'gūsit',
	'gūsta',
	'gūstā',
	'gūsti',
	'gūsts',
	'gūstu',
	'gūšot',
	'gūtai',
	'gūtam',
	'gūtas',
	'gūtām',
	'gūtās',
	'gūtie',
	'gūtos',
	'gūtus',
	'gūzma',
	'gūzmā',
	'gūzmu',
	'gūžas',
	'gvano',
	'gvelz',
	'gvelž',
	'gveru',
	'ģeceļ',
	'ģeida',
	'ģeķis',
	'ģelaķ',
	'ģemze',
	'ģengi',
	'ģente',
	'ģēles',
	'ģērba',
	'ģērbā',
	'ģērbi',
	'ģērbj',
	'ģērbs',
	'ģērbt',
	'ģērbu',
	'ģērēs',
	'ģērēt',
	'ģidām',
	'ģidāt',
	'ģidis',
	'ģidīs',
	'ģiedu',
	'ģifte',
	'ģifts',
	'ģiģis',
	'ģikši',
	'ģikts',
	'ģilde',
	'ģildē',
	'ģildi',
	'ģilžu',
	'ģinis',
	'ģinšu',
	'ģinti',
	'ģintī',
	'ģints',
	'ģipka',
	'ģipss',
	'ģipšo',
	'ģipte',
	'ģipts',
	'ģirts',
	'ģiska',
	'ģista',
	'ģistā',
	'ģisti',
	'ģisto',
	'ģists',
	'ģistu',
	'ģībām',
	'ģībāt',
	'ģībis',
	'ģībsi',
	'ģībst',
	'ģībšu',
	'ģībta',
	'ģībtā',
	'ģībti',
	'ģībto',
	'ģībts',
	'ģībtu',
	'ģīgas',
	'ģīgās',
	'ģīgāt',
	'ģīlis',
	'ģīmim',
	'ģīmis',
	'ģīmja',
	'ģīmji',
	'ģīmju',
	'habls',
	'habri',
	'haccp',
	'hadji',
	'hadža',
	'hadžā',
	'hadži',
	'hadžs',
	'hadžu',
	'haena',
	'haifa',
	'haihe',
	'haika',
	'haikā',
	'haiks',
	'haiku',
	'haiti',
	'hajna',
	'haken',
	'hakha',
	'halbi',
	'haleo',
	'halki',
	'halla',
	'halle',
	'hallē',
	'halli',
	'hallo',
	'halļu',
	'halma',
	'halsa',
	'halva',
	'halvā',
	'halvu',
	'halze',
	'halzē',
	'halzi',
	'halžu',
	'hamas',
	'hamās',
	'hamma',
	'hamri',
	'hamsa',
	'handa',
	'hanja',
	'hanka',
	'hanko',
	'hanna',
	'hanta',
	'hantā',
	'hanti',
	'hants',
	'hantu',
	'hanza',
	'haoma',
	'haomā',
	'haomu',
	'haosa',
	'haosā',
	'haosi',
	'haoss',
	'haosu',
	'harcs',
	'harči',
	'harčo',
	'harfa',
	'harna',
	'harnā',
	'harnu',
	'harta',
	'hartā',
	'hartu',
	'haski',
	'hasta',
	'hašta',
	'hatki',
	'haube',
	'hausi',
	'haute',
	'hauva',
	'hauze',
	'havaķ',
	'havea',
	'havra',
	'hādži',
	'hādžs',
	'hānja',
	'hebei',
	'hedžs',
	'hedžu',
	'hefei',
	'heide',
	'heihe',
	'heijā',
	'heilo',
	'heisa',
	'heisā',
	'heiti',
	'hekla',
	'hekni',
	'heķis',
	'helis',
	'helle',
	'hello',
	'helma',
	'helms',
	'hends',
	'henga',
	'henna',
	'hennā',
	'hennu',
	'henri',
	'hepri',
	'herba',
	'herca',
	'hercā',
	'herci',
	'hercs',
	'hercu',
	'herka',
	'herma',
	'hermā',
	'hermu',
	'herne',
	'herņa',
	'herve',
	'hetao',
	'hevea',
	'hevra',
	'hērda',
	'hiāts',
	'hidra',
	'hidrā',
	'hidrs',
	'hidru',
	'hiēna',
	'hiēnā',
	'hiēnu',
	'hifas',
	'hiksi',
	'hilla',
	'himki',
	'himna',
	'himnā',
	'himnu',
	'himsa',
	'hindi',
	'hindu',
	'hipns',
	'hiršs',
	'hirte',
	'hisis',
	'hjūgo',
	'hlams',
	'hlora',
	'hlorā',
	'hlorē',
	'hlori',
	'hlors',
	'hloru',
	'hluka',
	'hļebs',
	'hoāna',
	'hoānā',
	'hoānu',
	'hoboe',
	'hobro',
	'hobsa',
	'hodža',
	'hohma',
	'hoija',
	'hoins',
	'hojna',
	'hokus',
	'hoķis',
	'holda',
	'holds',
	'holla',
	'holls',
	'holma',
	'homis',
	'homme',
	'homsa',
	'honda',
	'hondo',
	'honor',
	'hoppa',
	'horas',
	'horda',
	'hordā',
	'hordu',
	'horki',
	'horna',
	'horni',
	'hoska',
	'hosta',
	'hostā',
	'hosti',
	'hosts',
	'hostu',
	'houms',
	'house',
	'houss',
	'hovas',
	'hrami',
	'hroma',
	'hromā',
	'hromē',
	'hromi',
	'hroms',
	'hromu',
	'hrona',
	'huaka',
	'huana',
	'huari',
	'hubei',
	'hubli',
	'hueja',
	'huejā',
	'hueji',
	'huejs',
	'hueju',
	'hugli',
	'huiņa',
	'hulda',
	'hulks',
	'hulla',
	'humus',
	'hunta',
	'huntā',
	'huntu',
	'huņņi',
	'hurma',
	'hurmā',
	'hurmu',
	'husta',
	'hutba',
	'hvans',
	'hvara',
	'ibara',
	'ibēre',
	'ibērē',
	'ibēri',
	'ibēru',
	'ibica',
	'ibisa',
	'ibisā',
	'ibisi',
	'ibiss',
	'ibisu',
	'iceho',
	'iciks',
	'ičana',
	'idams',
	'ideja',
	'idejā',
	'ideju',
	'idila',
	'idile',
	'idiņš',
	'idišs',
	'idleo',
	'idols',
	'idoze',
	'idres',
	'idris',
	'iduna',
	'idzin',
	'idžma',
	'ieaco',
	'ieada',
	'ieadi',
	'ieadu',
	'ieara',
	'ieari',
	'iears',
	'ieart',
	'iearu',
	'ieaud',
	'ieaug',
	'ieauj',
	'ieaus',
	'ieaut',
	'ieauž',
	'ieāķē',
	'ieāva',
	'ieāvi',
	'ieāvu',
	'iebar',
	'iebāz',
	'iebāž',
	'ieber',
	'iebēg',
	'iecel',
	'ieceļ',
	'iecep',
	'iecer',
	'iedeg',
	'iedēj',
	'iedēs',
	'iedēt',
	'iedob',
	'iedod',
	'iedos',
	'iedot',
	'iedub',
	'iedun',
	'iedur',
	'ieecē',
	'ieeja',
	'ieejā',
	'ieeju',
	'ieēda',
	'ieēdi',
	'ieēdu',
	'ieēno',
	'ieēst',
	'iegāz',
	'iegāž',
	'iegli',
	'iegul',
	'iegūs',
	'iegūt',
	'ieies',
	'ieiet',
	'ieira',
	'ieiri',
	'ieirs',
	'ieirt',
	'ieiru',
	'iejāj',
	'iejās',
	'iejāt',
	'iejoz',
	'iejož',
	'iejūc',
	'iejūk',
	'iekal',
	'iekaļ',
	'iekam',
	'iekar',
	'iekas',
	'iekaš',
	'iekām',
	'iekāp',
	'iekās',
	'iekāš',
	'iekod',
	'iekop',
	'iekož',
	'iekša',
	'iekšā',
	'iekšu',
	'iekul',
	'iekuļ',
	'iekur',
	'iekūp',
	'ieķep',
	'ieķer',
	'ielec',
	'ielej',
	'ielīp',
	'ielīs',
	'ielīt',
	'ielob',
	'ieloc',
	'ielok',
	'ielts',
	'iemal',
	'iemaļ',
	'iemej',
	'iemet',
	'iemin',
	'iemīl',
	'iemīs',
	'iemīt',
	'iemīz',
	'iemīž',
	'iemūc',
	'iemūk',
	'ienāc',
	'ienāk',
	'ienes',
	'ieņem',
	'ieoda',
	'ieodi',
	'ieodu',
	'ieost',
	'ieožu',
	'ieper',
	'iepil',
	'iepin',
	'iepīs',
	'iepīt',
	'iepūs',
	'iepūš',
	'iepūt',
	'ierēc',
	'ierij',
	'ierim',
	'ieris',
	'ierit',
	'ierīb',
	'ierīs',
	'ierīt',
	'ieroc',
	'ierod',
	'ierok',
	'ieros',
	'ierus',
	'ierūc',
	'iesāc',
	'iesāk',
	'ieser',
	'iesēd',
	'iesēj',
	'iesēs',
	'iesēt',
	'iesim',
	'iesit',
	'ieska',
	'ieskā',
	'iesma',
	'iesmā',
	'iesmi',
	'iesms',
	'iesmu',
	'iesna',
	'iesti',
	'iesūc',
	'iešot',
	'iešuj',
	'iešus',
	'iešūs',
	'iešūt',
	'ietai',
	'ietam',
	'ietas',
	'ietām',
	'ietās',
	'ietek',
	'ietēs',
	'ietēš',
	'ietie',
	'ietin',
	'ietīs',
	'ietīt',
	'ietne',
	'ietnē',
	'ietni',
	'ietņu',
	'ietop',
	'ietos',
	'ietur',
	'ietus',
	'ietve',
	'ietvē',
	'ietvi',
	'ieurb',
	'ievāc',
	'ieved',
	'ievel',
	'ieveļ',
	'ievem',
	'iever',
	'ievij',
	'ievil',
	'ieviļ',
	'ievīs',
	'ievīt',
	'iezām',
	'iezāt',
	'iezel',
	'iezeļ',
	'iezib',
	'iezim',
	'iezis',
	'iezīd',
	'iezīs',
	'iezīž',
	'iezta',
	'ieztā',
	'iezti',
	'iezto',
	'iezts',
	'ieztu',
	'iezuz',
	'iežam',
	'iežat',
	'iežos',
	'iežot',
	'iežus',
	'iežūs',
	'iežūt',
	'igala',
	'igalo',
	'igate',
	'igrek',
	'ihlen',
	'ijana',
	'ijars',
	'ikars',
	'ikata',
	'ikeja',
	'ikike',
	'ikmēr',
	'ikona',
	'ikonā',
	'ikonu',
	'ilava',
	'ildza',
	'ilgaa',
	'ilgaā',
	'ilgai',
	'ilgam',
	'ilgas',
	'ilgau',
	'ilgām',
	'ilgās',
	'ilgāt',
	'ilgen',
	'ilgie',
	'ilgis',
	'ilgos',
	'ilgot',
	'ilgsi',
	'ilgst',
	'ilgšu',
	'ilgta',
	'ilgtā',
	'ilgti',
	'ilgto',
	'ilgts',
	'ilgtu',
	'ilgum',
	'ilgus',
	'iliča',
	'iliņi',
	'ilīta',
	'ilītā',
	'ilīti',
	'ilīts',
	'ilītu',
	'ilksi',
	'ilksī',
	'ilkss',
	'ilkšu',
	'iloka',
	'ilons',
	'ilova',
	'ilsen',
	'iļavu',
	'iļģis',
	'imago',
	'imama',
	'imamā',
	'imami',
	'imams',
	'imamu',
	'imana',
	'imara',
	'imāma',
	'imāmā',
	'imāmi',
	'imāms',
	'imāmu',
	'imāns',
	'imera',
	'imeri',
	'imitē',
	'imīds',
	'imīni',
	'imola',
	'impac',
	'imšas',
	'imula',
	'imūna',
	'imūnā',
	'imūni',
	'imūno',
	'imūns',
	'imūnu',
	'inari',
	'incis',
	'indēs',
	'indēt',
	'indie',
	'indio',
	'indra',
	'indzi',
	'indzu',
	'ineni',
	'inese',
	'ineši',
	'infra',
	'ingas',
	'ingri',
	'inina',
	'iniša',
	'inkas',
	'insis',
	'intas',
	'intus',
	'inula',
	'iņsko',
	'ipiķi',
	'ipoha',
	'ippik',
	'irato',
	'irāka',
	'irāna',
	'irāņi',
	'irbim',
	'irbis',
	'irbja',
	'irbji',
	'irbju',
	'irdai',
	'irdam',
	'irdas',
	'irdām',
	'irdās',
	'irdie',
	'irdna',
	'irdnā',
	'irdne',
	'irdnē',
	'irdni',
	'irdno',
	'irdns',
	'irdnu',
	'irdņu',
	'irdos',
	'irdus',
	'irdza',
	'irdzi',
	'irdzu',
	'irena',
	'irgot',
	'irgsi',
	'irgšu',
	'irgta',
	'irgtā',
	'irgti',
	'irgto',
	'irgts',
	'irgtu',
	'irien',
	'iries',
	'iriga',
	'iriģē',
	'irina',
	'irini',
	'irinu',
	'iriri',
	'irīda',
	'irīta',
	'irītā',
	'irīti',
	'irīts',
	'irītu',
	'irkas',
	'irkle',
	'ironi',
	'irons',
	'irsim',
	'irsis',
	'irsit',
	'irska',
	'irsti',
	'irstu',
	'iršos',
	'iršot',
	'iršus',
	'irtai',
	'irtam',
	'irtas',
	'irtām',
	'irtās',
	'irtie',
	'irtos',
	'irtus',
	'irube',
	'iruna',
	'irusi',
	'irusī',
	'iruša',
	'irušā',
	'iruši',
	'irušo',
	'irušu',
	'iseta',
	'iskra',
	'islet',
	'isola',
	'istra',
	'istre',
	'išaks',
	'išēze',
	'išima',
	'itais',
	'itaka',
	'itala',
	'itami',
	'itāļi',
	'ituri',
	'iudri',
	'iupac',
	'iupap',
	'ivans',
	'ivasi',
	'ivisa',
	'ivone',
	'ivoni',
	'ivrea',
	'izaco',
	'izada',
	'izadi',
	'izadu',
	'izalo',
	'izana',
	'izara',
	'izari',
	'izars',
	'izart',
	'izaru',
	'izaud',
	'izaug',
	'izauj',
	'izaus',
	'izaut',
	'izauž',
	'izāķē',
	'izāra',
	'izāva',
	'izāvi',
	'izāvu',
	'izāzē',
	'izbar',
	'izbāz',
	'izbāž',
	'izber',
	'izbēg',
	'izboz',
	'izbož',
	'izbur',
	'izbūs',
	'izbūt',
	'izcel',
	'izceļ',
	'izcep',
	'izčib',
	'izdeg',
	'izdej',
	'izdēd',
	'izdēj',
	'izdēs',
	'izdēt',
	'izdīc',
	'izdob',
	'izdod',
	'izdos',
	'izdot',
	'izdub',
	'izdur',
	'izdūc',
	'izecē',
	'izeja',
	'izejā',
	'izeju',
	'izels',
	'izelš',
	'izēda',
	'izēdi',
	'izēdu',
	'izēno',
	'izēra',
	'izēst',
	'izgāz',
	'izgāž',
	'izgul',
	'izgūt',
	'izies',
	'iziet',
	'izirs',
	'izirt',
	'iziru',
	'izīda',
	'izīra',
	'izīrē',
	'izīri',
	'izīru',
	'izjāj',
	'izjās',
	'izjāt',
	'izjoz',
	'izjož',
	'izjūc',
	'izjūk',
	'izjūt',
	'izkal',
	'izkaļ',
	'izkar',
	'izkāp',
	'izkās',
	'izkāš',
	'izkod',
	'izkop',
	'izkož',
	'izkul',
	'izkuļ',
	'izkur',
	'izkūp',
	'izķer',
	'izlec',
	'izlej',
	'izlem',
	'izlep',
	'izlīs',
	'izlīt',
	'izlob',
	'izloc',
	'izlok',
	'izļūc',
	'izļūk',
	'izmal',
	'izmaļ',
	'izmet',
	'izmēz',
	'izmēž',
	'izmin',
	'izmīl',
	'izmīs',
	'izmīt',
	'izmīz',
	'izmīž',
	'izmūc',
	'izmūk',
	'iznāc',
	'iznāk',
	'iznes',
	'izņem',
	'izoda',
	'izodi',
	'izodu',
	'izola',
	'izolē',
	'izols',
	'izops',
	'izost',
	'izožu',
	'izper',
	'izpil',
	'izpin',
	'izpis',
	'izpiš',
	'izpīs',
	'izpīt',
	'izput',
	'izpūs',
	'izpūš',
	'izpūt',
	'izrāj',
	'izrās',
	'izrāt',
	'izrēc',
	'izrij',
	'izris',
	'izrit',
	'izrīb',
	'izrīs',
	'izrīt',
	'izroc',
	'izrok',
	'izsan',
	'izsāp',
	'izsēd',
	'izsēj',
	'izsēs',
	'izsēt',
	'izsit',
	'izsūc',
	'izsūt',
	'izšuj',
	'izšūs',
	'izšūt',
	'iztek',
	'iztēs',
	'iztēš',
	'iztin',
	'iztīs',
	'iztīt',
	'iztop',
	'iztur',
	'izurb',
	'izvāc',
	'izved',
	'izvel',
	'izveļ',
	'izvem',
	'izver',
	'izvij',
	'izvil',
	'izviļ',
	'izvīs',
	'izvīt',
	'izzel',
	'izzeļ',
	'izzib',
	'izzīd',
	'izzīž',
	'izzog',
	'izzūd',
	'izžūs',
	'izžūt',
	'ižori',
	'īblis',
	'īdama',
	'īdamā',
	'īdami',
	'īdamo',
	'īdams',
	'īdamu',
	'īdena',
	'īdens',
	'īdenu',
	'īdeņa',
	'īdēja',
	'īdēji',
	'īdēju',
	'īdēsi',
	'īdēšu',
	'īdēta',
	'īdētā',
	'īdēti',
	'īdēto',
	'īdēts',
	'īdētu',
	'īdiet',
	'īdoša',
	'īdošā',
	'īdoši',
	'īdošo',
	'īdošs',
	'īdošu',
	'īdzis',
	'īgnai',
	'īgnam',
	'īgnas',
	'īgnām',
	'īgnās',
	'īgnēs',
	'īgnēt',
	'īgnie',
	'īgnis',
	'īgnos',
	'īgnus',
	'īgsim',
	'īgsit',
	'īgsti',
	'īgstu',
	'īgšot',
	'īgtai',
	'īgtam',
	'īgtas',
	'īgtām',
	'īgtās',
	'īgtie',
	'īgtos',
	'īgtus',
	'īgusi',
	'īgusī',
	'īguša',
	'īgušā',
	'īguši',
	'īgušo',
	'īgušu',
	'ījabs',
	'īkšķi',
	'īlena',
	'īlenā',
	'īleni',
	'īlens',
	'īlenu',
	'īlēns',
	'īlija',
	'īliņš',
	'īmirs',
	'īpaša',
	'īpašā',
	'īpaši',
	'īpašo',
	'īpašs',
	'īpašu',
	'īpata',
	'īpatā',
	'īpati',
	'īpato',
	'īpats',
	'īpatu',
	'īrāms',
	'īrēja',
	'īrēji',
	'īrēju',
	'īrēsi',
	'īrēšu',
	'īrēta',
	'īrētā',
	'īrēti',
	'īrēto',
	'īrēts',
	'īrētu',
	'īries',
	'īrija',
	'īrisa',
	'īrisā',
	'īrisi',
	'īriss',
	'īrisu',
	'īsais',
	'īsajā',
	'īsiem',
	'īsina',
	'īsini',
	'īsinu',
	'īsiņš',
	'īsten',
	'īsuma',
	'īsumā',
	'īsumi',
	'īsums',
	'īsumu',
	'ītona',
	'īvāni',
	'īveņa',
	'īzāks',
	'jabot',
	'jahja',
	'jahta',
	'jahtā',
	'jahtu',
	'jahve',
	'jaika',
	'jaila',
	'jaina',
	'jairs',
	'jajce',
	'jakša',
	'jakts',
	'jalta',
	'jamba',
	'jambā',
	'jambi',
	'jambs',
	'jambu',
	'jammu',
	'jamot',
	'jamss',
	'jangs',
	'janka',
	'janki',
	'japon',
	'jarda',
	'jardā',
	'jardi',
	'jards',
	'jardu',
	'jarvi',
	'jaski',
	'jaslo',
	'jasma',
	'jasna',
	'jasno',
	'jašma',
	'jašmā',
	'jašmu',
	'jauca',
	'jauci',
	'jaucu',
	'jauči',
	'jauda',
	'jaudā',
	'jaudu',
	'jauja',
	'jauji',
	'jauju',
	'jauka',
	'jaukā',
	'jauki',
	'jauko',
	'jauks',
	'jaukt',
	'jauku',
	'jauls',
	'jauna',
	'jaunā',
	'jauni',
	'jauno',
	'jauns',
	'jaunu',
	'jaure',
	'jauri',
	'jausi',
	'jaust',
	'jaušs',
	'jaušu',
	'jauta',
	'jautā',
	'jauti',
	'jauto',
	'jauts',
	'jautu',
	'jazda',
	'jāaco',
	'jāada',
	'jāasē',
	'jāaug',
	'jāauj',
	'jāauž',
	'jāāķē',
	'jāāzē',
	'jābar',
	'jābāž',
	'jābed',
	'jāber',
	'jābēg',
	'jābož',
	'jābur',
	'jābūt',
	'jāceļ',
	'jācep',
	'jācer',
	'jāčab',
	'jāčuč',
	'jāčum',
	'jādām',
	'jādāt',
	'jādej',
	'jāder',
	'jādēd',
	'jādēj',
	'jādim',
	'jādip',
	'jādīc',
	'jādīs',
	'jādīt',
	'jādod',
	'jādot',
	'jādun',
	'jādur',
	'jādus',
	'jādūc',
	'jāecē',
	'jāelš',
	'jāēno',
	'jāgāž',
	'jāgul',
	'jāiet',
	'jāiež',
	'jāīrē',
	'jājam',
	'jājat',
	'jājāj',
	'jājām',
	'jājāt',
	'jājis',
	'jājot',
	'jājož',
	'jājūk',
	'jājūt',
	'jākaļ',
	'jākar',
	'jākaš',
	'jākāš',
	'jākož',
	'jākuļ',
	'jākur',
	'jākut',
	'jākūp',
	'jāķep',
	'jāķer',
	'jālec',
	'jālej',
	'jālēš',
	'jālīp',
	'jālok',
	'jālon',
	'jālūp',
	'jālūr',
	'jāļum',
	'jāļūk',
	'jāmaļ',
	'jāmāc',
	'jāmāj',
	'jāmāk',
	'jāmej',
	'jāmet',
	'jāmēj',
	'jāmēž',
	'jāmin',
	'jāmīl',
	'jāmīt',
	'jāmīž',
	'jāmūk',
	'jānāk',
	'jānes',
	'jānis',
	'jānus',
	'jāņem',
	'jāņot',
	'jāogo',
	'jāotē',
	'jāpeļ',
	'jāper',
	'jāpil',
	'jāpin',
	'jāpiš',
	'jāpoš',
	'jāput',
	'jāpūš',
	'jārāj',
	'jārej',
	'jārēc',
	'jārij',
	'jāris',
	'jārit',
	'jārīb',
	'jārls',
	'jārod',
	'jārok',
	'jārūc',
	'jārūk',
	'jāsāk',
	'jāsāp',
	'jāser',
	'jāsēc',
	'jāsēd',
	'jāsēj',
	'jāsim',
	'jāsit',
	'jāsūc',
	'jāsūt',
	'jāšot',
	'jāšuj',
	'jāšus',
	'jātai',
	'jātam',
	'jātas',
	'jātām',
	'jātās',
	'jātek',
	'jāteš',
	'jātēš',
	'jātic',
	'jātie',
	'jātin',
	'jātop',
	'jātos',
	'jātup',
	'jātur',
	'jātus',
	'jātuš',
	'jāurd',
	'jāuto',
	'jāūbo',
	'jāūkš',
	'jāvar',
	'jāvāc',
	'jāvām',
	'jāvāt',
	'jāvāž',
	'jāved',
	'jāveļ',
	'jāver',
	'jāvēž',
	'jāvij',
	'jāviļ',
	'jāvir',
	'jāvis',
	'jāvīd',
	'jāzeļ',
	'jāzib',
	'jāzīž',
	'jāzog',
	'jāzum',
	'jāzuz',
	'jāzūd',
	'jebal',
	'jebšu',
	'jefta',
	'jegas',
	'jehus',
	'jehvi',
	'jeida',
	'jelga',
	'jelgs',
	'jelsa',
	'jeļņa',
	'jence',
	'jenči',
	'jeņči',
	'jessi',
	'jeste',
	'jesti',
	'ješka',
	'jezda',
	'jezga',
	'jezgā',
	'jezgu',
	'jēcis',
	'jēdza',
	'jēdzi',
	'jēdzu',
	'jēgsi',
	'jēgšu',
	'jēgta',
	'jēgtā',
	'jēgti',
	'jēgto',
	'jēgts',
	'jēgtu',
	'jēlai',
	'jēlam',
	'jēlas',
	'jēlām',
	'jēlās',
	'jēlie',
	'jēlos',
	'jēlus',
	'jēpis',
	'jēras',
	'jēvle',
	'jēziņ',
	'jēzus',
	'jiema',
	'jihad',
	'jirbe',
	'jocis',
	'jogla',
	'jogos',
	'jogot',
	'johai',
	'joika',
	'jokam',
	'jokos',
	'jokot',
	'jokus',
	'jolka',
	'jolla',
	'jonim',
	'jonis',
	'jonna',
	'joņos',
	'joņot',
	'joņus',
	'jorka',
	'joske',
	'joski',
	'josla',
	'joslā',
	'josls',
	'joslu',
	'josma',
	'josta',
	'jostā',
	'joste',
	'josti',
	'josts',
	'jostu',
	'joška',
	'joula',
	'joule',
	'jozām',
	'jozās',
	'jozāt',
	'jozis',
	'jozīs',
	'jozos',
	'jozta',
	'joztā',
	'jozti',
	'jozto',
	'jozts',
	'joztu',
	'jozua',
	'jožam',
	'jožas',
	'jožat',
	'jožos',
	'jožot',
	'juani',
	'juaņa',
	'juaņā',
	'juaņu',
	'jucis',
	'judīt',
	'judra',
	'jugla',
	'jugra',
	'juiga',
	'jukaa',
	'jukaā',
	'jukai',
	'jukas',
	'jukau',
	'jukām',
	'jukāt',
	'jukna',
	'juksi',
	'jukšu',
	'jukta',
	'juktā',
	'jukti',
	'jukto',
	'jukts',
	'juktu',
	'julis',
	'jumim',
	'jumis',
	'jumja',
	'jumji',
	'jumju',
	'jumsi',
	'jumst',
	'jumšu',
	'jumta',
	'jumtā',
	'jumti',
	'jumto',
	'jumts',
	'jumtu',
	'junco',
	'junda',
	'jundā',
	'jundī',
	'jundu',
	'junga',
	'jungā',
	'jungs',
	'jungu',
	'junhe',
	'junka',
	'junta',
	'junts',
	'jupim',
	'jupis',
	'jupja',
	'jupji',
	'jupju',
	'jurga',
	'jurģi',
	'juris',
	'jurka',
	'jurki',
	'jurta',
	'jurtā',
	'jurtu',
	'jurva',
	'jusma',
	'jusms',
	'justa',
	'justā',
	'justi',
	'justo',
	'justs',
	'justu',
	'juška',
	'juški',
	'jutām',
	'jutās',
	'jutāt',
	'jutis',
	'jutīs',
	'jutos',
	'jūdra',
	'jūdza',
	'jūdze',
	'jūdzē',
	'jūdzi',
	'jūdzu',
	'jūdžu',
	'jūgsi',
	'jūgšu',
	'jūgta',
	'jūgtā',
	'jūgti',
	'jūgto',
	'jūgts',
	'jūgtu',
	'jūkam',
	'jūkat',
	'jūkot',
	'jūmām',
	'jūmās',
	'jūmāt',
	'jūmis',
	'jūmos',
	'jūras',
	'jūris',
	'jūsma',
	'jūsmā',
	'jūsmo',
	'jūsmu',
	'jūsos',
	'jūsot',
	'jūšos',
	'jūšus',
	'jūtaa',
	'jūtaā',
	'jūtai',
	'jūtam',
	'jūtas',
	'jūtat',
	'jūtau',
	'jūtim',
	'jūtis',
	'jūtos',
	'jūtot',
	'kaaba',
	'kabat',
	'kabis',
	'kable',
	'kačāt',
	'kačka',
	'kadai',
	'kadis',
	'kadms',
	'kadra',
	'kadrā',
	'kadrē',
	'kadri',
	'kadrs',
	'kadru',
	'kadži',
	'kaedi',
	'kafri',
	'kafue',
	'kahre',
	'kaiba',
	'kaibe',
	'kaids',
	'kaifs',
	'kaija',
	'kaijā',
	'kaiju',
	'kaiks',
	'kaila',
	'kailā',
	'kaili',
	'kailo',
	'kails',
	'kailu',
	'kaine',
	'kains',
	'kainū',
	'kaipt',
	'kaira',
	'kairā',
	'kaire',
	'kairē',
	'kairi',
	'kairo',
	'kairs',
	'kairu',
	'kaisa',
	'kaisi',
	'kaiss',
	'kaist',
	'kaisu',
	'kaiši',
	'kaišu',
	'kaita',
	'kaite',
	'kaitē',
	'kaiti',
	'kaiva',
	'kaivā',
	'kaive',
	'kaivu',
	'kakao',
	'kakas',
	'kakās',
	'kakāt',
	'kakla',
	'kaklā',
	'kakle',
	'kakli',
	'kakls',
	'kaklu',
	'kakšu',
	'kakti',
	'kaktī',
	'kakts',
	'kakva',
	'kaķēs',
	'kaķēt',
	'kaķis',
	'kalām',
	'kalās',
	'kalāt',
	'kalba',
	'kaled',
	'kalei',
	'kalis',
	'kalka',
	'kalkā',
	'kalki',
	'kalko',
	'kalks',
	'kalku',
	'kalla',
	'kallā',
	'kallu',
	'kalma',
	'kalme',
	'kalmē',
	'kalmi',
	'kalna',
	'kalnā',
	'kalni',
	'kalns',
	'kalnu',
	'kalos',
	'kalot',
	'kalpa',
	'kalpā',
	'kalpe',
	'kalpi',
	'kalpo',
	'kalps',
	'kalpu',
	'kalsi',
	'kalst',
	'kalšu',
	'kalta',
	'kaltā',
	'kalte',
	'kaltē',
	'kalti',
	'kalto',
	'kalts',
	'kaltu',
	'kalva',
	'kalve',
	'kalvē',
	'kalvi',
	'kaļam',
	'kaļas',
	'kaļat',
	'kaļķe',
	'kaļķi',
	'kaļķo',
	'kaļņa',
	'kaļos',
	'kaļot',
	'kaļva',
	'kaļvi',
	'kamar',
	'kamas',
	'kamat',
	'kamaz',
	'kamba',
	'kambi',
	'kamer',
	'kames',
	'kamet',
	'kamēr',
	'kamēt',
	'kamis',
	'kamīt',
	'kamka',
	'kamma',
	'kamms',
	'kampa',
	'kampi',
	'kampj',
	'kamps',
	'kampt',
	'kampu',
	'kamsa',
	'kamša',
	'kamus',
	'kance',
	'kanči',
	'kandi',
	'kanga',
	'kango',
	'kanha',
	'kanki',
	'kanna',
	'kannā',
	'kannu',
	'kanoe',
	'kansa',
	'kanta',
	'kantā',
	'kante',
	'kanti',
	'kanto',
	'kants',
	'kantu',
	'kanva',
	'kanvā',
	'kanvu',
	'kaoni',
	'kapaa',
	'kapās',
	'kapāt',
	'kapiš',
	'kaple',
	'kaplē',
	'kapļa',
	'kapri',
	'kapsa',
	'kaput',
	'karam',
	'karas',
	'karat',
	'karba',
	'karca',
	'karci',
	'karcu',
	'karde',
	'kardē',
	'kardi',
	'kards',
	'kares',
	'kargi',
	'kargo',
	'karko',
	'karks',
	'karkš',
	'karkt',
	'karla',
	'karma',
	'karmā',
	'karme',
	'karms',
	'karmu',
	'karna',
	'karno',
	'karns',
	'karoč',
	'karos',
	'karot',
	'karpa',
	'karpā',
	'karpe',
	'karpi',
	'karpo',
	'karpu',
	'karre',
	'karsa',
	'karsē',
	'karsi',
	'karst',
	'karsu',
	'karša',
	'karši',
	'karšu',
	'karta',
	'kartā',
	'karte',
	'kartē',
	'karti',
	'karts',
	'kartu',
	'karva',
	'karžu',
	'kasai',
	'kasas',
	'kasām',
	'kasās',
	'kasāt',
	'kasba',
	'kasēs',
	'kasēt',
	'kasis',
	'kasīs',
	'kasīt',
	'kaska',
	'kaskā',
	'kasko',
	'kasku',
	'kasos',
	'kasot',
	'kaspi',
	'kassa',
	'kasse',
	'kasta',
	'kastā',
	'kaste',
	'kasti',
	'kasto',
	'kasts',
	'kastu',
	'kašam',
	'kašas',
	'kašat',
	'kašās',
	'kašāt',
	'kašis',
	'kašķe',
	'kašnē',
	'kašņā',
	'kašot',
	'kašpo',
	'katla',
	'katlā',
	'katli',
	'katls',
	'katlu',
	'katra',
	'katrs',
	'kauca',
	'kauce',
	'kauci',
	'kaucu',
	'kauči',
	'kauču',
	'kaugu',
	'kauja',
	'kaujā',
	'kauju',
	'kauka',
	'kauki',
	'kauks',
	'kaukt',
	'kauķa',
	'kauķi',
	'kaula',
	'kaulā',
	'kaule',
	'kaulē',
	'kauli',
	'kauls',
	'kaulu',
	'kauļi',
	'kauna',
	'kaunā',
	'kauni',
	'kauns',
	'kaunu',
	'kauņa',
	'kaupa',
	'kaupā',
	'kaupe',
	'kaupi',
	'kaupu',
	'kauri',
	'kaurs',
	'kausa',
	'kausē',
	'kausi',
	'kausī',
	'kauss',
	'kaust',
	'kausu',
	'kauša',
	'kaušs',
	'kaušu',
	'kauta',
	'kautā',
	'kauti',
	'kauto',
	'kauts',
	'kautu',
	'kavēs',
	'kavēt',
	'kavra',
	'kazin',
	'kazla',
	'kazls',
	'kazlu',
	'kazot',
	'kažas',
	'kādai',
	'kādēļ',
	'kājām',
	'kājos',
	'kājot',
	'kāķis',
	'kālab',
	'kālim',
	'kālis',
	'kāļos',
	'kāļus',
	'kāmēr',
	'kāmēs',
	'kāmēt',
	'kāmim',
	'kāmis',
	'kāmja',
	'kāmji',
	'kāmju',
	'kānis',
	'kāpas',
	'kāpām',
	'kāpās',
	'kāpāt',
	'kāpea',
	'kāpeā',
	'kāpei',
	'kāpes',
	'kāpeu',
	'kāpēc',
	'kāpis',
	'kāpju',
	'kāple',
	'kāpos',
	'kāpsi',
	'kāpšu',
	'kāpta',
	'kāptā',
	'kāpti',
	'kāpto',
	'kāpts',
	'kāptu',
	'kārai',
	'kāram',
	'kāras',
	'kārām',
	'kārās',
	'kārāt',
	'kārba',
	'kārbā',
	'kārbu',
	'kārca',
	'kārci',
	'kārcu',
	'kārde',
	'kāres',
	'kārie',
	'kāris',
	'kārkā',
	'kārks',
	'kārkš',
	'kārkt',
	'kārķi',
	'kārļi',
	'kārna',
	'kārnā',
	'kārni',
	'kārno',
	'kārns',
	'kārnu',
	'kāros',
	'kārot',
	'kārpa',
	'kārpā',
	'kārpi',
	'kārpu',
	'kārsa',
	'kārsi',
	'kārst',
	'kārsu',
	'kāršu',
	'kārta',
	'kārtā',
	'kārte',
	'kārti',
	'kārtī',
	'kārto',
	'kārts',
	'kārtu',
	'kārus',
	'kāsām',
	'kāsāt',
	'kāsēs',
	'kāsēt',
	'kāsim',
	'kāsis',
	'kāsīs',
	'kāsta',
	'kāstā',
	'kāsti',
	'kāsto',
	'kāsts',
	'kāstu',
	'kāšam',
	'kāšat',
	'kāšos',
	'kāšot',
	'kāšus',
	'kātos',
	'kātot',
	'kāvām',
	'kāvās',
	'kāvāt',
	'kāvis',
	'kāvos',
	'kāzaa',
	'kāzaā',
	'kāzai',
	'kāzas',
	'kāzau',
	'kāzos',
	'kāzot',
	'kāzus',
	'kdzei',
	'kečvi',
	'kedas',
	'kedra',
	'kehra',
	'keiba',
	'keiki',
	'keila',
	'keili',
	'keils',
	'keina',
	'keipa',
	'keira',
	'keiri',
	'keiši',
	'keivi',
	'kekss',
	'kelce',
	'kelme',
	'kelpi',
	'kelsa',
	'kelso',
	'kelti',
	'kendo',
	'kenga',
	'kenta',
	'kenti',
	'kents',
	'kepka',
	'kerča',
	'kerka',
	'kerli',
	'kerma',
	'kerna',
	'kernā',
	'kerni',
	'kerns',
	'kernu',
	'kešju',
	'ketša',
	'kēksa',
	'kēksā',
	'kēksi',
	'kēkss',
	'kēksu',
	'kēres',
	'khaki',
	'kiang',
	'kibdo',
	'kibla',
	'kička',
	'kihnu',
	'kikot',
	'kilis',
	'kilti',
	'kimči',
	'kimri',
	'kinga',
	'kingi',
	'kings',
	'kingu',
	'kinis',
	'kinki',
	'kintu',
	'kinza',
	'kioto',
	'kiots',
	'kipra',
	'kirka',
	'kirke',
	'kirna',
	'kirsa',
	'kirza',
	'kirzā',
	'kirzu',
	'kises',
	'kista',
	'kisva',
	'kiška',
	'kiški',
	'kitāb',
	'kivis',
	'kīhei',
	'kjats',
	'kjeti',
	'klabi',
	'klabu',
	'klača',
	'klačā',
	'klačo',
	'klaču',
	'klade',
	'kladē',
	'kladi',
	'klaja',
	'klajā',
	'klaji',
	'klajo',
	'klajs',
	'klajš',
	'klaju',
	'klakš',
	'klakt',
	'klami',
	'klana',
	'klanā',
	'kland',
	'klang',
	'klani',
	'klans',
	'klanu',
	'klaņi',
	'klapa',
	'klape',
	'klapo',
	'klapu',
	'klase',
	'klasē',
	'klasi',
	'klass',
	'klašu',
	'klažu',
	'klāja',
	'klājā',
	'klāji',
	'klājs',
	'klāju',
	'klāms',
	'klāna',
	'klānā',
	'klāne',
	'klāni',
	'klāns',
	'klānu',
	'klāra',
	'klāri',
	'klārs',
	'klāsi',
	'klāšu',
	'klāta',
	'klātā',
	'klāti',
	'klāto',
	'klāts',
	'klātu',
	'klāva',
	'klāvā',
	'klāvi',
	'klāvs',
	'klāvu',
	'kleja',
	'klejo',
	'klejs',
	'kleķē',
	'klens',
	'klepo',
	'kleps',
	'klera',
	'klers',
	'klese',
	'klest',
	'kleša',
	'klešā',
	'klešs',
	'klešu',
	'klēga',
	'klēra',
	'klērā',
	'klēri',
	'klērs',
	'klēru',
	'klēšu',
	'klēti',
	'klētī',
	'klēts',
	'kliba',
	'klibā',
	'klibi',
	'klibo',
	'klibs',
	'klibu',
	'klica',
	'klied',
	'kliež',
	'klifa',
	'klifā',
	'klifi',
	'klifs',
	'klifu',
	'kliģu',
	'klija',
	'klijā',
	'kliju',
	'klika',
	'kliki',
	'kliks',
	'klikš',
	'klikt',
	'kliķe',
	'klims',
	'klimt',
	'kling',
	'klipa',
	'klipā',
	'klips',
	'klipš',
	'klipt',
	'klipu',
	'klišī',
	'klišs',
	'klīda',
	'klīdi',
	'klīdu',
	'klīgt',
	'klīns',
	'klīpa',
	'klīrs',
	'klīst',
	'klīva',
	'klīve',
	'klīvi',
	'klīze',
	'klokē',
	'kloks',
	'kloķī',
	'klona',
	'klonā',
	'kloni',
	'klons',
	'klonu',
	'klope',
	'klopi',
	'klovs',
	'kloze',
	'kluba',
	'klubā',
	'klubi',
	'klubs',
	'klubu',
	'kluči',
	'kluga',
	'kluka',
	'klukš',
	'kluku',
	'klupa',
	'klupe',
	'klupi',
	'klups',
	'klupt',
	'klupu',
	'klusa',
	'klusā',
	'kluse',
	'klusē',
	'klusi',
	'kluso',
	'kluss',
	'klust',
	'klusu',
	'kluši',
	'klušu',
	'kluta',
	'klūga',
	'klūgā',
	'klūgu',
	'klūpi',
	'klūpu',
	'kļauj',
	'kļaus',
	'kļaut',
	'kļava',
	'kļavā',
	'kļavu',
	'kļāva',
	'kļāvi',
	'kļāvu',
	'kļima',
	'kļina',
	'kļoša',
	'kļošā',
	'kļoši',
	'kļošs',
	'kļošu',
	'kļova',
	'kļovi',
	'kļuču',
	'kļuka',
	'kļuva',
	'kļuvi',
	'kļuvu',
	'kļūda',
	'kļūdā',
	'kļūdu',
	'kļūma',
	'kļūmā',
	'kļūme',
	'kļūmē',
	'kļūmi',
	'kļūmo',
	'kļūms',
	'kļūmu',
	'kļūsi',
	'kļūst',
	'kļūšu',
	'kļūta',
	'kļūtā',
	'kļūti',
	'kļūto',
	'kļūts',
	'kļūtu',
	'kmeri',
	'knakš',
	'knapa',
	'knapā',
	'knape',
	'knapi',
	'knapo',
	'knaps',
	'knapu',
	'knaša',
	'knašā',
	'knaši',
	'knašo',
	'knašs',
	'knašu',
	'knāba',
	'knābā',
	'knābi',
	'knābj',
	'knābs',
	'knābt',
	'knābu',
	'knāpa',
	'knets',
	'knēts',
	'knibe',
	'knieb',
	'knies',
	'knieš',
	'kniez',
	'knifē',
	'knifs',
	'kniks',
	'knikš',
	'knipa',
	'knipu',
	'knist',
	'knita',
	'knite',
	'kniti',
	'knitu',
	'knīpa',
	'knīpā',
	'knīpu',
	'knīti',
	'knītu',
	'knopa',
	'knope',
	'knosa',
	'knupa',
	'knupi',
	'knups',
	'knupt',
	'knupu',
	'knuta',
	'knute',
	'knūpi',
	'knūpu',
	'kņada',
	'kņadā',
	'kņado',
	'kņadu',
	'kņapi',
	'kņaze',
	'kņazs',
	'kņiga',
	'kņīpa',
	'kņīpā',
	'kņīpu',
	'kņope',
	'kņudi',
	'kņudu',
	'kņupa',
	'kņupi',
	'kņups',
	'kņupt',
	'kņupu',
	'kņuta',
	'kņūpi',
	'kņūpu',
	'kņūta',
	'koaks',
	'koala',
	'koalā',
	'koalu',
	'koana',
	'koans',
	'kobas',
	'kobla',
	'koblo',
	'kobra',
	'kobrā',
	'kobru',
	'kobza',
	'kobzā',
	'kobzu',
	'kocis',
	'kocka',
	'kočas',
	'kodas',
	'kodām',
	'kodās',
	'kodāt',
	'kodei',
	'kodes',
	'kodēm',
	'kodēs',
	'kodēt',
	'kodis',
	'kodīs',
	'kodīt',
	'kodne',
	'kodnē',
	'kodni',
	'kodņu',
	'kodos',
	'kodot',
	'kodru',
	'kohma',
	'koidu',
	'koika',
	'koina',
	'koinē',
	'koins',
	'koira',
	'koiva',
	'kokle',
	'koklē',
	'kokli',
	'kokļu',
	'kokos',
	'kokot',
	'koksa',
	'koksā',
	'koksē',
	'koksi',
	'kokss',
	'koksu',
	'kokša',
	'kokts',
	'koķes',
	'koķēt',
	'koķis',
	'kolas',
	'kolba',
	'kolbā',
	'kolbe',
	'kolbu',
	'kolda',
	'kolim',
	'kolis',
	'kolīt',
	'koljē',
	'kolka',
	'kolla',
	'kolle',
	'kolli',
	'kollo',
	'kolma',
	'kolme',
	'kolni',
	'kolno',
	'kolos',
	'kolta',
	'koltā',
	'kolti',
	'kolts',
	'koltu',
	'kolva',
	'koļam',
	'koļīt',
	'koļos',
	'koļus',
	'komas',
	'kombo',
	'komet',
	'komfo',
	'komma',
	'komon',
	'komža',
	'komžā',
	'komžu',
	'konca',
	'konci',
	'konča',
	'konči',
	'končs',
	'konda',
	'konga',
	'kongā',
	'kongi',
	'kongo',
	'kongu',
	'konis',
	'konja',
	'konka',
	'konna',
	'konro',
	'konss',
	'konta',
	'kontā',
	'kontē',
	'konti',
	'konto',
	'konts',
	'kontu',
	'konus',
	'konva',
	'koņča',
	'kopam',
	'kopām',
	'kopās',
	'kopāt',
	'kopēs',
	'kopēt',
	'kopis',
	'kopju',
	'kopka',
	'kopla',
	'kopne',
	'kopos',
	'kopot',
	'kopra',
	'koprā',
	'kopru',
	'kopsi',
	'kopšu',
	'kopta',
	'koptā',
	'kopti',
	'kopto',
	'kopts',
	'koptu',
	'kopus',
	'korba',
	'korča',
	'korda',
	'kordā',
	'kordi',
	'kords',
	'kordu',
	'korfu',
	'korga',
	'korģe',
	'korim',
	'koris',
	'korka',
	'korki',
	'korķē',
	'korķī',
	'korma',
	'koros',
	'korpa',
	'korpi',
	'korra',
	'korsa',
	'korso',
	'korše',
	'korta',
	'kortā',
	'korti',
	'korts',
	'kortu',
	'korus',
	'korva',
	'korze',
	'kosas',
	'kosis',
	'kosju',
	'koska',
	'kosma',
	'kosmā',
	'kosmi',
	'kosms',
	'kosmu',
	'kosta',
	'kostā',
	'kosti',
	'kosto',
	'kosts',
	'kostu',
	'košas',
	'koška',
	'košļā',
	'košus',
	'kotēs',
	'kotēt',
	'kotka',
	'kotli',
	'kotsa',
	'koučs',
	'kouts',
	'kovšu',
	'kovža',
	'kožam',
	'kožas',
	'kožat',
	'kožļā',
	'kožos',
	'kožot',
	'kpfsr',
	'krabi',
	'krabs',
	'kraha',
	'krahā',
	'krahi',
	'krahs',
	'krahu',
	'krajs',
	'kraks',
	'krakš',
	'krama',
	'kramā',
	'krami',
	'krams',
	'kramu',
	'krana',
	'krani',
	'kraņa',
	'krapa',
	'krape',
	'kraps',
	'krapš',
	'krapt',
	'krapu',
	'krasa',
	'krasā',
	'krasi',
	'kraso',
	'krass',
	'krasu',
	'krata',
	'krati',
	'kratu',
	'krauc',
	'krauj',
	'kraus',
	'kraut',
	'krava',
	'kravā',
	'krave',
	'kravu',
	'krāca',
	'krāce',
	'krācē',
	'krāci',
	'krācu',
	'krāču',
	'krāga',
	'krāgs',
	'krāja',
	'krājā',
	'krāji',
	'krāju',
	'krāka',
	'krākā',
	'krāko',
	'krāks',
	'krākt',
	'krāku',
	'krāla',
	'krālā',
	'krāli',
	'krāls',
	'krālu',
	'krāma',
	'krāmā',
	'krāmē',
	'krāmi',
	'krāms',
	'krāmu',
	'krāna',
	'krānā',
	'krāni',
	'krāns',
	'krānu',
	'krāpa',
	'krāpi',
	'krāpj',
	'krāps',
	'krāpt',
	'krāpu',
	'krāsa',
	'krāsā',
	'krāsi',
	'krāso',
	'krāss',
	'krāsu',
	'krāšu',
	'krāta',
	'krātā',
	'krāte',
	'krāti',
	'krāto',
	'krāts',
	'krātu',
	'krāva',
	'krāvi',
	'krāvu',
	'kredo',
	'kreja',
	'krejo',
	'kreks',
	'krekš',
	'krema',
	'kremē',
	'krems',
	'kremš',
	'kremt',
	'krens',
	'krepa',
	'krepā',
	'krepi',
	'kreps',
	'krept',
	'krepu',
	'krese',
	'kresē',
	'kresi',
	'kreša',
	'krešu',
	'kreva',
	'kreve',
	'krevē',
	'krevi',
	'krēma',
	'krēmā',
	'krēmi',
	'krēms',
	'krēmu',
	'krēpa',
	'krēpe',
	'krēpo',
	'krēps',
	'krēta',
	'krēts',
	'krēza',
	'krēzā',
	'krēzi',
	'krēzs',
	'krēzu',
	'kriet',
	'krija',
	'krijā',
	'krijs',
	'kriju',
	'krika',
	'krikā',
	'kriks',
	'krikš',
	'kriku',
	'kriķi',
	'krila',
	'krilā',
	'krili',
	'krils',
	'krilu',
	'krima',
	'krimš',
	'kriņa',
	'krips',
	'kripš',
	'kript',
	'kripu',
	'kriri',
	'krisa',
	'kriss',
	'krist',
	'kriša',
	'kriši',
	'krišu',
	'krita',
	'kriti',
	'kritu',
	'kriva',
	'krivs',
	'krizi',
	'krīči',
	'krīks',
	'krīna',
	'krīta',
	'krītā',
	'krīti',
	'krīto',
	'krīts',
	'krītu',
	'krīvi',
	'krīvs',
	'krīze',
	'krīzē',
	'krīzi',
	'krīža',
	'krīži',
	'krīžu',
	'kroča',
	'kroga',
	'krogā',
	'krogi',
	'krogo',
	'krogs',
	'krogu',
	'kroģē',
	'kroiķ',
	'kroja',
	'krojs',
	'kroka',
	'krokā',
	'kroko',
	'kroku',
	'kroķe',
	'kroli',
	'kroms',
	'krona',
	'krone',
	'kronē',
	'kroni',
	'krons',
	'kroņa',
	'kroņi',
	'krops',
	'krosa',
	'krosā',
	'krosi',
	'kross',
	'krosu',
	'krota',
	'krote',
	'krozē',
	'krško',
	'kruba',
	'krube',
	'kruga',
	'kruit',
	'kruja',
	'kruki',
	'kruks',
	'kruķē',
	'krums',
	'krupa',
	'krupā',
	'krupi',
	'krups',
	'krupu',
	'krusa',
	'krusā',
	'krusu',
	'krušs',
	'kruta',
	'krutā',
	'kruti',
	'kruto',
	'kruts',
	'kruva',
	'krūce',
	'krūda',
	'krūka',
	'krūkā',
	'krūku',
	'krūma',
	'krūmā',
	'krūmi',
	'krūms',
	'krūmu',
	'krūšu',
	'krūte',
	'krūti',
	'krūtī',
	'krūts',
	'krūza',
	'krūze',
	'krūzē',
	'krūzi',
	'krūzs',
	'krūžu',
	'ksdsp',
	'ksolo',
	'kšiža',
	'kubas',
	'kubis',
	'kubla',
	'kublā',
	'kubli',
	'kubls',
	'kublu',
	'kucēt',
	'kucis',
	'kučas',
	'kučka',
	'kudiš',
	'kudla',
	'kudls',
	'kudža',
	'kufra',
	'kugra',
	'kuģis',
	'kuģos',
	'kuģot',
	'kuhmo',
	'kuhva',
	'kuika',
	'kuiki',
	'kuiļa',
	'kuiļu',
	'kuito',
	'kuits',
	'kukai',
	'kukla',
	'kukli',
	'kukņa',
	'kukņā',
	'kukņu',
	'kukot',
	'kuksa',
	'kukša',
	'kuķis',
	'kulba',
	'kulbā',
	'kulbu',
	'kulda',
	'kuldā',
	'kuldu',
	'kulis',
	'kulka',
	'kulla',
	'kulpa',
	'kulsi',
	'kulša',
	'kulšu',
	'kulta',
	'kultā',
	'kulti',
	'kulto',
	'kults',
	'kultu',
	'kuļam',
	'kuļas',
	'kuļat',
	'kuļās',
	'kuļāt',
	'kuļķi',
	'kuļos',
	'kuļot',
	'kumba',
	'kumbo',
	'kumis',
	'kumla',
	'kumma',
	'kumpa',
	'kumpā',
	'kumpi',
	'kumpo',
	'kumps',
	'kumpt',
	'kumpu',
	'kunca',
	'kunce',
	'kunči',
	'kunda',
	'kunde',
	'kundi',
	'kuneo',
	'kunfu',
	'kunga',
	'kungā',
	'kungi',
	'kungo',
	'kungs',
	'kungu',
	'kunis',
	'kunna',
	'kunne',
	'kuntī',
	'kuņja',
	'kupča',
	'kupčs',
	'kupēs',
	'kupēt',
	'kupla',
	'kuplā',
	'kupli',
	'kuplo',
	'kupls',
	'kuplu',
	'kupre',
	'kupri',
	'kupša',
	'kuram',
	'kuras',
	'kurat',
	'kurās',
	'kurca',
	'kurci',
	'kurcu',
	'kurda',
	'kurdā',
	'kurdi',
	'kurds',
	'kurdu',
	'kuris',
	'kurka',
	'kurks',
	'kurkš',
	'kurkt',
	'kurla',
	'kurlā',
	'kurli',
	'kurlo',
	'kurls',
	'kurlu',
	'kurma',
	'kurme',
	'kurmi',
	'kurmī',
	'kurms',
	'kurna',
	'kurni',
	'kurns',
	'kurnu',
	'kuros',
	'kurot',
	'kurpe',
	'kurpē',
	'kurpi',
	'kurpu',
	'kursa',
	'kursā',
	'kursē',
	'kursi',
	'kurss',
	'kurst',
	'kursu',
	'kurši',
	'kuršu',
	'kurta',
	'kurtā',
	'kurtē',
	'kurti',
	'kurto',
	'kurts',
	'kurtu',
	'kurva',
	'kurve',
	'kurza',
	'kurze',
	'kusām',
	'kusāt',
	'kusis',
	'kusīs',
	'kuska',
	'kusko',
	'kusla',
	'kuslā',
	'kusli',
	'kuslo',
	'kusls',
	'kuslu',
	'kusta',
	'kustā',
	'kusti',
	'kusto',
	'kusts',
	'kustu',
	'kušāk',
	'kušīt',
	'kuška',
	'kušņa',
	'kušņi',
	'kutam',
	'kutas',
	'kutat',
	'kutba',
	'kutēs',
	'kutēt',
	'kutka',
	'kutki',
	'kutna',
	'kutno',
	'kutot',
	'kutro',
	'kuzis',
	'kuzka',
	'kuzma',
	'kuzņa',
	'kužas',
	'kūdām',
	'kūdāt',
	'kūdīs',
	'kūdīt',
	'kūdot',
	'kūdra',
	'kūdrā',
	'kūdro',
	'kūdru',
	'kūģis',
	'kūkas',
	'kūkos',
	'kūkot',
	'kūķis',
	'kūlām',
	'kūlās',
	'kūlāt',
	'kūles',
	'kūlim',
	'kūlis',
	'kūlos',
	'kūlot',
	'kūļās',
	'kūļāt',
	'kūļos',
	'kūļus',
	'kūņos',
	'kūņot',
	'kūpam',
	'kūpat',
	'kūpām',
	'kūpāt',
	'kūpēs',
	'kūpēt',
	'kūpis',
	'kūpot',
	'kūpsi',
	'kūpšu',
	'kūpta',
	'kūptā',
	'kūpti',
	'kūpto',
	'kūpts',
	'kūptu',
	'kūrām',
	'kūrās',
	'kūrāt',
	'kūrēt',
	'kūris',
	'kūros',
	'kūsas',
	'kūsās',
	'kūsāt',
	'kūsēs',
	'kūsēt',
	'kūsis',
	'kūsos',
	'kūsot',
	'kūsti',
	'kūstu',
	'kūtra',
	'kūtrā',
	'kūtri',
	'kūtro',
	'kūtrs',
	'kūtru',
	'kūvēt',
	'kvača',
	'kvadi',
	'kvakš',
	'kvari',
	'kvasa',
	'kvasi',
	'kvasī',
	'kvass',
	'kvasu',
	'kvazi',
	'kvekš',
	'kveni',
	'kvern',
	'kveta',
	'kvēla',
	'kvēlā',
	'kvēle',
	'kvēlē',
	'kvēli',
	'kvēlo',
	'kvēls',
	'kvēlu',
	'kvēļu',
	'kvēpa',
	'kvēpā',
	'kvēpē',
	'kvēpi',
	'kvēps',
	'kvēpt',
	'kvēpu',
	'kviec',
	'kvite',
	'kvitē',
	'kvito',
	'kvits',
	'kvīšu',
	'kvīte',
	'kvīti',
	'kvītī',
	'kvīts',
	'kvota',
	'kvotā',
	'kvote',
	'kvots',
	'kvotu',
	'ķauķe',
	'ķauķu',
	'ķebļi',
	'ķegļi',
	'ķeģis',
	'ķeire',
	'ķeiri',
	'ķeiši',
	'ķeiži',
	'ķekas',
	'ķeksē',
	'ķeksu',
	'ķelle',
	'ķellē',
	'ķelli',
	'ķelļu',
	'ķelme',
	'ķelna',
	'ķelne',
	'ķelpa',
	'ķelta',
	'ķeltā',
	'ķelti',
	'ķelts',
	'ķeltu',
	'ķemme',
	'ķemmē',
	'ķemmi',
	'ķempe',
	'ķence',
	'ķencē',
	'ķenča',
	'ķenga',
	'ķengā',
	'ķeņģe',
	'ķeņģi',
	'ķepam',
	'ķepat',
	'ķepām',
	'ķepāt',
	'ķepēs',
	'ķepēt',
	'ķepis',
	'ķepot',
	'ķepse',
	'ķepsi',
	'ķepšu',
	'ķepta',
	'ķeptā',
	'ķepti',
	'ķepto',
	'ķepts',
	'ķeptu',
	'ķeram',
	'ķeras',
	'ķerat',
	'ķerce',
	'ķeres',
	'ķeris',
	'ķerme',
	'ķeros',
	'ķerot',
	'ķerra',
	'ķerrā',
	'ķerru',
	'ķersi',
	'ķeršu',
	'ķerta',
	'ķertā',
	'ķerti',
	'ķerto',
	'ķerts',
	'ķertu',
	'ķerza',
	'ķesis',
	'ķeska',
	'ķeskā',
	'ķesku',
	'ķetna',
	'ķetnā',
	'ķetnu',
	'ķezis',
	'ķēdes',
	'ķēdēs',
	'ķēdēt',
	'ķēkša',
	'ķēķis',
	'ķēpās',
	'ķēpāt',
	'ķērām',
	'ķērās',
	'ķērāt',
	'ķērca',
	'ķērci',
	'ķērcu',
	'ķērde',
	'ķēris',
	'ķērks',
	'ķērkt',
	'ķērna',
	'ķērnā',
	'ķērne',
	'ķērnē',
	'ķērni',
	'ķērņu',
	'ķēros',
	'ķērpi',
	'ķērsa',
	'ķērsā',
	'ķērsu',
	'ķēzīs',
	'ķēzīt',
	'ķibis',
	'ķibji',
	'ķidaa',
	'ķidaā',
	'ķidai',
	'ķidas',
	'ķidau',
	'ķidās',
	'ķidāt',
	'ķidīt',
	'ķikšu',
	'ķilas',
	'ķilda',
	'ķildā',
	'ķilde',
	'ķildu',
	'ķilis',
	'ķilla',
	'ķilpa',
	'ķilpe',
	'ķinas',
	'ķinis',
	'ķinka',
	'ķipim',
	'ķipis',
	'ķipja',
	'ķipji',
	'ķipju',
	'ķiris',
	'ķirķi',
	'ķirpa',
	'ķirpā',
	'ķirpe',
	'ķirpo',
	'ķirpu',
	'ķirsī',
	'ķirša',
	'ķirza',
	'ķiržu',
	'ķisis',
	'ķists',
	'ķitēs',
	'ķitēt',
	'ķīķēs',
	'ķīķēt',
	'ķīķis',
	'ķīlas',
	'ķīlās',
	'ķīlāt',
	'ķīlēs',
	'ķīlēt',
	'ķīlim',
	'ķīlis',
	'ķīļos',
	'ķīļus',
	'ķīnis',
	'ķīpot',
	'ķīrim',
	'ķīris',
	'ķīros',
	'ķīrus',
	'ķīsim',
	'ķīsis',
	'ķīšos',
	'ķīšus',
	'ķobis',
	'ķocim',
	'ķocis',
	'ķočos',
	'ķočus',
	'ķoķis',
	'ķošča',
	'ķotka',
	'ķuģis',
	'ķulis',
	'ķunci',
	'ķurbe',
	'ķurķi',
	'ķurši',
	'ķusis',
	'ķutes',
	'ķutis',
	'ķūķis',
	'ķūlim',
	'ķūlis',
	'ķūļos',
	'ķūļus',
	'ķūsis',
	'labad',
	'labai',
	'labam',
	'laban',
	'labas',
	'labāk',
	'labām',
	'labās',
	'labāt',
	'labia',
	'labie',
	'labis',
	'labīt',
	'labos',
	'labot',
	'labus',
	'lacim',
	'lacio',
	'lacis',
	'lačos',
	'lačus',
	'lagoa',
	'lagos',
	'lagot',
	'lahti',
	'laiba',
	'laibs',
	'laida',
	'laidā',
	'laide',
	'laidē',
	'laidi',
	'laids',
	'laidu',
	'laika',
	'laikā',
	'laiki',
	'laiko',
	'laiks',
	'laiku',
	'laima',
	'laime',
	'laimē',
	'laimi',
	'laimo',
	'laims',
	'laiņi',
	'laipa',
	'laipā',
	'laipo',
	'laipu',
	'laist',
	'laita',
	'laiti',
	'laits',
	'laitu',
	'laiva',
	'laivā',
	'laivo',
	'laivu',
	'laiza',
	'laizi',
	'laizu',
	'laiža',
	'laižā',
	'laižu',
	'lajas',
	'lajta',
	'lakām',
	'lakāt',
	'lakna',
	'lakos',
	'lakot',
	'laksi',
	'lakši',
	'lakšu',
	'lakta',
	'laktā',
	'lakti',
	'lakto',
	'lakts',
	'laktu',
	'laķēs',
	'laķēt',
	'laķis',
	'lamaa',
	'lamaā',
	'lamai',
	'lamas',
	'lamau',
	'lamās',
	'lamāt',
	'lamba',
	'lamēd',
	'lamia',
	'lamma',
	'lamna',
	'lampa',
	'lampā',
	'lampu',
	'lamza',
	'lanco',
	'lančs',
	'landi',
	'lando',
	'langa',
	'lango',
	'langu',
	'lanka',
	'lankā',
	'lanku',
	'lansa',
	'lante',
	'laņģe',
	'laņģu',
	'laosa',
	'lapas',
	'lapis',
	'lapka',
	'lapos',
	'lapot',
	'lapsa',
	'lapsā',
	'lapse',
	'lapsi',
	'lapsu',
	'lapša',
	'lapši',
	'lapta',
	'laptā',
	'laptu',
	'lapua',
	'lares',
	'largo',
	'larkš',
	'larpa',
	'larus',
	'larva',
	'larvi',
	'lasām',
	'lasās',
	'lasāt',
	'lasēt',
	'lasim',
	'lasis',
	'lasīs',
	'lasīt',
	'laska',
	'lasks',
	'lasos',
	'lasot',
	'lasta',
	'lastā',
	'laste',
	'lasti',
	'lasts',
	'lastu',
	'laško',
	'lašor',
	'lašos',
	'lašus',
	'latos',
	'latot',
	'latva',
	'latve',
	'latvē',
	'latvi',
	'laube',
	'lauce',
	'laucē',
	'lauci',
	'lauča',
	'lauči',
	'lauču',
	'lauda',
	'laude',
	'laufa',
	'lauga',
	'lauka',
	'laukā',
	'lauki',
	'lauko',
	'lauks',
	'lauku',
	'lauķe',
	'laula',
	'laulā',
	'lauma',
	'laumā',
	'laumu',
	'laupa',
	'laupi',
	'laupu',
	'laura',
	'laurā',
	'laure',
	'lauri',
	'laurs',
	'lauru',
	'lauša',
	'lauta',
	'lautā',
	'lautu',
	'lauva',
	'lauvā',
	'lauvu',
	'lauza',
	'lauzā',
	'lauzi',
	'lauzt',
	'lauzu',
	'lauža',
	'lauži',
	'laužu',
	'lavās',
	'lavēs',
	'lavēt',
	'lavis',
	'lavka',
	'lavos',
	'lazda',
	'lazdā',
	'lazdu',
	'lazna',
	'lācim',
	'lācis',
	'lāčos',
	'lāčot',
	'lāčus',
	'lādas',
	'lādēs',
	'lādēt',
	'lādos',
	'lāgai',
	'lāgam',
	'lāgas',
	'lāgām',
	'lāgās',
	'lāgie',
	'lāgos',
	'lāgot',
	'lāgus',
	'lāmos',
	'lāmot',
	'lānim',
	'lānis',
	'lāņos',
	'lāņus',
	'lāpas',
	'lāpām',
	'lāpās',
	'lāpāt',
	'lāpīs',
	'lāpīt',
	'lāpos',
	'lāpot',
	'lāsēs',
	'lāsēt',
	'lāsma',
	'lāsmā',
	'lāsmo',
	'lāsmu',
	'lāsos',
	'lāsot',
	'lāsta',
	'lāstā',
	'lāsti',
	'lāsts',
	'lāstu',
	'lāški',
	'lāzne',
	'lāzņe',
	'lcesc',
	'lcvva',
	'lebic',
	'lebje',
	'lecam',
	'lecas',
	'lecat',
	'lecām',
	'lecis',
	'lecos',
	'lecot',
	'ledos',
	'ledot',
	'ledra',
	'ledum',
	'ledus',
	'lefke',
	'leges',
	'legio',
	'legno',
	'legua',
	'leģēs',
	'leģēt',
	'lehne',
	'leiči',
	'leida',
	'leija',
	'leika',
	'leiku',
	'leina',
	'leine',
	'leini',
	'leins',
	'leiņi',
	'leisi',
	'leist',
	'leiši',
	'leite',
	'lejam',
	'lejas',
	'lejat',
	'lejāk',
	'lejos',
	'lejot',
	'lejup',
	'lekas',
	'lekmi',
	'lekna',
	'leknā',
	'lekni',
	'lekno',
	'lekns',
	'leknu',
	'leksē',
	'lekša',
	'lekše',
	'lekši',
	'leķēs',
	'leķēt',
	'leķis',
	'lelis',
	'lelle',
	'lellē',
	'lelli',
	'lello',
	'lelļu',
	'lelos',
	'lelot',
	'lemgo',
	'lemju',
	'lemki',
	'lemma',
	'lemmā',
	'lemmu',
	'lemna',
	'lemot',
	'lempe',
	'lempē',
	'lempi',
	'lemsi',
	'lemšu',
	'lemta',
	'lemtā',
	'lemti',
	'lemto',
	'lemts',
	'lemtu',
	'lemur',
	'lenca',
	'lence',
	'lencē',
	'lenci',
	'lencu',
	'lenči',
	'lenčs',
	'lenču',
	'lendi',
	'lengu',
	'lenka',
	'lenks',
	'lenkt',
	'lenne',
	'lenšu',
	'lenta',
	'lentā',
	'lente',
	'lentē',
	'lenti',
	'lento',
	'lents',
	'lentu',
	'leona',
	'leone',
	'leova',
	'lepēs',
	'lepēt',
	'lepna',
	'lepnā',
	'lepni',
	'lepno',
	'lepns',
	'lepnu',
	'lepra',
	'leprā',
	'lepru',
	'lepsi',
	'lepšu',
	'lepus',
	'lerma',
	'lesba',
	'lesis',
	'leska',
	'lesko',
	'lesna',
	'leste',
	'lesto',
	'lests',
	'leška',
	'lešno',
	'letes',
	'letgo',
	'letki',
	'lezbe',
	'lēcas',
	'lēcām',
	'lēcās',
	'lēcāt',
	'lēces',
	'lēcis',
	'lēcos',
	'lēdas',
	'lējām',
	'lējās',
	'lējāt',
	'lējis',
	'lējos',
	'lēkas',
	'lēkās',
	'lēkāt',
	'lēkme',
	'lēkmē',
	'lēkmi',
	'lēksi',
	'lēkša',
	'lēkši',
	'lēkšo',
	'lēkšu',
	'lēkta',
	'lēktā',
	'lēkti',
	'lēkto',
	'lēkts',
	'lēktu',
	'lēlim',
	'lēlis',
	'lēļos',
	'lēļus',
	'lēmām',
	'lēmāt',
	'lēmis',
	'lēnai',
	'lēnam',
	'lēnas',
	'lēnām',
	'lēnās',
	'lēnie',
	'lēnis',
	'lēnos',
	'lēnot',
	'lēnus',
	'lēņos',
	'lēņot',
	'lēņus',
	'lēpes',
	'lēpim',
	'lēpis',
	'lēpja',
	'lēpji',
	'lēpju',
	'lēsām',
	'lēsāt',
	'lēsis',
	'lēsīs',
	'lēsta',
	'lēstā',
	'lēsti',
	'lēsto',
	'lēsts',
	'lēstu',
	'lēšam',
	'lēšat',
	'lēšot',
	'lētai',
	'lētum',
	'lhamo',
	'lhasa',
	'liāna',
	'liānā',
	'liānu',
	'libau',
	'libca',
	'liber',
	'libis',
	'libor',
	'libra',
	'licen',
	'licet',
	'licis',
	'lidis',
	'lidos',
	'lidot',
	'lieca',
	'liece',
	'liecē',
	'lieci',
	'liecu',
	'lieču',
	'liede',
	'liedē',
	'liedz',
	'liega',
	'liegā',
	'liegi',
	'liego',
	'liegs',
	'liegt',
	'liegu',
	'lieģi',
	'lieka',
	'liekā',
	'lieki',
	'lieko',
	'lieks',
	'liekš',
	'liekt',
	'lieku',
	'liela',
	'lielā',
	'lieli',
	'lielo',
	'liels',
	'lielu',
	'liena',
	'lienē',
	'liens',
	'lienu',
	'liepa',
	'liepā',
	'liepu',
	'liesa',
	'liesā',
	'liesē',
	'liesi',
	'lieso',
	'liess',
	'liest',
	'liesu',
	'lieši',
	'liešu',
	'lieta',
	'lietā',
	'liete',
	'lietē',
	'lieti',
	'lieto',
	'liets',
	'lietu',
	'lieva',
	'lieve',
	'lifta',
	'liftā',
	'lifti',
	'lifts',
	'liftu',
	'ligēt',
	'liģēs',
	'liģēt',
	'lijām',
	'lijāt',
	'lijis',
	'likam',
	'likām',
	'likās',
	'likāt',
	'likma',
	'likme',
	'likmē',
	'likmi',
	'likos',
	'likra',
	'liksi',
	'likšu',
	'likta',
	'liktā',
	'likti',
	'likto',
	'likts',
	'liktu',
	'lilje',
	'lillā',
	'lille',
	'limba',
	'limbā',
	'limbi',
	'limbo',
	'limbs',
	'limbu',
	'limes',
	'limfa',
	'limfā',
	'limfu',
	'lināt',
	'linca',
	'linča',
	'linčo',
	'linda',
	'linde',
	'lindi',
	'lindu',
	'linga',
	'lingā',
	'lingo',
	'lingu',
	'linka',
	'links',
	'linna',
	'linta',
	'lints',
	'linum',
	'liona',
	'lione',
	'lioni',
	'lipām',
	'lipāt',
	'lipin',
	'lipis',
	'lipno',
	'lipsa',
	'lipsi',
	'lipši',
	'lipšu',
	'lipta',
	'liptā',
	'lipti',
	'lipto',
	'lipts',
	'liptu',
	'liska',
	'liste',
	'liška',
	'lišķe',
	'lišķē',
	'litai',
	'litas',
	'litot',
	'litra',
	'litrā',
	'litri',
	'litrs',
	'litru',
	'litta',
	'liuki',
	'liuža',
	'livno',
	'livrs',
	'livzi',
	'lizda',
	'lizdā',
	'lizdo',
	'lizdu',
	'lizes',
	'lizjē',
	'lībim',
	'lībis',
	'lībja',
	'lībji',
	'lībju',
	'līcim',
	'līcis',
	'līčos',
	'līčot',
	'līčus',
	'līdām',
	'līdāt',
	'līdēs',
	'līdēt',
	'līdis',
	'līdīs',
	'līdsa',
	'līdza',
	'līdzā',
	'līdze',
	'līdzi',
	'līdzs',
	'līdzu',
	'līgas',
	'līgām',
	'līgāt',
	'līgns',
	'līgos',
	'līgot',
	'līgsi',
	'līgst',
	'līgšu',
	'līgta',
	'līgtā',
	'līgti',
	'līgto',
	'līgts',
	'līgtu',
	'līkai',
	'līkam',
	'līkas',
	'līkām',
	'līkās',
	'līkāt',
	'līkie',
	'līkme',
	'līkne',
	'līknē',
	'līkni',
	'līkņā',
	'līkņu',
	'līkos',
	'līksi',
	'līkst',
	'līkšu',
	'līkta',
	'līktā',
	'līkti',
	'līkto',
	'līkts',
	'līktu',
	'līkum',
	'līkus',
	'līķis',
	'līmēs',
	'līmēt',
	'līnim',
	'līnis',
	'līņās',
	'līņāt',
	'līņos',
	'līņus',
	'līpam',
	'līpat',
	'līpna',
	'līpot',
	'līsim',
	'līsit',
	'līsma',
	'līsta',
	'līstā',
	'līste',
	'līsti',
	'līsto',
	'līsts',
	'līstu',
	'līšot',
	'līšus',
	'lītai',
	'lītam',
	'lītas',
	'lītām',
	'lītās',
	'lītie',
	'lītis',
	'lītos',
	'lītus',
	'līves',
	'līvim',
	'līvis',
	'līvja',
	'līvji',
	'līvju',
	'ljana',
	'ljanā',
	'ljanu',
	'ljēža',
	'lkkss',
	'lldra',
	'lļkjs',
	'lnkba',
	'lnpva',
	'loads',
	'loano',
	'lobām',
	'lobās',
	'lobāt',
	'lobēs',
	'lobēt',
	'lobio',
	'lobis',
	'lobīs',
	'lobīt',
	'lobju',
	'lobņa',
	'lobos',
	'lobot',
	'lobsi',
	'lobšu',
	'lobta',
	'lobtā',
	'lobti',
	'lobto',
	'lobts',
	'lobtu',
	'lochs',
	'locim',
	'locis',
	'locīs',
	'locīt',
	'locka',
	'locus',
	'ločos',
	'ločus',
	'lodās',
	'lodāt',
	'lodes',
	'lodēs',
	'lodēt',
	'lodis',
	'lodza',
	'lodze',
	'lodža',
	'lofti',
	'logam',
	'loges',
	'logia',
	'logos',
	'logus',
	'loģis',
	'lohja',
	'loids',
	'loiko',
	'loimā',
	'lokai',
	'lokam',
	'lokas',
	'lokat',
	'lokām',
	'lokās',
	'lokāt',
	'lokie',
	'lokno',
	'lokos',
	'lokot',
	'lokri',
	'loksa',
	'lokum',
	'lokus',
	'lokva',
	'loķis',
	'lolos',
	'lolot',
	'lomka',
	'lompa',
	'lomza',
	'lomža',
	'lonam',
	'lonat',
	'lonēs',
	'lonēt',
	'longa',
	'lonot',
	'lonža',
	'lonžā',
	'lonžu',
	'lopsa',
	'loran',
	'lorda',
	'lordā',
	'lordi',
	'lords',
	'lordu',
	'loris',
	'lorka',
	'lorts',
	'losai',
	'losam',
	'losas',
	'losām',
	'losās',
	'losie',
	'losis',
	'loska',
	'losos',
	'losts',
	'losus',
	'lotis',
	'lotos',
	'lotto',
	'lotus',
	'louds',
	'louks',
	'loule',
	'louzi',
	'lozēs',
	'lozēt',
	'ložņa',
	'ložņā',
	'ložņu',
	'lsaka',
	'lsdsp',
	'lsszp',
	'luāra',
	'lubar',
	'lubas',
	'lubei',
	'lucim',
	'lucis',
	'lucka',
	'lučka',
	'lučos',
	'lučus',
	'ludes',
	'ludus',
	'ludza',
	'ludze',
	'ludzē',
	'ludzi',
	'ludžu',
	'luesa',
	'luesā',
	'luesi',
	'luess',
	'luesu',
	'luffa',
	'lugat',
	'lugus',
	'luģis',
	'luiga',
	'luija',
	'luika',
	'luino',
	'luiza',
	'luize',
	'lukas',
	'lukna',
	'lukne',
	'lukni',
	'luksa',
	'luksā',
	'luksi',
	'lukss',
	'luksu',
	'lukši',
	'lukta',
	'luktā',
	'luktu',
	'lulis',
	'lullo',
	'lullu',
	'lulos',
	'lulot',
	'luļļu',
	'lumii',
	'lumis',
	'lumme',
	'lumpa',
	'lumps',
	'lumza',
	'lunch',
	'lunda',
	'lundi',
	'lunga',
	'lungā',
	'lungi',
	'lungs',
	'lungu',
	'lunis',
	'lunka',
	'lunkā',
	'lunku',
	'lunte',
	'luņķi',
	'luoči',
	'lupas',
	'lupām',
	'lupāt',
	'lupis',
	'lupsi',
	'lupšu',
	'lupta',
	'luptā',
	'lupti',
	'lupto',
	'lupts',
	'luptu',
	'lupus',
	'lurda',
	'luska',
	'luste',
	'lustē',
	'lutēt',
	'lutra',
	'lūdza',
	'lūdzi',
	'lūdzu',
	'lūgsi',
	'lūgšu',
	'lūgta',
	'lūgtā',
	'lūgti',
	'lūgto',
	'lūgts',
	'lūgtu',
	'lūisa',
	'lūkos',
	'lūkot',
	'lūkša',
	'lūkšā',
	'lūkšu',
	'lūlos',
	'lūlot',
	'lūpam',
	'lūpat',
	'lūpot',
	'lūram',
	'lūrat',
	'lūrēs',
	'lūrēt',
	'lūrot',
	'lūsim',
	'lūsis',
	'lūsti',
	'lūstu',
	'lūšos',
	'lūšus',
	'lūvēt',
	'lūzām',
	'lūzāt',
	'lūzin',
	'lūzis',
	'lūzīs',
	'lūzta',
	'lūztā',
	'lūzti',
	'lūzto',
	'lūzts',
	'lūztu',
	'lūžņa',
	'lūžņā',
	'lūžņu',
	'lvavp',
	'lvfki',
	'lvlsa',
	'ļacka',
	'ļaksi',
	'ļaļka',
	'ļaohe',
	'ļaras',
	'ļauja',
	'ļauju',
	'ļauna',
	'ļaunā',
	'ļauni',
	'ļauno',
	'ļauns',
	'ļaunu',
	'ļausi',
	'ļaušu',
	'ļauta',
	'ļautā',
	'ļauti',
	'ļauto',
	'ļauts',
	'ļautu',
	'ļauži',
	'ļaužs',
	'ļāpāt',
	'ļāvām',
	'ļāvās',
	'ļāvāt',
	'ļāvis',
	'ļāvos',
	'ļecka',
	'ļečīt',
	'ļekaa',
	'ļekaā',
	'ļekai',
	'ļekas',
	'ļekau',
	'ļekās',
	'ļekāt',
	'ļekos',
	'ļekot',
	'ļenka',
	'ļepos',
	'ļepot',
	'ļeras',
	'ļerga',
	'ļergā',
	'ļergu',
	'ļerkš',
	'ļēpos',
	'ļēpot',
	'ļimām',
	'ļimāt',
	'ļimis',
	'ļimsi',
	'ļimst',
	'ļimšu',
	'ļimta',
	'ļimtā',
	'ļimti',
	'ļimto',
	'ļimts',
	'ļimtu',
	'ļinka',
	'ļinki',
	'ļinku',
	'ļipas',
	'ļipos',
	'ļipot',
	'ļiski',
	'ļivni',
	'ļodza',
	'ļodze',
	'ļodzi',
	'ļodzu',
	'ļorka',
	'ļucis',
	'ļukas',
	'ļukām',
	'ļukāt',
	'ļuksi',
	'ļukšu',
	'ļukta',
	'ļuktā',
	'ļukti',
	'ļukto',
	'ļukts',
	'ļuktu',
	'ļuļka',
	'ļuļķē',
	'ļumam',
	'ļumat',
	'ļumām',
	'ļumāt',
	'ļumēs',
	'ļumēt',
	'ļumis',
	'ļumot',
	'ļumsi',
	'ļumst',
	'ļumšu',
	'ļumta',
	'ļumtā',
	'ļumti',
	'ļumto',
	'ļumts',
	'ļumtu',
	'ļurba',
	'ļurbā',
	'ļurbu',
	'ļurkā',
	'ļurkš',
	'ļūkam',
	'ļūkat',
	'ļūkot',
	'ļvova',
	'maata',
	'maats',
	'macaķ',
	'macas',
	'macue',
	'mačka',
	'madja',
	'madla',
	'madre',
	'mafia',
	'magma',
	'magmā',
	'magmu',
	'magna',
	'magre',
	'magve',
	'maģis',
	'mahāt',
	'mahdī',
	'mahra',
	'maida',
	'maide',
	'maiga',
	'maigā',
	'maigi',
	'maigo',
	'maigs',
	'maigu',
	'maija',
	'maijā',
	'maiji',
	'maijs',
	'maiju',
	'maika',
	'maiko',
	'maile',
	'mails',
	'maina',
	'maini',
	'mains',
	'mainu',
	'maiņa',
	'maiņā',
	'maiņu',
	'maisa',
	'maisā',
	'maisi',
	'maiss',
	'maisu',
	'maita',
	'maitā',
	'maitu',
	'maize',
	'maizē',
	'maizi',
	'maižu',
	'maīsa',
	'maīsā',
	'maīsi',
	'maīss',
	'maīsu',
	'makao',
	'makja',
	'makni',
	'makņa',
	'maksa',
	'maksā',
	'maksi',
	'maksu',
	'makte',
	'makts',
	'maķis',
	'malām',
	'malās',
	'malāt',
	'malda',
	'maldā',
	'maldi',
	'malds',
	'maldu',
	'malis',
	'malka',
	'malkā',
	'malki',
	'malko',
	'malks',
	'malku',
	'malla',
	'malme',
	'malos',
	'malsi',
	'malšu',
	'malta',
	'maltā',
	'malti',
	'malto',
	'malts',
	'maltu',
	'malup',
	'malus',
	'malva',
	'malvā',
	'malve',
	'malvu',
	'maļam',
	'maļas',
	'maļat',
	'maļci',
	'maļos',
	'maļot',
	'mamba',
	'mambā',
	'mambo',
	'mambu',
	'mamma',
	'mammā',
	'mammu',
	'manām',
	'manās',
	'manāt',
	'manca',
	'mandu',
	'manes',
	'manga',
	'mangi',
	'mango',
	'mangu',
	'manīs',
	'manīt',
	'manju',
	'manka',
	'manko',
	'manna',
	'mannā',
	'manns',
	'mannu',
	'manos',
	'manot',
	'mansa',
	'mansā',
	'mansi',
	'manss',
	'mansu',
	'manta',
	'mantā',
	'manto',
	'mants',
	'mantu',
	'mantū',
	'manup',
	'manus',
	'maņka',
	'maoka',
	'maora',
	'maorā',
	'maori',
	'maors',
	'maoru',
	'mappa',
	'maras',
	'marco',
	'marcs',
	'marfa',
	'marga',
	'margā',
	'margo',
	'margs',
	'margu',
	'marha',
	'marka',
	'markā',
	'marke',
	'marki',
	'marko',
	'marks',
	'marku',
	'marķē',
	'marla',
	'marle',
	'marlē',
	'marli',
	'marļu',
	'marna',
	'marne',
	'marot',
	'marsa',
	'marsā',
	'marsi',
	'marss',
	'marsu',
	'marša',
	'maršā',
	'maršē',
	'marši',
	'maršs',
	'maršu',
	'marta',
	'martā',
	'marti',
	'marts',
	'martu',
	'marva',
	'marža',
	'maržā',
	'maržu',
	'masēs',
	'masēt',
	'maska',
	'maskā',
	'maskē',
	'masko',
	'masku',
	'masli',
	'masta',
	'mastā',
	'maste',
	'masti',
	'masto',
	'masts',
	'mastu',
	'mašna',
	'matča',
	'mater',
	'matēs',
	'matēt',
	'matīs',
	'matīt',
	'matka',
	'matos',
	'matot',
	'mauca',
	'mauci',
	'maucu',
	'mauči',
	'mauju',
	'mauka',
	'mauks',
	'maukt',
	'maula',
	'maule',
	'mauna',
	'maura',
	'maurā',
	'mauri',
	'mauro',
	'maurs',
	'mauru',
	'mausi',
	'maušu',
	'mauta',
	'mautā',
	'mauti',
	'mauto',
	'mauts',
	'mautu',
	'mazai',
	'mazam',
	'mazas',
	'mazām',
	'mazās',
	'mazda',
	'mazga',
	'mazgā',
	'mazie',
	'mazjs',
	'mazos',
	'mazum',
	'mazus',
	'mācam',
	'mācas',
	'mācat',
	'mācām',
	'mācās',
	'mācāt',
	'mācēs',
	'mācēt',
	'mācis',
	'mācīs',
	'mācīt',
	'mācos',
	'mācot',
	'mājam',
	'mājat',
	'mājām',
	'mājāt',
	'mājis',
	'mājos',
	'mājot',
	'mājup',
	'mākam',
	'mākat',
	'mākla',
	'mākot',
	'māksi',
	'mākšu',
	'mākta',
	'māktā',
	'mākti',
	'mākto',
	'mākts',
	'māktu',
	'mālēs',
	'mālēt',
	'mālis',
	'mānām',
	'mānās',
	'mānāt',
	'mānim',
	'mānis',
	'mānīs',
	'mānīt',
	'mānos',
	'mānot',
	'māņos',
	'māņus',
	'mārci',
	'mārdu',
	'mārga',
	'mārka',
	'mārkā',
	'mārki',
	'mārks',
	'mārku',
	'mārša',
	'māršā',
	'māršu',
	'mārte',
	'māsim',
	'māsit',
	'māsli',
	'māšot',
	'mātai',
	'mātam',
	'mātas',
	'mātām',
	'mātās',
	'mātie',
	'mātos',
	'mātra',
	'mātri',
	'mātus',
	'māvām',
	'māvāt',
	'māvis',
	'mbale',
	'mbuli',
	'media',
	'medio',
	'medīs',
	'medīt',
	'medne',
	'medni',
	'medņi',
	'medos',
	'medot',
	'medum',
	'medus',
	'medze',
	'megna',
	'megri',
	'mehdī',
	'meiča',
	'meičā',
	'meiču',
	'meija',
	'meijā',
	'meijo',
	'meiju',
	'meile',
	'meils',
	'meina',
	'meiri',
	'meita',
	'meitā',
	'meitu',
	'mejam',
	'mejat',
	'mejot',
	'meklē',
	'mekša',
	'meķis',
	'melco',
	'melde',
	'meldē',
	'meles',
	'melfi',
	'melga',
	'melim',
	'melis',
	'melka',
	'mella',
	'melle',
	'mells',
	'melna',
	'melnā',
	'melne',
	'melnē',
	'melni',
	'melno',
	'melns',
	'melnu',
	'melos',
	'melot',
	'melsa',
	'melsi',
	'melst',
	'melsu',
	'melša',
	'melšā',
	'melšu',
	'meļļa',
	'meļos',
	'meļus',
	'memel',
	'memme',
	'memmē',
	'memmi',
	'menca',
	'mencā',
	'mence',
	'mencu',
	'menda',
	'mendi',
	'menfi',
	'menga',
	'menge',
	'mensa',
	'mensu',
	'menšu',
	'menta',
	'mente',
	'mentē',
	'menti',
	'ments',
	'menza',
	'meņģe',
	'meņģi',
	'merci',
	'merga',
	'mergā',
	'mergu',
	'merka',
	'merke',
	'merķe',
	'merla',
	'merlo',
	'meroe',
	'mersa',
	'merta',
	'merva',
	'mesas',
	'meshi',
	'mesjē',
	'meska',
	'mesli',
	'mesls',
	'mesta',
	'mestā',
	'mesti',
	'mesto',
	'mests',
	'mestu',
	'meška',
	'mešus',
	'metal',
	'metam',
	'metas',
	'metat',
	'metām',
	'metās',
	'metāt',
	'metin',
	'metis',
	'metīs',
	'metla',
	'metos',
	'metot',
	'metra',
	'metrā',
	'metri',
	'metro',
	'metrs',
	'metru',
	'metus',
	'mezza',
	'mezzo',
	'mežģa',
	'mežģī',
	'mēdām',
	'mēdās',
	'mēdāt',
	'mēdīs',
	'mēdīt',
	'mēdos',
	'mēdot',
	'mēdza',
	'mēdzi',
	'mēdzu',
	'mēgsi',
	'mēgšu',
	'mēgta',
	'mēgtā',
	'mēgti',
	'mēgto',
	'mēgts',
	'mēgtu',
	'mēģis',
	'mējam',
	'mējat',
	'mējām',
	'mējāt',
	'mējis',
	'mējot',
	'mēkšķ',
	'mēķis',
	'mēles',
	'mēļai',
	'mēļam',
	'mēļas',
	'mēļām',
	'mēļās',
	'mēļie',
	'mēļos',
	'mēļot',
	'mēļus',
	'mēmai',
	'mēmam',
	'mēmas',
	'mēmām',
	'mēmās',
	'mēmie',
	'mēmos',
	'mēmus',
	'mērca',
	'mērce',
	'mērcē',
	'mērci',
	'mērcu',
	'mērču',
	'mērdē',
	'mērga',
	'mērim',
	'mēris',
	'mērīs',
	'mērīt',
	'mērka',
	'mērks',
	'mērkt',
	'mērķē',
	'mēros',
	'mērot',
	'mērus',
	'mēsim',
	'mēsit',
	'mēsla',
	'mēslā',
	'mēsli',
	'mēslo',
	'mēsls',
	'mēslu',
	'mēšot',
	'mētai',
	'mētam',
	'mētas',
	'mētām',
	'mētās',
	'mētāt',
	'mētie',
	'mētos',
	'mētra',
	'mētrā',
	'mētru',
	'mētus',
	'mēzām',
	'mēzāt',
	'mēzis',
	'mēzīs',
	'mēzta',
	'mēztā',
	'mēzti',
	'mēzto',
	'mēzts',
	'mēztu',
	'mēžam',
	'mēžat',
	'mēžot',
	'miasa',
	'miāze',
	'miāzē',
	'miāzi',
	'miāžu',
	'mices',
	'micis',
	'mičos',
	'mičot',
	'midži',
	'midžu',
	'miece',
	'miecē',
	'mieci',
	'mieču',
	'miedz',
	'miega',
	'miegā',
	'miegi',
	'miegs',
	'miegt',
	'miegu',
	'miela',
	'mieli',
	'mielo',
	'miels',
	'miena',
	'miens',
	'miera',
	'mierā',
	'mieri',
	'miers',
	'mieru',
	'miesa',
	'miesā',
	'miesi',
	'miest',
	'miesu',
	'miešu',
	'mieta',
	'mietā',
	'miete',
	'mietē',
	'mieti',
	'mieto',
	'miets',
	'mietu',
	'mieze',
	'miezt',
	'mieži',
	'migas',
	'migām',
	'migāt',
	'migla',
	'miglā',
	'miglo',
	'miglu',
	'migot',
	'migrē',
	'migsi',
	'migšu',
	'migta',
	'migtā',
	'migti',
	'migto',
	'migts',
	'migtu',
	'mihrs',
	'mijas',
	'mijās',
	'mijos',
	'mikaa',
	'mikaā',
	'mikai',
	'mikas',
	'mikau',
	'mikla',
	'miklā',
	'mikli',
	'miklo',
	'mikls',
	'miklu',
	'miksa',
	'miksē',
	'mikva',
	'miķis',
	'milai',
	'milda',
	'milka',
	'milla',
	'mille',
	'mills',
	'milna',
	'milnā',
	'milne',
	'milni',
	'milno',
	'milns',
	'milnu',
	'milsa',
	'milst',
	'milti',
	'milza',
	'milze',
	'milzē',
	'milzi',
	'milzt',
	'milzu',
	'milži',
	'milžu',
	'mimra',
	'mimus',
	'minam',
	'minas',
	'minat',
	'minām',
	'mināt',
	'minčo',
	'minčs',
	'mineo',
	'miner',
	'minēs',
	'minēt',
	'minis',
	'minja',
	'minka',
	'minna',
	'minos',
	'minot',
	'miņas',
	'mioma',
	'miomā',
	'miomu',
	'miona',
	'mionā',
	'mioni',
	'mions',
	'mionu',
	'miops',
	'miori',
	'mioze',
	'miozē',
	'miozi',
	'miožu',
	'mirām',
	'mirāt',
	'mirda',
	'mirdz',
	'mirga',
	'mirgā',
	'mirgi',
	'mirgo',
	'mirgu',
	'miris',
	'mirka',
	'mirki',
	'mirks',
	'mirkt',
	'mirku',
	'mirķi',
	'mirla',
	'mirlā',
	'mirlu',
	'mirma',
	'mirna',
	'mirra',
	'mirre',
	'mirrē',
	'mirri',
	'mirru',
	'mirsi',
	'mirst',
	'miršu',
	'mirta',
	'mirtā',
	'mirte',
	'mirtē',
	'mirti',
	'mirto',
	'mirts',
	'mirtu',
	'mirza',
	'misas',
	'misep',
	'misis',
	'miski',
	'misko',
	'missa',
	'misse',
	'mista',
	'mistā',
	'misti',
	'misto',
	'mists',
	'mistu',
	'miška',
	'miški',
	'mišmi',
	'mišna',
	'mitau',
	'mitām',
	'mitāt',
	'mites',
	'mitho',
	'mitis',
	'mitīs',
	'mitla',
	'mitra',
	'mitrā',
	'mitre',
	'mitri',
	'mitro',
	'mitrs',
	'mitru',
	'mitus',
	'mizas',
	'mizēt',
	'mizos',
	'mizot',
	'mīcām',
	'mīcās',
	'mīcāt',
	'mīcīs',
	'mīcīt',
	'mīcos',
	'mīcot',
	'mīdām',
	'mīdās',
	'mīdāt',
	'mīdīs',
	'mīdīt',
	'mīdos',
	'mīdot',
	'mīkla',
	'mīklā',
	'mīklu',
	'mīlam',
	'mīlat',
	'mīlēs',
	'mīlēt',
	'mīlis',
	'mīlīt',
	'mīlot',
	'mīļai',
	'mīļam',
	'mīļas',
	'mīļāk',
	'mīļām',
	'mīļās',
	'mīļie',
	'mīļos',
	'mīļot',
	'mīļus',
	'mīmas',
	'mīmis',
	'mīnēs',
	'mīnēt',
	'mīnus',
	'mīņās',
	'mīņāt',
	'mīsim',
	'mīsit',
	'mīsta',
	'mīsti',
	'mīstu',
	'mīšos',
	'mīšot',
	'mītai',
	'mītam',
	'mītas',
	'mītat',
	'mītām',
	'mītās',
	'mītie',
	'mītis',
	'mītne',
	'mītnē',
	'mītni',
	'mītns',
	'mītņu',
	'mītos',
	'mītot',
	'mītus',
	'mīzām',
	'mīzāt',
	'mīzis',
	'mīzīs',
	'mīzta',
	'mīztā',
	'mīzti',
	'mīzto',
	'mīzts',
	'mīztu',
	'mīžam',
	'mīžat',
	'mīžot',
	'mjaso',
	'mjeja',
	'mjēsa',
	'mlada',
	'mlava',
	'mnēme',
	'mnium',
	'mocām',
	'mocās',
	'mocāt',
	'mocis',
	'mocīs',
	'mocīt',
	'mocos',
	'mocot',
	'močīt',
	'modām',
	'modās',
	'modāt',
	'modes',
	'modis',
	'modīs',
	'modos',
	'modra',
	'modrā',
	'modri',
	'modro',
	'modrs',
	'modru',
	'modus',
	'moena',
	'moins',
	'moira',
	'moirā',
	'moiru',
	'moiva',
	'moivā',
	'moivu',
	'mojka',
	'mokaa',
	'mokaā',
	'mokai',
	'mokas',
	'mokau',
	'mokha',
	'mokka',
	'mokpo',
	'mokša',
	'mokši',
	'molča',
	'molde',
	'moles',
	'molla',
	'mollā',
	'molli',
	'molls',
	'mollu',
	'molti',
	'molto',
	'momas',
	'momme',
	'monas',
	'monca',
	'mongo',
	'monna',
	'monro',
	'monsa',
	'monte',
	'montē',
	'monts',
	'monza',
	'moņki',
	'mopti',
	'morda',
	'mordi',
	'mords',
	'mores',
	'morfa',
	'morfs',
	'morga',
	'morgā',
	'morgi',
	'morgs',
	'morgu',
	'moris',
	'morna',
	'morns',
	'morra',
	'morro',
	'morsa',
	'morsā',
	'morsi',
	'morss',
	'morsu',
	'morus',
	'morvi',
	'morze',
	'morzē',
	'morzi',
	'moržu',
	'mosim',
	'mosis',
	'mosli',
	'mossi',
	'mosso',
	'mosta',
	'mostā',
	'mosti',
	'mosto',
	'mosts',
	'mostu',
	'moška',
	'mošķi',
	'mošos',
	'mošus',
	'motru',
	'motto',
	'moura',
	'mousa',
	'movie',
	'mozēs',
	'mozēt',
	'mozgi',
	'mozus',
	'možai',
	'možam',
	'možas',
	'možām',
	'možās',
	'možie',
	'možos',
	'možus',
	'mrozi',
	'mšeno',
	'muara',
	'muarē',
	'mucis',
	'mucor',
	'mudas',
	'mudēt',
	'mudīt',
	'mudra',
	'mudri',
	'mudži',
	'mudžo',
	'mudžu',
	'mufta',
	'mufti',
	'mugla',
	'muita',
	'muitā',
	'muito',
	'muitu',
	'muiža',
	'muiži',
	'mukām',
	'mukāt',
	'mukla',
	'muklā',
	'mukli',
	'muklo',
	'mukls',
	'muklu',
	'muksi',
	'mukšu',
	'mukta',
	'muktā',
	'mukti',
	'mukto',
	'mukts',
	'muktu',
	'muķis',
	'mulča',
	'mulčā',
	'mulčē',
	'mulču',
	'mulda',
	'muldā',
	'mulde',
	'muldi',
	'muldu',
	'mulis',
	'mulka',
	'mulla',
	'mullā',
	'mullu',
	'mulsa',
	'mulsā',
	'mulsi',
	'mulso',
	'mulss',
	'mulst',
	'mulsu',
	'multa',
	'muļķa',
	'muļķā',
	'muļķe',
	'muļķi',
	'muļķo',
	'muļķs',
	'muļķu',
	'muļļa',
	'muļļā',
	'muļļu',
	'mumio',
	'mumjo',
	'mumps',
	'munči',
	'mundi',
	'mundo',
	'mundu',
	'munēt',
	'munga',
	'mungo',
	'munus',
	'murca',
	'murce',
	'murci',
	'murcu',
	'murda',
	'murdā',
	'murde',
	'murdi',
	'murds',
	'murdu',
	'murga',
	'murgā',
	'murgi',
	'murgo',
	'murgs',
	'murgu',
	'muris',
	'murkš',
	'murli',
	'murrā',
	'murza',
	'musca',
	'musēt',
	'musis',
	'musks',
	'musli',
	'musts',
	'mušīs',
	'mušīt',
	'mušķu',
	'mušļi',
	'mušot',
	'mutēs',
	'mutēt',
	'mutjē',
	'mūdām',
	'mūdāt',
	'mūdīs',
	'mūdīt',
	'mūdot',
	'mūkam',
	'mūkat',
	'mūkot',
	'mūķēs',
	'mūķēt',
	'mūķis',
	'mūlim',
	'mūlis',
	'mūļas',
	'mūļos',
	'mūļus',
	'mūrēs',
	'mūrēt',
	'mūrim',
	'mūris',
	'mūros',
	'mūrus',
	'mūsos',
	'mūsus',
	'mūzai',
	'mūzas',
	'mūzām',
	'mūzās',
	'mūžam',
	'mvali',
	'mveru',
	'nabas',
	'nabis',
	'nadas',
	'nadph',
	'nadži',
	'nafig',
	'nafta',
	'naftā',
	'naftu',
	'nagas',
	'nagla',
	'naglā',
	'nagli',
	'naglo',
	'naglu',
	'nagļi',
	'nagos',
	'naģis',
	'nahui',
	'nahuj',
	'naida',
	'naidā',
	'naidi',
	'naido',
	'naids',
	'naidu',
	'naiga',
	'naigā',
	'naigi',
	'naigo',
	'naigs',
	'naigu',
	'naika',
	'naiks',
	'naila',
	'naini',
	'naira',
	'naiva',
	'naivā',
	'naivi',
	'naivo',
	'naivs',
	'naivu',
	'naiza',
	'naīna',
	'najas',
	'nakfa',
	'naklo',
	'nakra',
	'nakrā',
	'nakru',
	'naksa',
	'nakšu',
	'nakti',
	'naktī',
	'nakts',
	'nalsa',
	'namco',
	'nampa',
	'nampo',
	'namsa',
	'nandi',
	'nandu',
	'nanga',
	'nanna',
	'nansī',
	'nante',
	'naoss',
	'naras',
	'nardi',
	'nardo',
	'naric',
	'narni',
	'narti',
	'narts',
	'narva',
	'narvi',
	'naska',
	'naskā',
	'naski',
	'nasko',
	'nasks',
	'nasku',
	'nasta',
	'nastā',
	'nastu',
	'nasua',
	'nasus',
	'naški',
	'našķe',
	'našķē',
	'našķi',
	'našķu',
	'našua',
	'natte',
	'nauču',
	'nauda',
	'naudā',
	'naudu',
	'nauja',
	'nauji',
	'nauka',
	'nauru',
	'nausa',
	'nazad',
	'nazat',
	'nazim',
	'nazis',
	'nažos',
	'nažot',
	'nažus',
	'nācām',
	'nācās',
	'nācāt',
	'nācis',
	'nācos',
	'nāgas',
	'nākam',
	'nākas',
	'nākat',
	'nākos',
	'nākot',
	'nāksi',
	'nākšu',
	'nākta',
	'nāktā',
	'nākti',
	'nākto',
	'nākts',
	'nāktu',
	'nāras',
	'nāsāt',
	'nātas',
	'nātna',
	'nātnā',
	'nātni',
	'nātno',
	'nātns',
	'nātnu',
	'nātra',
	'nātrā',
	'nātre',
	'nātrē',
	'nātri',
	'nātru',
	'nāves',
	'nāvēs',
	'nāvēt',
	'nāzis',
	'ndola',
	'neaco',
	'neada',
	'neadi',
	'neadu',
	'neasa',
	'neasā',
	'neasē',
	'neasi',
	'neaso',
	'neass',
	'neasu',
	'neaud',
	'neaug',
	'neauj',
	'neaus',
	'neaut',
	'neauž',
	'neāķē',
	'neāva',
	'neāvi',
	'neāvu',
	'neāzē',
	'nebar',
	'nebāz',
	'nebāž',
	'nebed',
	'neber',
	'nebēg',
	'neboz',
	'nebož',
	'nebra',
	'nebur',
	'nebūs',
	'nebūt',
	'necel',
	'neceļ',
	'necep',
	'necer',
	'necik',
	'necke',
	'nečab',
	'nečuč',
	'nečum',
	'nedej',
	'neder',
	'nedēd',
	'nedēj',
	'nedēs',
	'nedēt',
	'nedim',
	'nedip',
	'nedīc',
	'nedob',
	'nedod',
	'nedos',
	'nedot',
	'nedun',
	'nedur',
	'nedus',
	'nedūc',
	'neecē',
	'neeju',
	'neels',
	'neelš',
	'neesi',
	'neēda',
	'neēdi',
	'neēdu',
	'neēno',
	'neēst',
	'nefas',
	'negan',
	'negāz',
	'negāž',
	'negla',
	'negul',
	'negus',
	'negūs',
	'negūt',
	'neies',
	'neiet',
	'neiez',
	'neiež',
	'neijī',
	'neira',
	'neiri',
	'neirs',
	'neirt',
	'neiru',
	'neisa',
	'neise',
	'neita',
	'neiva',
	'neīdi',
	'neīdu',
	'neīga',
	'neīgi',
	'neīgs',
	'neīgt',
	'neīgu',
	'neīrē',
	'nejāj',
	'nejās',
	'nejāt',
	'nejoz',
	'nejož',
	'nejum',
	'nejūc',
	'nejūk',
	'nejūt',
	'nekad',
	'nekal',
	'nekaļ',
	'nekam',
	'nekar',
	'nekas',
	'nekaš',
	'nekāp',
	'nekās',
	'nekāš',
	'nekla',
	'nekod',
	'nekon',
	'nekop',
	'nekož',
	'nekul',
	'nekuļ',
	'nekur',
	'nekut',
	'nekūp',
	'neķep',
	'neķer',
	'nelec',
	'nelej',
	'nelem',
	'nelēs',
	'nelēš',
	'nelga',
	'nelgā',
	'nelgu',
	'nelīp',
	'nelīs',
	'nelīt',
	'nelma',
	'nelob',
	'neloc',
	'nelok',
	'nelon',
	'nelūp',
	'nelūr',
	'neļķe',
	'neļķu',
	'neļum',
	'neļūc',
	'neļūk',
	'nemal',
	'nemaļ',
	'nemaz',
	'nemāc',
	'nemāj',
	'nemāk',
	'nemās',
	'nemāt',
	'nemej',
	'nemet',
	'nemēj',
	'nemēr',
	'nemēs',
	'nemēt',
	'nemēz',
	'nemēž',
	'nemin',
	'nemīl',
	'nemīs',
	'nemīt',
	'nemīz',
	'nemīž',
	'nemon',
	'nemti',
	'nemūc',
	'nemūk',
	'nenāc',
	'nenāk',
	'nenes',
	'neņem',
	'neoda',
	'neodi',
	'neodu',
	'neogo',
	'neona',
	'neonā',
	'neoni',
	'neons',
	'neonu',
	'neost',
	'neotē',
	'neožu',
	'nepel',
	'nepeļ',
	'neper',
	'nepil',
	'nepin',
	'nepis',
	'nepiš',
	'nepīs',
	'nepīt',
	'nepos',
	'nepoš',
	'neput',
	'nepūs',
	'nepūš',
	'nepūt',
	'nerāj',
	'nerās',
	'nerāt',
	'nerca',
	'nercā',
	'nerci',
	'nercs',
	'nercu',
	'nerej',
	'nerēc',
	'nerft',
	'nerga',
	'nerha',
	'nerij',
	'neris',
	'nerit',
	'nerīb',
	'nerīs',
	'nerīt',
	'neroc',
	'nerod',
	'nerok',
	'nerpa',
	'nerra',
	'nerrā',
	'nerri',
	'nerro',
	'nerrs',
	'nerru',
	'nerta',
	'nerūc',
	'nerūk',
	'nerva',
	'nervā',
	'nervi',
	'nervs',
	'nervu',
	'nerza',
	'nesam',
	'nesas',
	'nesat',
	'nesāc',
	'nesāk',
	'nesām',
	'nesāp',
	'nesās',
	'nesāt',
	'nesen',
	'neser',
	'nesēc',
	'nesēd',
	'nesēj',
	'nesēs',
	'nesēt',
	'nesis',
	'nesit',
	'nesīs',
	'nesos',
	'nesot',
	'nesta',
	'nestā',
	'nesti',
	'nesto',
	'nests',
	'nestu',
	'nesūc',
	'nesūt',
	'nešuj',
	'nešus',
	'nešūs',
	'nešūt',
	'netāl',
	'netek',
	'netes',
	'neteš',
	'netēs',
	'netēš',
	'netic',
	'netin',
	'netīs',
	'netīt',
	'netop',
	'netos',
	'netta',
	'netto',
	'netup',
	'netur',
	'netuš',
	'neuma',
	'neurb',
	'neurd',
	'neuri',
	'neuto',
	'neūbo',
	'neūkš',
	'nevar',
	'nevāc',
	'nevāz',
	'nevāž',
	'neved',
	'nevel',
	'neveļ',
	'nevem',
	'never',
	'nevēz',
	'nevēž',
	'nevij',
	'nevil',
	'neviļ',
	'nevir',
	'nevis',
	'nevīd',
	'nevīs',
	'nevīt',
	'nezel',
	'nezeļ',
	'nezib',
	'nezin',
	'nezīd',
	'nezīž',
	'nezog',
	'nezum',
	'nezuz',
	'nezūd',
	'nežūs',
	'nežūt',
	'nēģis',
	'nērsa',
	'nērsi',
	'nērst',
	'nērsu',
	'nēršu',
	'nēsas',
	'nēsās',
	'nēsāt',
	'nēsim',
	'nēsis',
	'nēšos',
	'nēšus',
	'ngadi',
	'ngolo',
	'ngozi',
	'nhlbi',
	'nicin',
	'niddm',
	'niece',
	'nieka',
	'niekā',
	'nieki',
	'nieko',
	'nieks',
	'nieku',
	'niere',
	'nierē',
	'nieri',
	'nieru',
	'niess',
	'nieši',
	'nieva',
	'nievā',
	'nievu',
	'nieza',
	'nieze',
	'niezē',
	'niezi',
	'niezu',
	'niežu',
	'nigde',
	'nigra',
	'nihil',
	'nikas',
	'nikna',
	'niknā',
	'nikni',
	'nikno',
	'nikns',
	'niknu',
	'niksa',
	'nikte',
	'niķis',
	'nimaz',
	'nimba',
	'nimbā',
	'nimbi',
	'nimbs',
	'nimbu',
	'nimfa',
	'nimfā',
	'nimfu',
	'ninbo',
	'ninna',
	'ninne',
	'niobe',
	'niona',
	'niora',
	'nipis',
	'nirām',
	'nirās',
	'nirāt',
	'nirga',
	'niris',
	'nirka',
	'niros',
	'nirsi',
	'nirst',
	'niršu',
	'nirta',
	'nirtā',
	'nirti',
	'nirto',
	'nirts',
	'nirtu',
	'nirza',
	'niška',
	'nitra',
	'nitrē',
	'nitva',
	'niule',
	'nivhi',
	'nizva',
	'nībru',
	'nīcām',
	'nīcis',
	'nīdām',
	'nīdās',
	'nīdāt',
	'nīdēs',
	'nīdēt',
	'nīdis',
	'nīdīs',
	'nīdos',
	'nīgra',
	'nīgrā',
	'nīgri',
	'nīgro',
	'nīgrs',
	'nīgru',
	'nīkām',
	'nīkāt',
	'nīksi',
	'nīkst',
	'nīkšu',
	'nīkta',
	'nīktā',
	'nīkti',
	'nīkto',
	'nīkts',
	'nīktu',
	'nīski',
	'nīsta',
	'nīstā',
	'nīsti',
	'nīsto',
	'nīsts',
	'nīstu',
	'nītes',
	'nītēs',
	'nītēt',
	'nītis',
	'nītīs',
	'nītīt',
	'njama',
	'njasa',
	'njāja',
	'nnrti',
	'noada',
	'noadi',
	'noadu',
	'noale',
	'noara',
	'noarā',
	'noari',
	'noars',
	'noart',
	'noaru',
	'noass',
	'noaud',
	'noaug',
	'noauj',
	'noaus',
	'noaut',
	'noauž',
	'noāķē',
	'noāva',
	'noāvi',
	'noāvu',
	'nobar',
	'nobāz',
	'nobāž',
	'nober',
	'nobēg',
	'nobla',
	'noblā',
	'nobli',
	'noblo',
	'nobls',
	'noblu',
	'nobur',
	'nobūt',
	'nocel',
	'noceļ',
	'nocep',
	'nočab',
	'nodaļ',
	'nodeg',
	'nodej',
	'noder',
	'nodēd',
	'nodēt',
	'nodim',
	'nodip',
	'nodīc',
	'nodod',
	'nodos',
	'nodot',
	'nodun',
	'nodur',
	'nodus',
	'nodūc',
	'noecē',
	'noeja',
	'noejā',
	'noeju',
	'noele',
	'noels',
	'noelš',
	'noēda',
	'noēdi',
	'noēdu',
	'noēma',
	'noēno',
	'noēst',
	'noēze',
	'nogāz',
	'nogāž',
	'nogul',
	'noies',
	'noiet',
	'noira',
	'noiri',
	'noirs',
	'noirt',
	'noiru',
	'noīdi',
	'noīdu',
	'noīrē',
	'nojas',
	'nojāj',
	'nojās',
	'nojāt',
	'nojis',
	'nojoz',
	'nojož',
	'nojum',
	'nojus',
	'nojūc',
	'nojūk',
	'nokal',
	'nokaļ',
	'nokar',
	'nokāp',
	'nokās',
	'nokāš',
	'nokia',
	'nokod',
	'nokop',
	'nokož',
	'nokse',
	'nokul',
	'nokuļ',
	'nokut',
	'nokūp',
	'noķep',
	'noķer',
	'nolād',
	'nolec',
	'nolej',
	'nolem',
	'nolīp',
	'nolīs',
	'nolīt',
	'nolob',
	'noloc',
	'nolok',
	'nolūp',
	'nolūr',
	'noļūc',
	'noļūk',
	'noļūt',
	'nomal',
	'nomaļ',
	'nomāc',
	'nomās',
	'nomāt',
	'nomen',
	'nomet',
	'nomēz',
	'nomēž',
	'nomin',
	'nomīs',
	'nomīt',
	'nomīz',
	'nomīž',
	'nommo',
	'nomos',
	'nomūc',
	'nomūk',
	'nonas',
	'nonāc',
	'nonāk',
	'nones',
	'nonna',
	'nonne',
	'nonni',
	'noņem',
	'noost',
	'nopel',
	'nopeļ',
	'noper',
	'nopil',
	'nopin',
	'nopīs',
	'nopīt',
	'noput',
	'nopūs',
	'nopūš',
	'nopūt',
	'noras',
	'norāj',
	'norās',
	'norāt',
	'norba',
	'norča',
	'norej',
	'norēc',
	'norge',
	'norij',
	'noris',
	'norit',
	'norīb',
	'norīs',
	'norīt',
	'norma',
	'normā',
	'normē',
	'normu',
	'noroc',
	'norok',
	'norsa',
	'norūc',
	'nosan',
	'nosāp',
	'nosēc',
	'nosēd',
	'nosēj',
	'nosēs',
	'nosēt',
	'nosis',
	'nosit',
	'nosīc',
	'noska',
	'nosta',
	'nostu',
	'nosūc',
	'nosūt',
	'nošpa',
	'nošuj',
	'nošūs',
	'nošūt',
	'notaļ',
	'notāl',
	'notāļ',
	'notek',
	'notēs',
	'notēš',
	'notic',
	'notin',
	'notio',
	'notis',
	'notīs',
	'notīt',
	'notop',
	'notra',
	'notup',
	'notur',
	'noval',
	'novāc',
	'noved',
	'novel',
	'noveļ',
	'novem',
	'nover',
	'novēz',
	'novēž',
	'novij',
	'novil',
	'noviļ',
	'noviz',
	'novīd',
	'novīs',
	'novīt',
	'nozel',
	'nozeļ',
	'nozib',
	'nozis',
	'nozīd',
	'nozīž',
	'nozog',
	'nozuz',
	'nozūd',
	'nozze',
	'nožūs',
	'nožūt',
	'npies',
	'nrtvp',
	'nsaid',
	'nsdap',
	'ntais',
	'nudie',
	'nueva',
	'nuevo',
	'nuķis',
	'nulai',
	'nulle',
	'nullē',
	'nulli',
	'nulļu',
	'numea',
	'numen',
	'numur',
	'nungi',
	'nuoro',
	'nuovo',
	'nupat',
	'nurmi',
	'nurms',
	'nurse',
	'nursē',
	'nursi',
	'nuršu',
	'nūģis',
	'nūjos',
	'nūjot',
	'ņakāt',
	'ņamma',
	'ņammā',
	'ņammu',
	'ņaņka',
	'ņauda',
	'ņaudā',
	'ņaudi',
	'ņaudu',
	'ņauka',
	'ņecka',
	'ņefig',
	'ņemam',
	'ņemas',
	'ņemat',
	'ņemča',
	'ņemos',
	'ņemot',
	'ņemsi',
	'ņemšu',
	'ņemta',
	'ņemtā',
	'ņemti',
	'ņemto',
	'ņemts',
	'ņemtu',
	'ņenca',
	'ņencā',
	'ņenci',
	'ņencs',
	'ņencu',
	'ņerga',
	'ņergā',
	'ņergu',
	'ņerkš',
	'ņēmām',
	'ņēmās',
	'ņēmāt',
	'ņēmis',
	'ņēmos',
	'ņipra',
	'ņiprā',
	'ņipri',
	'ņipro',
	'ņiprs',
	'ņipru',
	'ņirba',
	'ņirbā',
	'ņirbi',
	'ņirbs',
	'ņirbt',
	'ņirbu',
	'ņirdz',
	'ņirga',
	'ņirgā',
	'ņirgs',
	'ņirgt',
	'ņirgu',
	'ņirkš',
	'ņisko',
	'ņjāsa',
	'ņjoki',
	'ņudzi',
	'ņudzu',
	'ņukāt',
	'ņukši',
	'ņuņņa',
	'ņuņņā',
	'ņuņņi',
	'ņuņņu',
	'ņurba',
	'ņurca',
	'ņurci',
	'ņurcu',
	'ņurdi',
	'ņurdu',
	'ņurga',
	'ņurgā',
	'ņurgu',
	'ņurīs',
	'ņurīt',
	'ņurka',
	'ņurkš',
	'ņurrā',
	'oandu',
	'oapec',
	'oāzim',
	'oāzis',
	'oāžos',
	'oāžus',
	'obada',
	'obans',
	'obasi',
	'obeče',
	'obeds',
	'obeja',
	'obeļi',
	'obers',
	'obhss',
	'oboja',
	'obojā',
	'oboju',
	'obols',
	'obuda',
	'obuļi',
	'octaa',
	'oculi',
	'očkas',
	'odens',
	'odera',
	'odere',
	'oderē',
	'oderi',
	'oderu',
	'odesa',
	'odērs',
	'odien',
	'odiet',
	'odins',
	'odiņš',
	'odium',
	'odīsi',
	'odīšu',
	'odīte',
	'odīts',
	'odors',
	'odsen',
	'odurs',
	'odusi',
	'odusī',
	'oduša',
	'odušā',
	'oduši',
	'odušo',
	'odušu',
	'ofiņš',
	'ofiss',
	'ofits',
	'ofīra',
	'ogāja',
	'ogājā',
	'ogāji',
	'ogājs',
	'ogāju',
	'ogive',
	'oglei',
	'oglis',
	'ogoja',
	'ogoji',
	'ogoju',
	'ogoki',
	'ogosi',
	'ogošu',
	'ogota',
	'ogotā',
	'ogoti',
	'ogoto',
	'ogots',
	'ogotu',
	'ohaio',
	'ohoss',
	'ohota',
	'ohrže',
	'ojars',
	'ojats',
	'okapi',
	'okene',
	'okera',
	'okerā',
	'okeri',
	'okers',
	'okeru',
	'okija',
	'oksle',
	'oktli',
	'okulē',
	'okuņs',
	'okupē',
	'olalā',
	'olava',
	'olāja',
	'olājā',
	'olāji',
	'olājs',
	'olāju',
	'olāts',
	'oleri',
	'olesa',
	'oleum',
	'olina',
	'oliņa',
	'oliņā',
	'oliņi',
	'oliņu',
	'oliva',
	'olīva',
	'olīvā',
	'olīve',
	'olīvē',
	'olīvi',
	'olīvu',
	'olovi',
	'olūts',
	'oļāja',
	'oļājā',
	'oļāji',
	'oļājs',
	'oļāju',
	'oļegs',
	'oļupe',
	'omaha',
	'omars',
	'omāna',
	'omāra',
	'omārā',
	'omāri',
	'omārs',
	'omāru',
	'omega',
	'omegā',
	'omegu',
	'omena',
	'omens',
	'omiša',
	'omīte',
	'omnis',
	'omola',
	'omons',
	'omska',
	'omuļi',
	'omuta',
	'onāns',
	'onija',
	'oniks',
	'onīmi',
	'onīms',
	'onkab',
	'oņega',
	'opaka',
	'opals',
	'opapa',
	'opapā',
	'opapi',
	'opaps',
	'opapu',
	'opava',
	'opāla',
	'opālā',
	'opāli',
	'opāls',
	'opālu',
	'opera',
	'operā',
	'operē',
	'operu',
	'opija',
	'opijā',
	'opiji',
	'opijs',
	'opiju',
	'opodo',
	'opoka',
	'opole',
	'opoļi',
	'oponē',
	'opora',
	'optēs',
	'optēt',
	'optom',
	'opusa',
	'opusā',
	'opusi',
	'opuss',
	'opusu',
	'opūgi',
	'orala',
	'orars',
	'orava',
	'orāla',
	'orālā',
	'orāli',
	'orālo',
	'orāls',
	'orālu',
	'orāna',
	'orbis',
	'ordre',
	'orele',
	'orels',
	'orema',
	'origo',
	'orija',
	'oriki',
	'orisa',
	'oriša',
	'orīši',
	'orkus',
	'ornis',
	'orona',
	'orono',
	'orora',
	'orpēs',
	'orpēt',
	'orska',
	'orupa',
	'oruro',
	'osaka',
	'osāja',
	'osājā',
	'osāji',
	'osājs',
	'osāju',
	'oseka',
	'osēra',
	'osiņa',
	'osiņš',
	'osīši',
	'osīts',
	'osmia',
	'osoba',
	'osova',
	'ossia',
	'ostai',
	'ostam',
	'ostas',
	'ostām',
	'ostās',
	'ostāt',
	'ostie',
	'ostīs',
	'ostīt',
	'ostos',
	'ostot',
	'ostra',
	'ostus',
	'osumi',
	'ošaca',
	'ošana',
	'ošanā',
	'ošanu',
	'ošava',
	'ošāni',
	'ošiņš',
	'ošķis',
	'ošņās',
	'ošņāt',
	'ošuna',
	'ošupe',
	'otars',
	'otaru',
	'otava',
	'otepe',
	'otepē',
	'oters',
	'otēja',
	'otēji',
	'otēju',
	'otēsi',
	'otēšu',
	'otēta',
	'otētā',
	'otēti',
	'otēto',
	'otēts',
	'otētu',
	'otiņa',
	'otīta',
	'otītā',
	'otīti',
	'otīts',
	'otītu',
	'otomi',
	'otori',
	'otoze',
	'otram',
	'otrup',
	'ovada',
	'ovaso',
	'ovāla',
	'ovālā',
	'ovāli',
	'ovālo',
	'ovāls',
	'ovālu',
	'oviši',
	'ovīši',
	'ozena',
	'ozenā',
	'ozenu',
	'ozero',
	'ozēna',
	'ozēnā',
	'ozēnu',
	'ozflb',
	'ozola',
	'ozolā',
	'ozoli',
	'ozols',
	'ozolu',
	'ozona',
	'ozonā',
	'ozonē',
	'ozoni',
	'ozons',
	'ozonu',
	'ožama',
	'ožamā',
	'ožami',
	'ožamo',
	'ožams',
	'ožamu',
	'ožiša',
	'ožoša',
	'ožošā',
	'ožoši',
	'ožošo',
	'ožošs',
	'ožošu',
	'paada',
	'paade',
	'paadi',
	'paadu',
	'paara',
	'paari',
	'paars',
	'paart',
	'paaru',
	'paasa',
	'paasā',
	'paasi',
	'paaso',
	'paass',
	'paasu',
	'paaud',
	'paaug',
	'paauj',
	'paaus',
	'paaut',
	'paauž',
	'paāva',
	'paāvi',
	'paāvu',
	'paāzē',
	'pabar',
	'pabāz',
	'pabāž',
	'paber',
	'pabēg',
	'paboz',
	'pabož',
	'pabur',
	'pabūs',
	'pabūt',
	'pacel',
	'paceļ',
	'pacem',
	'pacep',
	'packa',
	'pacta',
	'pačab',
	'pačib',
	'pačka',
	'pačuč',
	'padam',
	'padda',
	'padeg',
	'padej',
	'padēd',
	'padēj',
	'padēs',
	'padēt',
	'padis',
	'padīc',
	'padob',
	'padod',
	'pados',
	'padot',
	'padun',
	'padur',
	'padus',
	'padūc',
	'padža',
	'padžu',
	'paecē',
	'paeju',
	'paels',
	'paelš',
	'paesi',
	'paēda',
	'paēdi',
	'paēdu',
	'paēna',
	'paēnā',
	'paēne',
	'paēnu',
	'paēst',
	'pagai',
	'pagāz',
	'pagāž',
	'pagul',
	'pagūs',
	'pagūt',
	'paiču',
	'paide',
	'paies',
	'paiet',
	'paija',
	'paijā',
	'paiju',
	'paika',
	'paiki',
	'paiks',
	'paira',
	'pairi',
	'pairs',
	'pairt',
	'pairu',
	'paisa',
	'paise',
	'paisi',
	'paiss',
	'paist',
	'paisu',
	'paīdi',
	'paīdu',
	'paīga',
	'paīgi',
	'paīgs',
	'paīgt',
	'paīgu',
	'paīsa',
	'paīsā',
	'paīsi',
	'paīso',
	'paīss',
	'paīsu',
	'pajāj',
	'pajās',
	'pajāt',
	'pajoz',
	'pajož',
	'pajūc',
	'pajūk',
	'pajūt',
	'pakal',
	'pakaļ',
	'pakar',
	'pakas',
	'pakāp',
	'pakās',
	'pakāš',
	'pakni',
	'pakņi',
	'pakod',
	'pakop',
	'pakos',
	'pakot',
	'pakož',
	'paksa',
	'pakse',
	'pakša',
	'pakši',
	'pakšķ',
	'pakšu',
	'pakti',
	'paktī',
	'pakts',
	'pakul',
	'pakuļ',
	'pakur',
	'pakut',
	'pakūp',
	'paķer',
	'paķis',
	'palas',
	'palau',
	'palce',
	'palec',
	'palej',
	'palep',
	'palēs',
	'palēš',
	'palis',
	'palīs',
	'palīt',
	'palka',
	'palls',
	'palma',
	'palmā',
	'palmi',
	'palmo',
	'palmu',
	'palob',
	'paloc',
	'palok',
	'palos',
	'palot',
	'palpē',
	'palsa',
	'palsā',
	'palsi',
	'palso',
	'palss',
	'palsu',
	'palši',
	'palšs',
	'palšu',
	'palte',
	'palti',
	'paltī',
	'palts',
	'palūk',
	'palūr',
	'palva',
	'paļaa',
	'paļaā',
	'paļai',
	'paļas',
	'paļau',
	'paļās',
	'paļāt',
	'pamal',
	'pamaļ',
	'pamaz',
	'pamāj',
	'pamās',
	'pamāt',
	'pamet',
	'pamēz',
	'pamēž',
	'pamin',
	'pamīl',
	'pamīs',
	'pamīt',
	'pamīz',
	'pamīž',
	'pampa',
	'pampā',
	'pampi',
	'pamps',
	'pampt',
	'pampu',
	'pamūc',
	'pamūk',
	'panas',
	'panāc',
	'panāk',
	'panča',
	'pančs',
	'panda',
	'panes',
	'panēs',
	'panēt',
	'panga',
	'pangā',
	'pangu',
	'panjē',
	'panka',
	'pankā',
	'panki',
	'panks',
	'panku',
	'panna',
	'pannā',
	'panno',
	'pannu',
	'panta',
	'pantā',
	'panti',
	'pants',
	'pantu',
	'panus',
	'paņas',
	'paņem',
	'paņgu',
	'paoda',
	'paodi',
	'paodu',
	'paogo',
	'paola',
	'paolo',
	'paost',
	'paotē',
	'paožu',
	'papel',
	'papeļ',
	'paper',
	'papil',
	'papio',
	'papis',
	'papiš',
	'papos',
	'papua',
	'papum',
	'papus',
	'paput',
	'papūs',
	'papūš',
	'papūt',
	'parau',
	'parāj',
	'parās',
	'parāt',
	'parčs',
	'parej',
	'pareo',
	'parfē',
	'parga',
	'parij',
	'paris',
	'parit',
	'parīb',
	'parīs',
	'parīt',
	'parka',
	'parkā',
	'parki',
	'parks',
	'parkš',
	'parku',
	'parla',
	'parma',
	'parms',
	'paroc',
	'parod',
	'parok',
	'parsa',
	'parsā',
	'parsi',
	'parss',
	'parsu',
	'parta',
	'parte',
	'parti',
	'parts',
	'parus',
	'parūc',
	'parva',
	'pasar',
	'pasāc',
	'pasāk',
	'pasāp',
	'pasen',
	'pasēc',
	'pasēd',
	'pasēj',
	'pasēs',
	'pasēt',
	'pasha',
	'pashā',
	'pashu',
	'pasit',
	'pasīc',
	'pasīt',
	'paska',
	'pasms',
	'passa',
	'pasta',
	'pastā',
	'paste',
	'pasti',
	'pasto',
	'pasts',
	'pastu',
	'pasūc',
	'pasūt',
	'paška',
	'pašļi',
	'pašuj',
	'pašūs',
	'pašūt',
	'patas',
	'patek',
	'pater',
	'pates',
	'patēs',
	'patēš',
	'patin',
	'patio',
	'patīc',
	'patīk',
	'patīs',
	'patīt',
	'patla',
	'patma',
	'patna',
	'patop',
	'patra',
	'patup',
	'patur',
	'pauča',
	'pauda',
	'paudi',
	'paudu',
	'pauga',
	'pauka',
	'pauks',
	'paukš',
	'paulu',
	'pauna',
	'paunā',
	'pauno',
	'paunu',
	'paupi',
	'paurb',
	'paure',
	'paurē',
	'pauri',
	'paurs',
	'pauru',
	'pausa',
	'pausā',
	'pausē',
	'pauso',
	'paust',
	'pausu',
	'pauta',
	'pauti',
	'pautī',
	'pauts',
	'pautu',
	'pauze',
	'pauzē',
	'pauzi',
	'pauži',
	'paužu',
	'pavāc',
	'paved',
	'pavei',
	'pavel',
	'paveļ',
	'paver',
	'pavēl',
	'pavēz',
	'pavēž',
	'pavij',
	'paviz',
	'pavīd',
	'pavīs',
	'pavīt',
	'pazib',
	'pazīd',
	'pazīs',
	'pazīt',
	'pazīž',
	'pazog',
	'pazuz',
	'pazūd',
	'pažēl',
	'pažūs',
	'pažūt',
	'pāķis',
	'pālai',
	'pālam',
	'pālas',
	'pālām',
	'pālās',
	'pālie',
	'pālim',
	'pālis',
	'pālos',
	'pālus',
	'pāļos',
	'pāļus',
	'pāndu',
	'pārar',
	'pārāk',
	'pārba',
	'pārej',
	'pāres',
	'pārēm',
	'pārim',
	'pāris',
	'pārla',
	'pāros',
	'pārot',
	'pārts',
	'pārus',
	'pāvis',
	'pctvl',
	'pecus',
	'pečka',
	'pedra',
	'peers',
	'pefki',
	'peica',
	'peilē',
	'peine',
	'peins',
	'pejas',
	'pekas',
	'pekās',
	'pekāt',
	'pekla',
	'pekle',
	'peklē',
	'pekli',
	'pekļu',
	'pekša',
	'pelce',
	'pelči',
	'pelde',
	'peldē',
	'peldi',
	'peldu',
	'pelei',
	'peles',
	'pelēm',
	'pelēs',
	'pelēt',
	'pelīt',
	'pelli',
	'pelni',
	'pelnī',
	'pelns',
	'pelsi',
	'pelši',
	'pelšu',
	'pelta',
	'peltā',
	'pelti',
	'pelto',
	'pelts',
	'peltu',
	'pelus',
	'pelva',
	'pelžu',
	'peļam',
	'peļas',
	'peļat',
	'peļķe',
	'peļļi',
	'peļņa',
	'peļņā',
	'peļņi',
	'peļņu',
	'peļos',
	'peļot',
	'pemba',
	'pence',
	'penči',
	'penga',
	'penge',
	'pengs',
	'penhu',
	'penis',
	'penka',
	'penne',
	'penni',
	'pensa',
	'pensā',
	'pensi',
	'penss',
	'pensu',
	'penta',
	'pentā',
	'pente',
	'pentu',
	'penza',
	'peņķe',
	'peņķi',
	'peona',
	'peonā',
	'peoni',
	'peons',
	'peonu',
	'pepis',
	'peram',
	'peras',
	'perat',
	'perca',
	'perdu',
	'peres',
	'perēs',
	'perēt',
	'perga',
	'perge',
	'perma',
	'permā',
	'permi',
	'perms',
	'permu',
	'perna',
	'pernu',
	'peros',
	'perot',
	'perse',
	'persi',
	'perss',
	'perša',
	'peršā',
	'perši',
	'peršs',
	'peršu',
	'perve',
	'pesah',
	'peses',
	'peski',
	'pesko',
	'pesku',
	'peste',
	'pestī',
	'pesto',
	'peška',
	'pešta',
	'petļa',
	'petra',
	'pezna',
	'pēcam',
	'pēcāk',
	'pēcis',
	'pēčam',
	'pēčāk',
	'pēdas',
	'pēdos',
	'pēdot',
	'pēkst',
	'pēkši',
	'pēkšķ',
	'pēkšu',
	'pēlām',
	'pēlāt',
	'pēlēt',
	'pēlim',
	'pēlis',
	'pēļos',
	'pēļus',
	'pērām',
	'pērās',
	'pērāt',
	'pēris',
	'pērku',
	'pērla',
	'pērle',
	'pērlē',
	'pērli',
	'pērļo',
	'pērļu',
	'pērna',
	'pērnā',
	'pērni',
	'pērno',
	'pērns',
	'pērnu',
	'pēros',
	'pērsa',
	'pērse',
	'pērsi',
	'pēršu',
	'pērta',
	'pērtā',
	'pērti',
	'pērto',
	'pērts',
	'pērtu',
	'pētīs',
	'pētīt',
	'phare',
	'phoca',
	'phoma',
	'phone',
	'piala',
	'pialā',
	'pialu',
	'piano',
	'picea',
	'picka',
	'pickā',
	'picku',
	'picus',
	'pidna',
	'piear',
	'piece',
	'pieci',
	'piede',
	'pieej',
	'pieēd',
	'pieir',
	'piekt',
	'pieķe',
	'piems',
	'piena',
	'pienā',
	'pieni',
	'pieno',
	'piens',
	'pienu',
	'pieņi',
	'piepe',
	'piepē',
	'piepi',
	'piera',
	'piere',
	'pierē',
	'pieri',
	'pieru',
	'piesa',
	'pieši',
	'pieta',
	'pietā',
	'piete',
	'pieti',
	'piets',
	'pietu',
	'pieva',
	'piēra',
	'pikas',
	'pikās',
	'pikāt',
	'pikēs',
	'pikēt',
	'pikne',
	'pikos',
	'pikot',
	'pikre',
	'piksa',
	'piksi',
	'pikša',
	'pikšā',
	'pikši',
	'pikšs',
	'pikšu',
	'pikta',
	'piktā',
	'pikti',
	'pikto',
	'pikts',
	'piktu',
	'piķēs',
	'piķēt',
	'piķis',
	'piķos',
	'piķot',
	'pilam',
	'pilat',
	'pilām',
	'pilāt',
	'pilča',
	'pilda',
	'pildi',
	'pildu',
	'piles',
	'pilēs',
	'pilēt',
	'pilis',
	'pilka',
	'pilkt',
	'pillā',
	'pills',
	'pilna',
	'pilnā',
	'pilne',
	'pilni',
	'pilno',
	'pilns',
	'pilnu',
	'pilot',
	'pilsi',
	'pilst',
	'pilšu',
	'pilta',
	'piltā',
	'pilti',
	'pilto',
	'pilts',
	'piltu',
	'pilve',
	'piļka',
	'piļļa',
	'pimpa',
	'pinam',
	'pinas',
	'pinat',
	'pinām',
	'pinās',
	'pināt',
	'pinca',
	'pince',
	'pinčs',
	'pinda',
	'pinds',
	'pinga',
	'pingā',
	'pinis',
	'pinja',
	'pinka',
	'pinkā',
	'pinki',
	'pinko',
	'pinks',
	'pinkš',
	'pinku',
	'pinne',
	'pinnē',
	'pinni',
	'pinņu',
	'pinos',
	'pinot',
	'pinšu',
	'pinta',
	'pinte',
	'pintē',
	'pinti',
	'pinto',
	'pints',
	'pinus',
	'piņķe',
	'piņķē',
	'piņķi',
	'piņņi',
	'piona',
	'pionā',
	'pioni',
	'pions',
	'pionu',
	'piper',
	'pipka',
	'pipkā',
	'pipko',
	'pipku',
	'piras',
	'pirda',
	'pirdi',
	'pirdu',
	'pires',
	'pirga',
	'pirgu',
	'piris',
	'pirka',
	'pirki',
	'pirks',
	'pirkt',
	'pirku',
	'pirma',
	'pirmo',
	'pirms',
	'pirmu',
	'pirna',
	'pirra',
	'pirrs',
	'pirst',
	'pirts',
	'pirža',
	'piržu',
	'pisām',
	'pisās',
	'pisāt',
	'pisis',
	'pisīs',
	'piska',
	'pisos',
	'pissa',
	'pista',
	'pistā',
	'pisti',
	'pisto',
	'pists',
	'pistu',
	'pisum',
	'pišam',
	'pišas',
	'pišat',
	'pišma',
	'pišos',
	'pišot',
	'pitta',
	'piuka',
	'pizda',
	'pīcka',
	'pīckā',
	'pīcku',
	'pīdēt',
	'pīkas',
	'pīkst',
	'pīķis',
	'pīlei',
	'pīles',
	'pīlēm',
	'pīlēs',
	'pīpēs',
	'pīpēt',
	'pīpim',
	'pīpis',
	'pīpja',
	'pīpji',
	'pīpju',
	'pīpls',
	'pīpos',
	'pīpot',
	'pīsim',
	'pīsit',
	'pīsla',
	'pīšļi',
	'pīšos',
	'pīšot',
	'pītai',
	'pītam',
	'pītas',
	'pītām',
	'pītās',
	'pīteo',
	'pītes',
	'pītha',
	'pītie',
	'pītis',
	'pītne',
	'pītnē',
	'pītni',
	'pītņu',
	'pītos',
	'pītus',
	'pjaca',
	'pjana',
	'pjani',
	'pjati',
	'pjaui',
	'pjero',
	'pjesa',
	'pjeza',
	'pjezā',
	'pjezu',
	'pjēra',
	'plada',
	'pladā',
	'plade',
	'pladē',
	'pladi',
	'plads',
	'pladu',
	'plaka',
	'plake',
	'plaki',
	'plaks',
	'plakš',
	'plakt',
	'plaku',
	'plana',
	'pland',
	'plane',
	'planē',
	'plano',
	'plans',
	'plasa',
	'plasē',
	'plasi',
	'plass',
	'plaša',
	'plašā',
	'plaši',
	'plašo',
	'plašs',
	'plašu',
	'plata',
	'platā',
	'plate',
	'platē',
	'plati',
	'plato',
	'plats',
	'platu',
	'plava',
	'plaza',
	'plažu',
	'plāce',
	'plāča',
	'plāja',
	'plāji',
	'plāju',
	'plākš',
	'plāna',
	'plānā',
	'plāne',
	'plāni',
	'plāno',
	'plāns',
	'plānu',
	'plāņi',
	'plāsi',
	'plāši',
	'plāšs',
	'plāšu',
	'plāta',
	'plātā',
	'plāte',
	'plātē',
	'plāti',
	'plāto',
	'plāts',
	'plātu',
	'plāva',
	'plāvā',
	'plāvs',
	'plāvu',
	'pleca',
	'plecā',
	'plece',
	'pleci',
	'plecs',
	'plecu',
	'pleči',
	'pleda',
	'pledā',
	'pledi',
	'pleds',
	'pledu',
	'plekš',
	'plene',
	'plenē',
	'pleni',
	'pleno',
	'pleņu',
	'plesa',
	'plese',
	'plest',
	'pleša',
	'plešs',
	'plešu',
	'pleta',
	'pletē',
	'pleti',
	'plets',
	'pletu',
	'pleve',
	'plevu',
	'plēka',
	'plēkā',
	'plēku',
	'plēķi',
	'plēne',
	'plēnē',
	'plēni',
	'plēņu',
	'plēsa',
	'plēsi',
	'plēst',
	'plēsu',
	'plēša',
	'plēšā',
	'plēšu',
	'plēve',
	'plēvē',
	'plēvi',
	'plica',
	'plika',
	'plikā',
	'pliki',
	'pliko',
	'pliks',
	'plikš',
	'plikt',
	'pliku',
	'pliķē',
	'plink',
	'plisē',
	'pliss',
	'plita',
	'pliva',
	'plīks',
	'plīkš',
	'plīsa',
	'plīsi',
	'plīst',
	'plīsu',
	'plīša',
	'plīšā',
	'plīši',
	'plīšs',
	'plīšu',
	'plīta',
	'plītē',
	'plīti',
	'plītī',
	'plīts',
	'plīva',
	'plīvā',
	'plīvi',
	'plīvo',
	'plīvs',
	'plīvu',
	'ploce',
	'ploča',
	'ploče',
	'ploču',
	'plokš',
	'ploku',
	'plore',
	'plosa',
	'plosi',
	'plosu',
	'ploša',
	'ploši',
	'plota',
	'plote',
	'plots',
	'plova',
	'plovā',
	'plovi',
	'plovs',
	'plovu',
	'pluči',
	'pludi',
	'pludo',
	'pluds',
	'pluģi',
	'pluka',
	'pluki',
	'pluks',
	'plukt',
	'pluku',
	'plura',
	'plusa',
	'plusā',
	'plusi',
	'pluss',
	'plusu',
	'pluta',
	'plūca',
	'plūci',
	'plūcu',
	'plūči',
	'plūda',
	'plūde',
	'plūdi',
	'plūds',
	'plūdu',
	'plūka',
	'plūkā',
	'plūks',
	'plūkt',
	'plūku',
	'plūma',
	'plūme',
	'plūmē',
	'plūmi',
	'plūst',
	'plūts',
	'pļaka',
	'pļauj',
	'pļaus',
	'pļaut',
	'pļava',
	'pļavā',
	'pļavu',
	'pļāpa',
	'pļāpā',
	'pļāpu',
	'pļāva',
	'pļāvi',
	'pļāvu',
	'pļeka',
	'pļekš',
	'pļeku',
	'pļeva',
	'pļiku',
	'pļuka',
	'pļukš',
	'pļukt',
	'pļura',
	'pļusa',
	'pļuta',
	'pņevi',
	'pocim',
	'pocis',
	'počas',
	'počos',
	'počta',
	'počus',
	'podam',
	'podas',
	'podol',
	'podos',
	'podus',
	'podze',
	'podži',
	'podžo',
	'poena',
	'poēma',
	'poēmā',
	'poēmu',
	'poēts',
	'pofig',
	'pogas',
	'pogās',
	'pogāt',
	'pogos',
	'pogot',
	'poģis',
	'pohas',
	'pohuj',
	'poika',
	'poila',
	'poiša',
	'pokas',
	'poķis',
	'polga',
	'polim',
	'polis',
	'polje',
	'polka',
	'polkā',
	'polku',
	'polla',
	'polna',
	'polša',
	'polšs',
	'polts',
	'poļos',
	'poļus',
	'pomme',
	'pompa',
	'pompā',
	'pompu',
	'ponca',
	'pončo',
	'ponds',
	'ponga',
	'pongo',
	'ponis',
	'ponni',
	'ponse',
	'ponta',
	'ponte',
	'ponti',
	'ponts',
	'poopo',
	'popen',
	'popēs',
	'popēt',
	'popis',
	'popji',
	'porca',
	'porēs',
	'porēt',
	'porga',
	'porka',
	'porms',
	'porno',
	'porns',
	'porsa',
	'poršs',
	'porta',
	'porte',
	'porto',
	'ports',
	'portu',
	'porvo',
	'posām',
	'posās',
	'posāt',
	'posis',
	'posīs',
	'posīt',
	'posma',
	'posmā',
	'posmi',
	'posms',
	'posmu',
	'posos',
	'posse',
	'posta',
	'postā',
	'poste',
	'posti',
	'posto',
	'posts',
	'postu',
	'pošam',
	'pošas',
	'pošat',
	'pošku',
	'pošol',
	'pošos',
	'pošot',
	'potei',
	'potes',
	'potēm',
	'potēs',
	'potēt',
	'potus',
	'pover',
	'povoa',
	'pozēs',
	'pozēt',
	'prado',
	'praja',
	'praka',
	'praks',
	'prasa',
	'prasi',
	'prass',
	'prast',
	'prasu',
	'prata',
	'prati',
	'prato',
	'pratu',
	'prava',
	'prāca',
	'prāga',
	'prāms',
	'prāna',
	'prānā',
	'prānu',
	'prāta',
	'prātā',
	'prāti',
	'prāto',
	'prāts',
	'prātu',
	'prāva',
	'prāvā',
	'prāve',
	'prāvi',
	'prāvo',
	'prāvs',
	'prāvu',
	'prece',
	'precē',
	'preci',
	'precu',
	'preču',
	'prele',
	'prese',
	'presē',
	'presi',
	'press',
	'prešu',
	'prete',
	'preti',
	'pretī',
	'prevo',
	'prēmē',
	'prēti',
	'prici',
	'prieš',
	'prikš',
	'prima',
	'primo',
	'priši',
	'prīma',
	'prīmā',
	'prīmu',
	'prīze',
	'prīzē',
	'prīzi',
	'prīžu',
	'proči',
	'prode',
	'prods',
	'profi',
	'profs',
	'proja',
	'prome',
	'promi',
	'proms',
	'prone',
	'prosa',
	'proso',
	'proti',
	'prots',
	'protu',
	'prove',
	'provē',
	'provi',
	'provo',
	'proza',
	'prozā',
	'prozu',
	'pruha',
	'pruta',
	'prūss',
	'prūša',
	'prūši',
	'prūšu',
	'psihe',
	'psihē',
	'psihi',
	'psiho',
	'psihs',
	'psihu',
	'psila',
	'psīhe',
	'pskus',
	'psoas',
	'psora',
	'ptahs',
	'ptiča',
	'ptoze',
	'ptozē',
	'ptozi',
	'ptožu',
	'ptuja',
	'puasī',
	'puatū',
	'puaza',
	'puazā',
	'puazi',
	'puazs',
	'puazu',
	'pucēs',
	'pucēt',
	'pucka',
	'pučis',
	'pučka',
	'pudra',
	'pudža',
	'pudžu',
	'pugas',
	'puigs',
	'puika',
	'puikā',
	'puiks',
	'puiku',
	'puisī',
	'puiša',
	'pukst',
	'pukši',
	'pukšķ',
	'pukšu',
	'puķei',
	'puķes',
	'puķēm',
	'puķēs',
	'puķēt',
	'pulce',
	'pulcē',
	'pulēs',
	'pulēt',
	'pulga',
	'pulgo',
	'pulis',
	'pulka',
	'pulkā',
	'pulki',
	'pulks',
	'pulku',
	'pulmo',
	'pulpa',
	'pulpā',
	'pulpu',
	'pulsa',
	'pulsā',
	'pulsē',
	'pulsi',
	'pulss',
	'pulsu',
	'pulšu',
	'pulti',
	'pultī',
	'pults',
	'puļīt',
	'puļķi',
	'pumpa',
	'pumpā',
	'pumpe',
	'pumpē',
	'pumpi',
	'pumpu',
	'punch',
	'punes',
	'punga',
	'punis',
	'punka',
	'punks',
	'punna',
	'punša',
	'punšā',
	'punši',
	'punšs',
	'punšu',
	'punta',
	'punte',
	'puņķi',
	'pupis',
	'purga',
	'purks',
	'purkš',
	'purms',
	'purna',
	'purnā',
	'purne',
	'purni',
	'purns',
	'purnu',
	'purņi',
	'purui',
	'purva',
	'purvā',
	'purvi',
	'purvs',
	'purvu',
	'puska',
	'pusta',
	'pusts',
	'pušai',
	'pušam',
	'pušas',
	'pušām',
	'pušās',
	'pušča',
	'pušie',
	'puška',
	'pušķa',
	'pušķo',
	'pušos',
	'pušta',
	'puštu',
	'pušus',
	'putaa',
	'putaā',
	'putai',
	'putam',
	'putas',
	'putat',
	'putau',
	'putēs',
	'putēt',
	'putka',
	'putna',
	'putnā',
	'putni',
	'putns',
	'putnu',
	'putos',
	'putot',
	'putra',
	'putrā',
	'putro',
	'putru',
	'putti',
	'putto',
	'puvām',
	'puvāt',
	'puvis',
	'puzle',
	'pūces',
	'pūdēs',
	'pūdēt',
	'pūdža',
	'pūdžā',
	'pūdži',
	'pūdžu',
	'pūgas',
	'pūkas',
	'pūkos',
	'pūkot',
	'pūkši',
	'pūkšķ',
	'pūkšu',
	'pūķis',
	'pūlas',
	'pūlea',
	'pūleā',
	'pūlei',
	'pūles',
	'pūleu',
	'pūlēt',
	'pūlim',
	'pūlis',
	'pūlos',
	'pūļos',
	'pūļus',
	'pūnis',
	'pūņas',
	'pūpis',
	'pūris',
	'pūrna',
	'pūsim',
	'pūsit',
	'pūsma',
	'pūsmā',
	'pūsmu',
	'pūsta',
	'pūstā',
	'pūsti',
	'pūsto',
	'pūsts',
	'pūstu',
	'pūšam',
	'pūšas',
	'pūšat',
	'pūšļi',
	'pūšļo',
	'pūšņi',
	'pūšos',
	'pūšot',
	'pūšus',
	'pūtai',
	'pūtam',
	'pūtas',
	'pūtām',
	'pūtās',
	'pūtāt',
	'pūtes',
	'pūtie',
	'pūtin',
	'pūtis',
	'pūtīs',
	'pūtīt',
	'pūtos',
	'pūtus',
	'pūžņi',
	'pūžņo',
	'queto',
	'rabas',
	'rabbi',
	'races',
	'racis',
	'radas',
	'radām',
	'radās',
	'radāt',
	'radio',
	'radis',
	'radīs',
	'radīt',
	'radne',
	'rados',
	'radot',
	'radus',
	'radze',
	'radzē',
	'radzi',
	'radža',
	'radžā',
	'radži',
	'radžu',
	'ragas',
	'ragbi',
	'ragga',
	'ragus',
	'raģes',
	'raģis',
	'raiba',
	'raibā',
	'raibi',
	'raibo',
	'raibs',
	'raibu',
	'raiča',
	'raida',
	'raidi',
	'raids',
	'raidu',
	'raija',
	'raika',
	'raiks',
	'raile',
	'raina',
	'rains',
	'raipa',
	'raisa',
	'raisi',
	'raisu',
	'raiši',
	'raita',
	'raitā',
	'raiti',
	'raito',
	'raits',
	'raitu',
	'raiza',
	'raize',
	'raizē',
	'raizi',
	'raižu',
	'rajah',
	'rajas',
	'rakas',
	'rakām',
	'rakās',
	'rakāt',
	'rakņā',
	'rakom',
	'rakos',
	'raksi',
	'rakšu',
	'rakta',
	'raktā',
	'rakte',
	'rakti',
	'rakto',
	'rakts',
	'raktu',
	'rakus',
	'raķis',
	'rallā',
	'ralli',
	'raļļi',
	'ramas',
	'ramīt',
	'ramma',
	'ramni',
	'ramot',
	'rampa',
	'rampā',
	'ramps',
	'rampu',
	'ramsi',
	'ramta',
	'ranča',
	'ranči',
	'rančo',
	'randa',
	'rands',
	'randu',
	'ranga',
	'rangā',
	'rangi',
	'rangs',
	'rangu',
	'ranka',
	'rankā',
	'ranki',
	'ranks',
	'ranku',
	'ranta',
	'rante',
	'rants',
	'ranžē',
	'raņķi',
	'rapid',
	'rapla',
	'rapšu',
	'rasas',
	'rasēs',
	'rasēt',
	'rasma',
	'rasmā',
	'rasms',
	'rasmu',
	'rasos',
	'rasot',
	'rasta',
	'rastā',
	'rasti',
	'rasto',
	'rasts',
	'rastu',
	'ratha',
	'ratot',
	'rauca',
	'rauci',
	'raucu',
	'rauda',
	'raudā',
	'raude',
	'raudi',
	'rauds',
	'raudu',
	'rauga',
	'raugā',
	'raugi',
	'raugs',
	'raugu',
	'rauju',
	'rauks',
	'raukt',
	'rauma',
	'rauna',
	'raupa',
	'raupi',
	'raups',
	'rausa',
	'rausi',
	'raust',
	'rausu',
	'rauša',
	'rauši',
	'raušu',
	'rauta',
	'rautā',
	'rauti',
	'rautī',
	'rauto',
	'rauts',
	'rautu',
	'rauza',
	'ravēs',
	'ravēt',
	'ravne',
	'razas',
	'ražos',
	'ražot',
	'rādām',
	'rādās',
	'rādāt',
	'rādha',
	'rādīs',
	'rādīt',
	'rādos',
	'rādot',
	'rādze',
	'rādzē',
	'rādzi',
	'rādža',
	'rādžā',
	'rādžu',
	'rāģis',
	'rājam',
	'rājas',
	'rājat',
	'rājām',
	'rājās',
	'rājāt',
	'rājis',
	'rājos',
	'rājot',
	'rāmēt',
	'rāmim',
	'rāmis',
	'rāmīs',
	'rāmīt',
	'rāmja',
	'rāmji',
	'rāmju',
	'rānčī',
	'rāpās',
	'rāpos',
	'rāpot',
	'rāpus',
	'rāsim',
	'rāsit',
	'rāšos',
	'rāšot',
	'rātai',
	'rātam',
	'rātas',
	'rātām',
	'rātās',
	'rātie',
	'rātna',
	'rātnā',
	'rātni',
	'rātno',
	'rātns',
	'rātnu',
	'rātos',
	'rātus',
	'rāvai',
	'rāvam',
	'rāvas',
	'rāvām',
	'rāvās',
	'rāvāt',
	'rāvie',
	'rāvis',
	'rāvos',
	'rāvus',
	'rāzna',
	'rbtac',
	'rcaii',
	'reaģē',
	'reāla',
	'reālā',
	'reāli',
	'reālo',
	'reāls',
	'reālu',
	'rebab',
	'rebea',
	'rebeā',
	'rebei',
	'rebes',
	'rebeu',
	'rebis',
	'recēs',
	'recēt',
	'rečka',
	'redbī',
	'redza',
	'redze',
	'redzē',
	'redzi',
	'redzu',
	'redžo',
	'redžu',
	'regas',
	'regus',
	'regži',
	'reģis',
	'reiba',
	'reibā',
	'reibi',
	'reibo',
	'reibs',
	'reibt',
	'reibu',
	'reida',
	'reidā',
	'reidi',
	'reids',
	'reidu',
	'reihs',
	'reika',
	'reiki',
	'reiks',
	'reina',
	'reine',
	'reini',
	'reiņi',
	'reisa',
	'reisā',
	'reisi',
	'reiss',
	'reisu',
	'reits',
	'reivs',
	'reiza',
	'reizā',
	'reize',
	'reizē',
	'reizi',
	'reižu',
	'rejam',
	'rejas',
	'rejat',
	'rejos',
	'rejot',
	'rekse',
	'rekši',
	'rekšķ',
	'rekšu',
	'rekur',
	'reķis',
	'remdē',
	'remes',
	'remis',
	'remiz',
	'rempe',
	'rempi',
	'remsa',
	'remte',
	'remzi',
	'renča',
	'renčs',
	'renda',
	'rende',
	'rends',
	'renga',
	'rengā',
	'rengu',
	'renna',
	'renne',
	'rensa',
	'renst',
	'renšu',
	'rente',
	'rentē',
	'renti',
	'reņģe',
	'repēs',
	'repēt',
	'repki',
	'repos',
	'repot',
	'repss',
	'repša',
	'repši',
	'repšs',
	'resis',
	'resko',
	'resna',
	'resnā',
	'resne',
	'resnē',
	'resni',
	'resno',
	'resns',
	'resnu',
	'reste',
	'restē',
	'reška',
	'rešņa',
	'rešņi',
	'rešņu',
	'retai',
	'retam',
	'retes',
	'retim',
	'retis',
	'retro',
	'retum',
	'reusa',
	'reuta',
	'reuts',
	'revda',
	'revēt',
	'revue',
	'rezka',
	'režģī',
	'rēcam',
	'rēcat',
	'rēcām',
	'rēcāt',
	'rēcis',
	'rēcot',
	'rēdas',
	'rēdea',
	'rēdeā',
	'rēdei',
	'rēdes',
	'rēdeu',
	'rēdze',
	'rēdzē',
	'rēdzi',
	'rēdžu',
	'rēgos',
	'rēgot',
	'rējām',
	'rējās',
	'rējāt',
	'rējis',
	'rējos',
	'rēksi',
	'rēkšu',
	'rēkta',
	'rēktā',
	'rēkti',
	'rēkto',
	'rēkts',
	'rēktu',
	'rēķis',
	'rēnai',
	'rēnam',
	'rēnas',
	'rēnām',
	'rēnās',
	'rēnie',
	'rēnos',
	'rēnus',
	'rētos',
	'rētot',
	'rēvēs',
	'rēvēt',
	'rēzes',
	'rēzna',
	'rēzus',
	'rheum',
	'riāla',
	'riālā',
	'riāli',
	'riāls',
	'riālu',
	'ribes',
	'ribhu',
	'ricas',
	'ricis',
	'ricka',
	'ridas',
	'ridāt',
	'ridze',
	'rieba',
	'riebi',
	'riebj',
	'riebs',
	'riebt',
	'riebu',
	'riece',
	'rieda',
	'rieks',
	'riekt',
	'riels',
	'riepa',
	'riepā',
	'riepe',
	'riept',
	'riepu',
	'riere',
	'rierē',
	'rieri',
	'rieru',
	'riesa',
	'riesi',
	'riest',
	'riešu',
	'rieta',
	'rietā',
	'rieti',
	'rieto',
	'riets',
	'rietu',
	'rieva',
	'rievā',
	'rievo',
	'rievu',
	'rieza',
	'rieze',
	'riezi',
	'riezt',
	'riezu',
	'rieža',
	'riežā',
	'riežu',
	'rifts',
	'rijam',
	'rijas',
	'rijat',
	'rijām',
	'rijāt',
	'rijis',
	'rijot',
	'rikot',
	'rikša',
	'rikšā',
	'rikši',
	'rikšo',
	'rikšu',
	'rikte',
	'riķis',
	'rimām',
	'rimās',
	'rimāt',
	'rimba',
	'rimis',
	'rimos',
	'rimsi',
	'rimst',
	'rimša',
	'rimši',
	'rimšu',
	'rimta',
	'rimtā',
	'rimti',
	'rimto',
	'rimts',
	'rimtu',
	'rinda',
	'rindā',
	'rindo',
	'rindu',
	'ringa',
	'ringā',
	'ringe',
	'ringi',
	'rings',
	'ringu',
	'rinka',
	'rinki',
	'rinks',
	'rints',
	'riņķī',
	'riņķo',
	'riņņu',
	'riolo',
	'rioni',
	'ripos',
	'ripot',
	'ripsa',
	'ripsā',
	'ripsi',
	'ripss',
	'ripsu',
	'ripši',
	'risam',
	'risas',
	'risat',
	'risām',
	'risāt',
	'risēs',
	'risēt',
	'risis',
	'risīs',
	'riska',
	'riskā',
	'riskē',
	'riski',
	'risks',
	'risku',
	'risot',
	'rissa',
	'rista',
	'ristā',
	'riste',
	'risti',
	'risto',
	'rists',
	'ristu',
	'riška',
	'ritam',
	'ritat',
	'ritēs',
	'ritēt',
	'ritma',
	'ritmā',
	'ritmi',
	'ritms',
	'ritmu',
	'ritot',
	'rivne',
	'rizga',
	'rībam',
	'rības',
	'rībat',
	'rībēs',
	'rībēt',
	'rībot',
	'rīdām',
	'rīdāt',
	'rīdīs',
	'rīdīt',
	'rīdot',
	'rīdze',
	'rīdzē',
	'rīdzi',
	'rīdžu',
	'rīgas',
	'rīkle',
	'rīklē',
	'rīkli',
	'rīkļu',
	'rīkos',
	'rīkot',
	'rīmes',
	'rīmēs',
	'rīmēt',
	'rīsim',
	'rīsis',
	'rīsit',
	'rīšot',
	'rītai',
	'rītam',
	'rītas',
	'rītām',
	'rītās',
	'rītie',
	'rītin',
	'rītos',
	'rītus',
	'rīvēs',
	'rīvēt',
	'rlmvm',
	'robai',
	'robam',
	'robas',
	'robām',
	'robās',
	'robie',
	'robīs',
	'robīt',
	'robos',
	'robot',
	'robus',
	'rocis',
	'rodam',
	'rodas',
	'rodat',
	'rodeo',
	'rodīt',
	'rodos',
	'rodot',
	'rodze',
	'rogas',
	'roģis',
	'roida',
	'rokam',
	'rokas',
	'rokat',
	'rokos',
	'rokot',
	'roķis',
	'romba',
	'rombā',
	'rombi',
	'rombs',
	'rombu',
	'romis',
	'romni',
	'ronda',
	'rondo',
	'ronds',
	'rongo',
	'ronim',
	'ronis',
	'ronse',
	'roņos',
	'roņus',
	'ropju',
	'rosās',
	'rosis',
	'rosma',
	'rosmā',
	'rosme',
	'rosmē',
	'rosmi',
	'rosmu',
	'rosos',
	'rosta',
	'roste',
	'rosts',
	'rotās',
	'rotāt',
	'rotēs',
	'rotēt',
	'rotīs',
	'rotīt',
	'rotot',
	'rouda',
	'roume',
	'route',
	'rovim',
	'rovis',
	'rovja',
	'rovji',
	'rovju',
	'rovna',
	'rozas',
	'rozes',
	'rozis',
	'rozīt',
	'rtkic',
	'ruana',
	'ruasī',
	'ruāna',
	'rubīt',
	'rubus',
	'rucis',
	'rucka',
	'ručka',
	'rudai',
	'rudam',
	'rudas',
	'rudām',
	'rudās',
	'ruden',
	'rudēs',
	'rudēt',
	'rudie',
	'rudin',
	'rudīt',
	'rudki',
	'rudna',
	'rudņa',
	'rudos',
	'rudot',
	'rudra',
	'rudus',
	'rudzi',
	'ruhnu',
	'ruinē',
	'ruiru',
	'ruiss',
	'ruīna',
	'ruīnā',
	'ruīnu',
	'rujen',
	'rukām',
	'rukās',
	'rukāt',
	'ruksi',
	'rukst',
	'rukši',
	'rukšķ',
	'rukšu',
	'rukta',
	'ruktā',
	'rukti',
	'rukto',
	'rukts',
	'ruktu',
	'ruķis',
	'rules',
	'rulis',
	'rulle',
	'rullē',
	'rullu',
	'ruļļu',
	'rumba',
	'rumbā',
	'rumbi',
	'rumbs',
	'rumbu',
	'rumpi',
	'runās',
	'runāt',
	'runci',
	'runči',
	'runda',
	'rundi',
	'rundu',
	'runga',
	'rungā',
	'rungi',
	'rungo',
	'rungu',
	'runka',
	'runte',
	'ruņči',
	'ruņģi',
	'rupja',
	'rupjā',
	'rupji',
	'rupjo',
	'rupjš',
	'rupju',
	'ruska',
	'rusks',
	'rusne',
	'rusta',
	'rustā',
	'ruste',
	'rustē',
	'rusti',
	'rusts',
	'rustu',
	'ruška',
	'ruškā',
	'rušku',
	'rušķe',
	'rutka',
	'rutkā',
	'rutki',
	'rutks',
	'rutku',
	'ruzga',
	'ruzgā',
	'ruzgi',
	'ruzgo',
	'ruzgs',
	'ruzgu',
	'rūcam',
	'rūcat',
	'rūcām',
	'rūcāt',
	'rūcis',
	'rūcot',
	'rūdām',
	'rūdās',
	'rūdāt',
	'rūdīs',
	'rūdīt',
	'rūdos',
	'rūdot',
	'rūgām',
	'rūgāt',
	'rūgsi',
	'rūgst',
	'rūgšu',
	'rūgta',
	'rūgtā',
	'rūgtē',
	'rūgti',
	'rūgto',
	'rūgts',
	'rūgtu',
	'rūkam',
	'rūkas',
	'rūkat',
	'rūkās',
	'rūkāt',
	'rūkņā',
	'rūkos',
	'rūkot',
	'rūksi',
	'rūkšu',
	'rūkta',
	'rūktā',
	'rūkti',
	'rūkto',
	'rūkts',
	'rūktu',
	'rūķis',
	'rūnas',
	'rūnām',
	'rūnāt',
	'rūnīs',
	'rūnīt',
	'rūnot',
	'rūpas',
	'rūpes',
	'rūpēs',
	'rūpēt',
	'rūsēs',
	'rūsēt',
	'rūsga',
	'rūsgā',
	'rūsgi',
	'rūsgo',
	'rūsgs',
	'rūsgu',
	'rūsis',
	'rūsma',
	'rūsot',
	'rūtes',
	'rūtij',
	'rūtis',
	'rūtīm',
	'rūtīs',
	'rūtos',
	'rūtot',
	'rūzga',
	'rūzgā',
	'rūzgi',
	'rūzgo',
	'rūzgs',
	'rūzgu',
	'rūzis',
	'rūžas',
	'rževa',
	'saaco',
	'saada',
	'saadi',
	'saadu',
	'saalo',
	'saara',
	'saari',
	'saars',
	'saart',
	'saaru',
	'saaud',
	'saaug',
	'saauj',
	'saaus',
	'saaut',
	'saauž',
	'saāķē',
	'saāva',
	'saāvi',
	'saāvu',
	'sabal',
	'sabar',
	'sabāz',
	'sabāž',
	'saber',
	'sabēg',
	'saboz',
	'sabož',
	'sabre',
	'sabro',
	'sabur',
	'sabūs',
	'sabūt',
	'sacel',
	'saceļ',
	'sacep',
	'sacer',
	'sacīs',
	'sacīt',
	'sacra',
	'sačab',
	'sačib',
	'sadej',
	'sader',
	'sadēd',
	'sadēj',
	'sadēs',
	'sadēt',
	'sadhu',
	'sadko',
	'sadod',
	'sados',
	'sadot',
	'sadur',
	'saecē',
	'saeju',
	'saēda',
	'saēdi',
	'saēdu',
	'saēst',
	'safra',
	'sagas',
	'sagāz',
	'sagāž',
	'sagša',
	'sagšā',
	'sagšu',
	'sagte',
	'sagul',
	'sagūt',
	'sahel',
	'saida',
	'saidā',
	'saidu',
	'saies',
	'saiet',
	'saiga',
	'saigā',
	'saigu',
	'saima',
	'saime',
	'saimē',
	'saimi',
	'saims',
	'saint',
	'saiņo',
	'saira',
	'sairā',
	'sairi',
	'sairs',
	'sairt',
	'sairu',
	'saišu',
	'saita',
	'saite',
	'saitē',
	'saiti',
	'saits',
	'saiva',
	'saivā',
	'saive',
	'saivu',
	'saīga',
	'saīgi',
	'saīgs',
	'saīgt',
	'saīgu',
	'sajāj',
	'sajās',
	'sajāt',
	'sajoz',
	'sajož',
	'sajum',
	'sajūc',
	'sajūk',
	'sajūt',
	'sakaa',
	'sakaā',
	'sakai',
	'sakal',
	'sakaļ',
	'sakar',
	'sakas',
	'sakaš',
	'sakau',
	'sakām',
	'sakāp',
	'sakās',
	'sakāš',
	'sakāt',
	'sakļa',
	'sakļā',
	'sakļu',
	'sakne',
	'saknē',
	'sakni',
	'sakņo',
	'sakņu',
	'sakod',
	'sakop',
	'sakos',
	'sakot',
	'sakož',
	'sakrs',
	'sakša',
	'sakši',
	'sakšu',
	'sakta',
	'saktā',
	'sakte',
	'sakti',
	'sakts',
	'saktu',
	'sakul',
	'sakuļ',
	'sakur',
	'saķep',
	'saķer',
	'salas',
	'salām',
	'salāt',
	'salda',
	'saldā',
	'salde',
	'saldē',
	'saldi',
	'saldo',
	'salds',
	'saldu',
	'salec',
	'salej',
	'salep',
	'salēs',
	'salēš',
	'salis',
	'salīp',
	'salīs',
	'salīt',
	'salka',
	'salki',
	'salks',
	'salkt',
	'salku',
	'salma',
	'salmā',
	'salme',
	'salmi',
	'salmo',
	'salms',
	'salmu',
	'salna',
	'salnā',
	'salni',
	'salno',
	'salns',
	'salnu',
	'salot',
	'salou',
	'salsa',
	'salsi',
	'salst',
	'salša',
	'salšu',
	'salta',
	'saltā',
	'salti',
	'salto',
	'salts',
	'saltu',
	'salva',
	'salve',
	'saļņi',
	'saļut',
	'saļūc',
	'saļūk',
	'samal',
	'samaļ',
	'samba',
	'sambā',
	'sambi',
	'sambo',
	'sambu',
	'samej',
	'samek',
	'samet',
	'samin',
	'samīs',
	'samīt',
	'samīz',
	'samīž',
	'samoa',
	'sampi',
	'sampo',
	'samsa',
	'samta',
	'samtā',
	'samti',
	'samts',
	'samtu',
	'samūc',
	'samūk',
	'sanāc',
	'sanāk',
	'sanca',
	'sanči',
	'sanda',
	'sandē',
	'sandi',
	'sanes',
	'sanēs',
	'sanēt',
	'sanga',
	'sanīt',
	'sankt',
	'sansa',
	'santa',
	'santi',
	'santo',
	'sants',
	'saņem',
	'saoda',
	'saodi',
	'saodu',
	'saogo',
	'saost',
	'saožu',
	'sapas',
	'saper',
	'sapes',
	'sapfo',
	'sapil',
	'sapin',
	'sapīs',
	'sapīt',
	'sapna',
	'sapņo',
	'sapos',
	'sapoš',
	'sapri',
	'saput',
	'sapūs',
	'sapūš',
	'sapūt',
	'sarāj',
	'sarās',
	'sarāt',
	'sardi',
	'sarej',
	'sarep',
	'sarga',
	'sargā',
	'sargi',
	'sargs',
	'sargu',
	'sarij',
	'sarit',
	'sarīs',
	'sarīt',
	'sarja',
	'sarka',
	'sarki',
	'sarks',
	'sarkt',
	'sarku',
	'sarķe',
	'sarķi',
	'sarma',
	'sarmā',
	'sarmo',
	'sarms',
	'sarmu',
	'sarni',
	'sarno',
	'saroc',
	'sarod',
	'sarok',
	'sarre',
	'sarta',
	'sarti',
	'sartu',
	'sarūc',
	'sarūk',
	'sarve',
	'sarža',
	'saržā',
	'sarži',
	'saržs',
	'saržu',
	'sasāp',
	'sasēd',
	'sasēj',
	'sasēs',
	'sasēt',
	'sasēž',
	'sasis',
	'sasit',
	'sasūc',
	'sasūt',
	'sašuj',
	'sašūs',
	'sašūt',
	'satek',
	'satēs',
	'satēš',
	'satin',
	'satis',
	'satīs',
	'satīt',
	'satja',
	'sator',
	'satur',
	'satva',
	'sauca',
	'sauce',
	'sauci',
	'saucu',
	'saugt',
	'sauja',
	'saujā',
	'sauju',
	'sauka',
	'saukā',
	'sauks',
	'saukt',
	'saule',
	'saulē',
	'sauli',
	'sauls',
	'sauļa',
	'sauļi',
	'sauļo',
	'sauļu',
	'saums',
	'sauna',
	'saunā',
	'saunu',
	'saurb',
	'saurs',
	'sausa',
	'sausā',
	'sausē',
	'sausi',
	'sauso',
	'sauss',
	'sausu',
	'sauši',
	'sauta',
	'saute',
	'sautē',
	'sauts',
	'sauva',
	'saūda',
	'savāc',
	'savāz',
	'savāž',
	'saved',
	'savel',
	'saveļ',
	'saver',
	'savij',
	'savīs',
	'savīt',
	'sazel',
	'sazeļ',
	'sazin',
	'sazīd',
	'sazīž',
	'sazog',
	'sažūs',
	'sažūt',
	'sābra',
	'sābrā',
	'sābri',
	'sābrs',
	'sābru',
	'sācis',
	'sādža',
	'sāģis',
	'sājai',
	'sājam',
	'sājas',
	'sājām',
	'sājās',
	'sājie',
	'sājos',
	'sājus',
	'sākam',
	'sākas',
	'sākat',
	'sākām',
	'sākās',
	'sākāt',
	'sākla',
	'sākos',
	'sākot',
	'sāksi',
	'sākšu',
	'sākta',
	'sāktā',
	'sākti',
	'sākto',
	'sākts',
	'sāktu',
	'sālām',
	'sālās',
	'sālāt',
	'sāles',
	'sālis',
	'sālīs',
	'sālīt',
	'sālos',
	'sālot',
	'sāļai',
	'sāļam',
	'sāļas',
	'sāļām',
	'sāļās',
	'sāļie',
	'sāļos',
	'sāļus',
	'sānes',
	'sānis',
	'sānus',
	'sāņis',
	'sāņup',
	'sāņus',
	'sāpam',
	'sāpat',
	'sāpes',
	'sāpēs',
	'sāpēt',
	'sāpot',
	'sāris',
	'sārķe',
	'sārma',
	'sārmā',
	'sārmi',
	'sārmo',
	'sārms',
	'sārmu',
	'sārni',
	'sārņi',
	'sārņo',
	'sārta',
	'sārtā',
	'sārte',
	'sārti',
	'sārto',
	'sārts',
	'sārtu',
	'sātni',
	'sbori',
	'scala',
	'scēna',
	'scēnā',
	'scēnu',
	'score',
	'scrub',
	'scudo',
	'sdaka',
	'seara',
	'seato',
	'sebai',
	'sebam',
	'sebas',
	'sebām',
	'sebās',
	'sebha',
	'sebie',
	'sebos',
	'sebrs',
	'sebum',
	'sebus',
	'secam',
	'secās',
	'secco',
	'secen',
	'secēs',
	'secēt',
	'secin',
	'secos',
	'sedde',
	'sedli',
	'sedlo',
	'sedna',
	'sedoc',
	'sedum',
	'sedza',
	'sedzi',
	'sedzu',
	'segai',
	'segas',
	'segām',
	'segās',
	'segli',
	'seglo',
	'segļi',
	'segno',
	'segre',
	'segsi',
	'segšu',
	'segta',
	'segtā',
	'segti',
	'segto',
	'segts',
	'segtu',
	'segue',
	'seida',
	'seifa',
	'seifā',
	'seifi',
	'seifs',
	'seifu',
	'seija',
	'seila',
	'seili',
	'seiļi',
	'seima',
	'seimā',
	'seimi',
	'seims',
	'seimu',
	'seini',
	'seiša',
	'seišā',
	'seišu',
	'seivs',
	'sejma',
	'sejni',
	'sekaa',
	'sekaā',
	'sekai',
	'sekas',
	'sekau',
	'sekla',
	'seklā',
	'sekle',
	'seklē',
	'sekli',
	'seklo',
	'sekls',
	'seklu',
	'sekļi',
	'sekļu',
	'sekme',
	'sekmē',
	'sekos',
	'sekot',
	'seksa',
	'seksā',
	'seksi',
	'sekss',
	'seksu',
	'sekša',
	'sekta',
	'sektā',
	'sektu',
	'sekus',
	'selah',
	'selga',
	'selgā',
	'selgu',
	'selje',
	'selke',
	'selma',
	'selva',
	'selvā',
	'selvu',
	'semba',
	'semen',
	'semis',
	'senai',
	'senam',
	'senas',
	'senāk',
	'senām',
	'senās',
	'senāt',
	'sence',
	'sencē',
	'senci',
	'senči',
	'senču',
	'sendi',
	'senga',
	'senie',
	'senis',
	'senja',
	'senji',
	'senlo',
	'senna',
	'senno',
	'senos',
	'senta',
	'sentā',
	'senti',
	'sents',
	'sentu',
	'senus',
	'senza',
	'sepse',
	'sepsē',
	'sepsi',
	'sepšu',
	'septa',
	'seram',
	'seras',
	'serat',
	'serba',
	'serbā',
	'serbi',
	'serbs',
	'serbu',
	'serde',
	'serdē',
	'serdi',
	'serds',
	'seren',
	'seres',
	'serga',
	'serio',
	'seris',
	'serks',
	'serot',
	'serpa',
	'serra',
	'serre',
	'serso',
	'serve',
	'servē',
	'servi',
	'serža',
	'seržī',
	'seržs',
	'seržu',
	'sesia',
	'seska',
	'seskā',
	'seski',
	'sesks',
	'sesku',
	'sesmi',
	'sesto',
	'sešas',
	'setka',
	'seula',
	'seuta',
	'sevis',
	'sevro',
	'sēcam',
	'sēcat',
	'sēcām',
	'sēcāt',
	'sēcis',
	'sēcot',
	'sēdam',
	'sēdat',
	'sēdās',
	'sēdēs',
	'sēdēt',
	'sēdos',
	'sēdot',
	'sēdus',
	'sējam',
	'sējas',
	'sējat',
	'sējām',
	'sējās',
	'sējāt',
	'sējis',
	'sējos',
	'sējot',
	'sēkās',
	'sēkāt',
	'sēkla',
	'sēklā',
	'sēklu',
	'sēkļa',
	'sēkot',
	'sēksi',
	'sēkšu',
	'sēkta',
	'sēktā',
	'sēkti',
	'sēkto',
	'sēkts',
	'sēktu',
	'sēles',
	'sēlim',
	'sēlis',
	'sēļos',
	'sēļus',
	'sēnes',
	'sēņos',
	'sēņot',
	'sēraa',
	'sēraā',
	'sērai',
	'sēram',
	'sēras',
	'sērau',
	'sērām',
	'sērās',
	'sērāt',
	'sērfa',
	'sērfā',
	'sērfi',
	'sērfo',
	'sērfs',
	'sērfu',
	'sērga',
	'sērgā',
	'sērgu',
	'sērie',
	'sēris',
	'sērma',
	'sēros',
	'sērot',
	'sērsa',
	'sērsi',
	'sērst',
	'sērsu',
	'sēršu',
	'sērta',
	'sērtā',
	'sērti',
	'sērto',
	'sērts',
	'sērtu',
	'sērus',
	'sēsim',
	'sēsit',
	'sēšos',
	'sēšot',
	'sētai',
	'sētam',
	'sētas',
	'sētām',
	'sētās',
	'sētie',
	'sētin',
	'sētīt',
	'sētos',
	'sētus',
	'sēvra',
	'sēžas',
	'sēžos',
	'sfēna',
	'sfēnā',
	'sfēni',
	'sfēns',
	'sfēnu',
	'sfēra',
	'sfērā',
	'sfēru',
	'shape',
	'share',
	'shēma',
	'shēmā',
	'shēmu',
	'shire',
	'shīma',
	'shīmā',
	'shīmu',
	'siala',
	'sialā',
	'siali',
	'sials',
	'sialu',
	'siana',
	'siaņa',
	'siāma',
	'sibis',
	'sibiu',
	'sibsi',
	'sicav',
	'sicis',
	'sidha',
	'sidhi',
	'sidka',
	'sidni',
	'sidra',
	'sidrā',
	'sidri',
	'sidrs',
	'sidru',
	'sieka',
	'siekā',
	'sieki',
	'sieks',
	'sieku',
	'siena',
	'sienā',
	'sieni',
	'siens',
	'sienu',
	'siera',
	'sierā',
	'sieri',
	'siero',
	'siers',
	'sieru',
	'siesi',
	'siešu',
	'sieta',
	'sietā',
	'siete',
	'sietē',
	'sieti',
	'sieto',
	'siets',
	'sietu',
	'sieva',
	'sievā',
	'sievu',
	'siežu',
	'sigas',
	'sight',
	'sigma',
	'sijas',
	'sijās',
	'sijāt',
	'sikām',
	'sikāt',
	'sikes',
	'sikhi',
	'sikhs',
	'siksi',
	'sikšu',
	'sikta',
	'siktā',
	'sikti',
	'sikto',
	'sikts',
	'siktu',
	'silām',
	'silāt',
	'silda',
	'sildi',
	'sildu',
	'silfa',
	'silfā',
	'silfi',
	'silfs',
	'silfu',
	'silis',
	'silli',
	'sills',
	'silsi',
	'silst',
	'silšu',
	'silta',
	'siltā',
	'silti',
	'silto',
	'silts',
	'siltu',
	'silva',
	'silvi',
	'siļķe',
	'simap',
	'simla',
	'simna',
	'simta',
	'simtā',
	'simti',
	'simts',
	'simtu',
	'sinda',
	'sindi',
	'singa',
	'sinis',
	'sinka',
	'sinti',
	'sinto',
	'sinus',
	'siņka',
	'siona',
	'sioni',
	'sipki',
	'siras',
	'sirat',
	'sirdi',
	'sirdī',
	'sirds',
	'sirdu',
	'sirga',
	'sirgi',
	'sirgs',
	'sirgt',
	'sirgu',
	'siris',
	'sirks',
	'sirma',
	'sirmā',
	'sirmi',
	'sirmo',
	'sirms',
	'sirmu',
	'siros',
	'sirot',
	'sirpa',
	'sirps',
	'sirta',
	'sirte',
	'sirts',
	'siržu',
	'sisis',
	'sisla',
	'sista',
	'sistā',
	'sisti',
	'sisto',
	'sists',
	'sistu',
	'sitam',
	'sitas',
	'sitat',
	'sitām',
	'sitās',
	'sitāt',
	'sitis',
	'sitīs',
	'sitka',
	'sitos',
	'sitot',
	'sitta',
	'situē',
	'situs',
	'sitve',
	'sīcis',
	'sīkai',
	'sīkam',
	'sīkas',
	'sīkām',
	'sīkās',
	'sīkāt',
	'sīkie',
	'sīkle',
	'sīkos',
	'sīksi',
	'sīkst',
	'sīkšu',
	'sīkta',
	'sīktā',
	'sīkti',
	'sīkto',
	'sīkts',
	'sīktu',
	'sīkus',
	'sīlim',
	'sīlis',
	'sīļos',
	'sīļus',
	'sīvai',
	'sīvam',
	'sīvas',
	'sīvām',
	'sīvās',
	'sīvie',
	'sīvos',
	'sīvus',
	'sjana',
	'sjego',
	'sjēna',
	'sjēnā',
	'sjēnu',
	'sjēra',
	'sjona',
	'sjuņa',
	'skabi',
	'skadi',
	'skafa',
	'skaja',
	'skaka',
	'skala',
	'skalā',
	'skale',
	'skali',
	'skalo',
	'skals',
	'skalu',
	'skaļa',
	'skaļā',
	'skaļi',
	'skaļo',
	'skaļš',
	'skaļu',
	'skani',
	'skans',
	'skanu',
	'skaņa',
	'skaņā',
	'skaņi',
	'skaņo',
	'skaņš',
	'skaņu',
	'skapē',
	'skara',
	'skarā',
	'skare',
	'skaro',
	'skars',
	'skart',
	'skaru',
	'skašu',
	'skat.',
	'skata',
	'skatā',
	'skate',
	'skatē',
	'skati',
	'skats',
	'skatu',
	'skaud',
	'skauj',
	'skaus',
	'skaut',
	'skauž',
	'skava',
	'skavā',
	'skavo',
	'skavu',
	'skāba',
	'skābā',
	'skābe',
	'skābē',
	'skābi',
	'skābo',
	'skābs',
	'skābt',
	'skābu',
	'skāde',
	'skādē',
	'skādi',
	'skānē',
	'skāni',
	'skāns',
	'skāņa',
	'skāņā',
	'skāņi',
	'skāņo',
	'skāņs',
	'skāņš',
	'skāņu',
	'skāra',
	'skāre',
	'skārē',
	'skāri',
	'skāru',
	'skāva',
	'skāvi',
	'skāvu',
	'skāžu',
	'skeča',
	'skečā',
	'skeči',
	'skečs',
	'skeču',
	'skegs',
	'skene',
	'skenē',
	'skets',
	'skēna',
	'skice',
	'skicē',
	'skici',
	'skiču',
	'skidz',
	'skifa',
	'skifā',
	'skifi',
	'skifs',
	'skifu',
	'skips',
	'skira',
	'skirā',
	'skiri',
	'skirs',
	'skiru',
	'skita',
	'skitā',
	'skiti',
	'skits',
	'skitu',
	'skoba',
	'skočs',
	'skoda',
	'skoki',
	'skoks',
	'skola',
	'skolā',
	'skole',
	'skolo',
	'skols',
	'skolu',
	'skone',
	'skopa',
	'skopā',
	'skopi',
	'skopo',
	'skops',
	'skopu',
	'skora',
	'skors',
	'skosa',
	'skota',
	'skotā',
	'skote',
	'skoti',
	'skots',
	'skotu',
	'skrab',
	'skrap',
	'skrip',
	'skuba',
	'skubā',
	'skubu',
	'skudo',
	'skuja',
	'skujā',
	'skuju',
	'skuki',
	'skuķe',
	'skuķi',
	'skuķu',
	'skuma',
	'skume',
	'skumi',
	'skums',
	'skumt',
	'skumu',
	'skura',
	'skurā',
	'skuru',
	'skuta',
	'skuti',
	'skuva',
	'skuvi',
	'skuvu',
	'skype',
	'skūps',
	'skūsi',
	'skūst',
	'skūšu',
	'skūta',
	'skūtā',
	'skūti',
	'skūto',
	'skūts',
	'skūtu',
	'slaba',
	'slabā',
	'slabi',
	'slabs',
	'slabu',
	'slaka',
	'slakā',
	'slaki',
	'slaku',
	'slama',
	'slamt',
	'slana',
	'slane',
	'slang',
	'slani',
	'slapē',
	'slaps',
	'slapš',
	'slapt',
	'slapu',
	'slate',
	'slauc',
	'slava',
	'slavā',
	'slave',
	'slavē',
	'slavu',
	'slāba',
	'slābā',
	'slābi',
	'slābo',
	'slābs',
	'slābt',
	'slābu',
	'slāja',
	'slāji',
	'slāju',
	'slāna',
	'slānī',
	'slāņo',
	'slāpa',
	'slāpe',
	'slāpē',
	'slāpi',
	'slāps',
	'slāpt',
	'slāpu',
	'slāsi',
	'slāšu',
	'slāta',
	'slātā',
	'slāti',
	'slāto',
	'slāts',
	'slātu',
	'slāva',
	'slāvā',
	'slāvi',
	'slāvs',
	'slāvu',
	'sleja',
	'slejā',
	'slejs',
	'sleju',
	'slemp',
	'slems',
	'slepe',
	'sleps',
	'slepu',
	'slēde',
	'slēdz',
	'slēga',
	'slēgā',
	'slēgi',
	'slēgs',
	'slēgt',
	'slēgu',
	'slēģe',
	'slēģi',
	'slēja',
	'slēji',
	'slēju',
	'slēpa',
	'slēpe',
	'slēpē',
	'slēpi',
	'slēpj',
	'slēpo',
	'slēps',
	'slēpt',
	'slēpu',
	'slida',
	'slidā',
	'slide',
	'slido',
	'slidu',
	'sliec',
	'slien',
	'slies',
	'sliet',
	'slija',
	'sliji',
	'sliju',
	'slikt',
	'slima',
	'slimā',
	'slimi',
	'slimo',
	'slims',
	'slimt',
	'slimu',
	'sling',
	'slink',
	'slipa',
	'slipā',
	'slipe',
	'slipi',
	'slips',
	'slipu',
	'slita',
	'slitā',
	'slite',
	'slits',
	'slitu',
	'sliva',
	'slīce',
	'slīde',
	'slīdē',
	'slīdi',
	'slīdu',
	'slīga',
	'slīgi',
	'slīgs',
	'slīgt',
	'slīgu',
	'slīka',
	'slīki',
	'slīks',
	'slīkt',
	'slīku',
	'slīpa',
	'slīpā',
	'slīpe',
	'slīpē',
	'slīpi',
	'slīpo',
	'slīps',
	'slīpt',
	'slīpu',
	'slīsi',
	'slīšu',
	'slīta',
	'slītā',
	'slīte',
	'slīti',
	'slīto',
	'slīts',
	'slītu',
	'slīžu',
	'sloga',
	'slogā',
	'slogi',
	'slogo',
	'slogs',
	'slogu',
	'sloka',
	'slokā',
	'sloku',
	'slons',
	'slota',
	'slotā',
	'slots',
	'slotu',
	'sluča',
	'slump',
	'slums',
	'slūpe',
	'slūpē',
	'slūpi',
	'slūps',
	'smacē',
	'smaga',
	'smagā',
	'smagi',
	'smago',
	'smags',
	'smagu',
	'smaka',
	'smakā',
	'smaki',
	'smako',
	'smaks',
	'smakt',
	'smaku',
	'smala',
	'smani',
	'smash',
	'smādē',
	'smeju',
	'smeķē',
	'smels',
	'smelt',
	'smeļu',
	'smena',
	'smens',
	'smerš',
	'smeša',
	'smešā',
	'smeši',
	'smešs',
	'smešu',
	'smeta',
	'smēde',
	'smēdē',
	'smēdi',
	'smēja',
	'smēji',
	'smēju',
	'smēķē',
	'smēla',
	'smēli',
	'smēlu',
	'smēra',
	'smērā',
	'smēre',
	'smērē',
	'smēri',
	'smērs',
	'smēru',
	'smēžu',
	'smies',
	'smiet',
	'smiga',
	'smila',
	'smird',
	'smišu',
	'smite',
	'smitē',
	'smiti',
	'smīde',
	'smīlā',
	'smīna',
	'smīnā',
	'smīni',
	'smīns',
	'smīnu',
	'smoga',
	'smogā',
	'smogi',
	'smogs',
	'smogu',
	'smoku',
	'smola',
	'smuka',
	'smukā',
	'smuki',
	'smuko',
	'smuks',
	'smuku',
	'smule',
	'smulē',
	'smuli',
	'smuļu',
	'smuti',
	'smuts',
	'snaps',
	'snaud',
	'snauž',
	'snāja',
	'snāji',
	'snāju',
	'snāsi',
	'snāšu',
	'snāta',
	'snātā',
	'snāte',
	'snāti',
	'snāto',
	'snāts',
	'snātu',
	'snēka',
	'snieg',
	'sniga',
	'snigi',
	'snigs',
	'snigt',
	'snigu',
	'snoba',
	'snobā',
	'snobi',
	'snobs',
	'snobu',
	'snora',
	'snore',
	'snova',
	'snuff',
	'soave',
	'sodām',
	'sodās',
	'sodāt',
	'sodīs',
	'sodīt',
	'sodni',
	'sodos',
	'sodot',
	'sodra',
	'sofia',
	'softs',
	'sogna',
	'soģis',
	'soido',
	'sokas',
	'sokos',
	'sokti',
	'solas',
	'solām',
	'solās',
	'solāt',
	'soldo',
	'solds',
	'solea',
	'soleā',
	'solim',
	'solis',
	'solīs',
	'solīt',
	'solos',
	'solot',
	'soltā',
	'solus',
	'soļos',
	'soļot',
	'soļus',
	'somma',
	'sonča',
	'sonda',
	'sonde',
	'songa',
	'songā',
	'songi',
	'songs',
	'songu',
	'sonna',
	'sonne',
	'sopdu',
	'sopes',
	'sopka',
	'sopkā',
	'sopku',
	'sopra',
	'soras',
	'sorba',
	'sorbā',
	'sorbi',
	'sorbs',
	'sorbu',
	'sorgo',
	'sorrī',
	'sorso',
	'sorte',
	'sorts',
	'soska',
	'sosks',
	'sosna',
	'soste',
	'sotka',
	'sotņa',
	'sotto',
	'soula',
	'souls',
	'sours',
	'spala',
	'spalā',
	'spali',
	'spals',
	'spalu',
	'spaļi',
	'spams',
	'spara',
	'sparā',
	'spare',
	'sparē',
	'spari',
	'spars',
	'sparu',
	'spata',
	'spāģi',
	'spāņi',
	'spāņu',
	'spāre',
	'spārē',
	'spāri',
	'spāru',
	'speca',
	'specā',
	'speci',
	'specs',
	'spect',
	'specu',
	'speķo',
	'spers',
	'spert',
	'speru',
	'spēja',
	'spējā',
	'spēji',
	'spējo',
	'spējš',
	'spēju',
	'spēka',
	'spēkā',
	'spēki',
	'spēks',
	'spēku',
	'spēķi',
	'spēle',
	'spēlē',
	'spēli',
	'spēļu',
	'spēra',
	'spēri',
	'spēru',
	'spēsi',
	'spēšu',
	'spēta',
	'spētā',
	'spēti',
	'spēto',
	'spēts',
	'spētu',
	'spica',
	'spicā',
	'spice',
	'spicē',
	'spici',
	'spico',
	'spics',
	'spicu',
	'spiču',
	'spied',
	'spiež',
	'spiga',
	'spiģu',
	'spika',
	'spiku',
	'spina',
	'spinā',
	'spini',
	'spinn',
	'spins',
	'spinu',
	'spira',
	'spirā',
	'spire',
	'spiru',
	'spīčs',
	'spīda',
	'spīde',
	'spīdē',
	'spīdi',
	'spīds',
	'spīdu',
	'spīgo',
	'spīka',
	'spīķi',
	'spīla',
	'spīle',
	'spīlē',
	'spīli',
	'spīļu',
	'spīre',
	'spīrs',
	'spīšu',
	'spīte',
	'spītē',
	'spīti',
	'spītī',
	'spīts',
	'spīva',
	'spīvā',
	'spīve',
	'spīvē',
	'spīvi',
	'spīvo',
	'spīvs',
	'spīvu',
	'spīžu',
	'spode',
	'spoga',
	'spogā',
	'spogo',
	'spoģi',
	'spoka',
	'spokā',
	'spoki',
	'spoks',
	'spoku',
	'spole',
	'spolē',
	'spoli',
	'spoļu',
	'spora',
	'sporā',
	'spors',
	'sporu',
	'spoža',
	'spožā',
	'spoži',
	'spožo',
	'spožs',
	'spožu',
	'sprēd',
	'sprēž',
	'sprūc',
	'sprūk',
	'spura',
	'spurā',
	'spurc',
	'spure',
	'spurr',
	'spurs',
	'spurt',
	'spuru',
	'srema',
	'sroda',
	'sroks',
	'staba',
	'stabā',
	'stabi',
	'stabs',
	'stabu',
	'stafs',
	'staga',
	'stage',
	'stags',
	'staģe',
	'staģi',
	'staka',
	'staks',
	'staļi',
	'stans',
	'stapa',
	'staps',
	'stapt',
	'stara',
	'starā',
	'stare',
	'stari',
	'staro',
	'starp',
	'stars',
	'start',
	'staru',
	'stasi',
	'staši',
	'stata',
	'statā',
	'state',
	'stati',
	'stato',
	'stats',
	'statu',
	'stava',
	'stābs',
	'stāda',
	'stādā',
	'stādi',
	'stāds',
	'stādu',
	'stāja',
	'stājā',
	'stāji',
	'stāju',
	'stāķi',
	'stārs',
	'stāsi',
	'stāšu',
	'stāta',
	'stātā',
	'stāti',
	'stāto',
	'stāts',
	'stātu',
	'stāva',
	'stāvā',
	'stāvi',
	'stāvo',
	'stāvs',
	'stāvu',
	'stāze',
	'stāzē',
	'stāzi',
	'stāža',
	'stāžā',
	'stāži',
	'stāžs',
	'stāžu',
	'steep',
	'stega',
	'stege',
	'steka',
	'stekā',
	'steki',
	'steks',
	'steku',
	'steķi',
	'stele',
	'stema',
	'stena',
	'stenā',
	'steni',
	'steno',
	'stens',
	'stenu',
	'stepa',
	'stepā',
	'stepe',
	'stepē',
	'stepi',
	'steps',
	'stepu',
	'stera',
	'sterā',
	'steri',
	'sters',
	'steru',
	'steso',
	'stēga',
	'stēks',
	'stēla',
	'stēlā',
	'stēle',
	'stēlē',
	'stēli',
	'stēlu',
	'stēļu',
	'stēna',
	'stiba',
	'stibā',
	'stibī',
	'stibu',
	'stieg',
	'stiep',
	'stiga',
	'stigā',
	'stigi',
	'stigo',
	'stigs',
	'stigt',
	'stigu',
	'stika',
	'stiks',
	'stiķē',
	'stila',
	'stilā',
	'stili',
	'stilo',
	'stils',
	'stilt',
	'stilu',
	'stime',
	'stipa',
	'stipe',
	'stira',
	'stīdz',
	'stīga',
	'stīgā',
	'stīgo',
	'stīgs',
	'stīgt',
	'stīgu',
	'stīna',
	'stīne',
	'stīpa',
	'stīpā',
	'stīpo',
	'stīpu',
	'stīva',
	'stīvā',
	'stīvē',
	'stīvi',
	'stīvo',
	'stīvs',
	'stīvu',
	'stock',
	'stoda',
	'stoka',
	'stoks',
	'stoķi',
	'stola',
	'stoli',
	'stols',
	'stoma',
	'stona',
	'stonā',
	'stons',
	'stopa',
	'stopā',
	'stope',
	'stopi',
	'stops',
	'stopu',
	'stora',
	'store',
	'storē',
	'stori',
	'stors',
	'storu',
	'stoss',
	'stota',
	'stote',
	'stots',
	'straž',
	'streb',
	'stria',
	'stuba',
	'stubs',
	'studē',
	'studi',
	'stuga',
	'stuka',
	'stukā',
	'stuki',
	'stuks',
	'stuku',
	'stuķi',
	'stumj',
	'stums',
	'stumt',
	'stupa',
	'stupā',
	'stupi',
	'stups',
	'stupu',
	'stura',
	'stute',
	'stutē',
	'stūķē',
	'stūķi',
	'stūma',
	'stūmi',
	'stūmu',
	'stūpa',
	'stūpā',
	'stūpu',
	'stūra',
	'stūre',
	'stūrē',
	'stūri',
	'stūrs',
	'stūru',
	'suber',
	'sucre',
	'suden',
	'sueca',
	'sufle',
	'suflē',
	'sugām',
	'sugus',
	'suhrs',
	'suita',
	'suitā',
	'suite',
	'suiti',
	'suits',
	'suitu',
	'sujet',
	'sukas',
	'sukās',
	'sukāt',
	'sukna',
	'sukņa',
	'sukre',
	'suķis',
	'sulde',
	'sulla',
	'sulos',
	'sulot',
	'suļķē',
	'sumba',
	'summa',
	'summā',
	'summē',
	'summu',
	'sumpa',
	'sundi',
	'sunīs',
	'sunīt',
	'sunji',
	'sunka',
	'sunna',
	'sunnā',
	'sunnu',
	'sunža',
	'suņus',
	'suono',
	'super',
	'supes',
	'supēē',
	'surbo',
	'surči',
	'surts',
	'susēs',
	'susēt',
	'suska',
	'susla',
	'suslā',
	'suslu',
	'susta',
	'sustā',
	'suste',
	'susti',
	'susto',
	'susts',
	'sustu',
	'suška',
	'sušķe',
	'sutām',
	'sutāt',
	'sutis',
	'sutīs',
	'sutka',
	'sutks',
	'sutot',
	'sutra',
	'sutri',
	'sūbēs',
	'sūbēt',
	'sūbri',
	'sūcam',
	'sūcas',
	'sūcat',
	'sūcām',
	'sūcās',
	'sūcāt',
	'sūcis',
	'sūcos',
	'sūcot',
	'sūdzi',
	'sūdzu',
	'sūkas',
	'sūkās',
	'sūkāt',
	'sūknē',
	'sūksi',
	'sūkst',
	'sūkšu',
	'sūkta',
	'sūktā',
	'sūkti',
	'sūkto',
	'sūkts',
	'sūktu',
	'sūķis',
	'sūnas',
	'sūnos',
	'sūnot',
	'sūrai',
	'sūram',
	'sūras',
	'sūrām',
	'sūrās',
	'sūres',
	'sūrie',
	'sūris',
	'sūrja',
	'sūrma',
	'sūrmā',
	'sūrme',
	'sūrmē',
	'sūrmi',
	'sūrmo',
	'sūrms',
	'sūrmu',
	'sūros',
	'sūrst',
	'sūrus',
	'sūsās',
	'sūsta',
	'sūtam',
	'sūtat',
	'sūtām',
	'sūtāt',
	'sūtīs',
	'sūtīt',
	'sūtne',
	'sūtnē',
	'sūtni',
	'sūtņu',
	'sūtot',
	'sūtra',
	'sūzas',
	'svaja',
	'svaki',
	'svami',
	'svani',
	'svaņa',
	'svapa',
	'svara',
	'svarā',
	'svari',
	'svaro',
	'svars',
	'svaru',
	'svate',
	'svazi',
	'svāhā',
	'svece',
	'svecē',
	'sveci',
	'sveča',
	'sveču',
	'sveda',
	'sveic',
	'sveji',
	'sveki',
	'sveķi',
	'sveķo',
	'svela',
	'svelp',
	'svels',
	'svelt',
	'sveļu',
	'svemp',
	'svens',
	'sveru',
	'sveša',
	'svešā',
	'sveši',
	'svešo',
	'svešs',
	'svešu',
	'sveta',
	'sveti',
	'svēbi',
	'svēki',
	'svēla',
	'svēlā',
	'svēle',
	'svēlē',
	'svēli',
	'svēlo',
	'svēls',
	'svēlu',
	'svēļu',
	'svēpē',
	'svēra',
	'svēre',
	'svēri',
	'svērs',
	'svērt',
	'svēru',
	'svēta',
	'svētā',
	'svēte',
	'svēti',
	'svētī',
	'svēto',
	'svēts',
	'svētu',
	'svied',
	'sviež',
	'sviķe',
	'sviķi',
	'sviķo',
	'svila',
	'svile',
	'svili',
	'svilp',
	'svils',
	'svilt',
	'svilu',
	'sviļi',
	'sviļu',
	'svina',
	'svinā',
	'svini',
	'svino',
	'svins',
	'svinu',
	'svira',
	'svirā',
	'sviru',
	'svita',
	'svīda',
	'svīdē',
	'svīdi',
	'svīds',
	'svīdu',
	'svīre',
	'svīrē',
	'svīri',
	'svīru',
	'svīst',
	'svīta',
	'svītā',
	'svīte',
	'svīts',
	'svītu',
	'svoge',
	'šabja',
	'šachs',
	'šahta',
	'šahtā',
	'šahti',
	'šahtu',
	'šaiba',
	'šaids',
	'šaihs',
	'šaika',
	'šaiks',
	'šaima',
	'šairi',
	'šaise',
	'šaize',
	'šakra',
	'šakri',
	'šakti',
	'šaķis',
	'šalca',
	'šalci',
	'šalcu',
	'šaldu',
	'šalis',
	'šalka',
	'šalkā',
	'šalko',
	'šalks',
	'šalkt',
	'šalku',
	'šalle',
	'šallē',
	'šalli',
	'šalļu',
	'šalom',
	'šalte',
	'šalts',
	'šamie',
	'šampo',
	'šance',
	'šanga',
	'šanse',
	'šanti',
	'šapka',
	'šaras',
	'šarfi',
	'šarfs',
	'šaria',
	'šarma',
	'šarmā',
	'šarmi',
	'šarms',
	'šarmu',
	'šarpe',
	'šarpi',
	'šarpī',
	'šarps',
	'šarža',
	'šaržā',
	'šaržē',
	'šarži',
	'šaržs',
	'šaržu',
	'šasta',
	'šaška',
	'šatnē',
	'šatra',
	'šauda',
	'šaudi',
	'šaudu',
	'šauja',
	'šauju',
	'šaukt',
	'šauls',
	'šauļi',
	'šauna',
	'šaura',
	'šaurā',
	'šauri',
	'šauro',
	'šaurs',
	'šauru',
	'šausi',
	'šaust',
	'šaušu',
	'šauta',
	'šautā',
	'šauti',
	'šauto',
	'šauts',
	'šautu',
	'šauva',
	'šavai',
	'šādai',
	'šākji',
	'šālim',
	'šālis',
	'šāļos',
	'šāļus',
	'šāvām',
	'šāvās',
	'šāvāt',
	'šāvis',
	'šāvos',
	'šefot',
	'šefte',
	'šeibe',
	'šeiha',
	'šeihā',
	'šeihi',
	'šeihs',
	'šeihu',
	'šeika',
	'šeikā',
	'šeiki',
	'šeiko',
	'šeiks',
	'šeiku',
	'šeima',
	'šeine',
	'šelda',
	'šelfa',
	'šelfā',
	'šelfi',
	'šelfs',
	'šelfu',
	'šenga',
	'šengā',
	'šengi',
	'šengs',
	'šengu',
	'šenss',
	'šeņši',
	'šeols',
	'šepat',
	'šepka',
	'šepte',
	'šerbs',
	'šerls',
	'šerpa',
	'šerpā',
	'šerpi',
	'šerpo',
	'šerps',
	'šerpu',
	'šerri',
	'šeški',
	'ševde',
	'ševro',
	'šēras',
	'šhara',
	'šiacu',
	'šifra',
	'šifrā',
	'šifrē',
	'šifri',
	'šifrs',
	'šifru',
	'šihta',
	'šihtā',
	'šihtē',
	'šihtu',
	'šiīšu',
	'šiīta',
	'šiītā',
	'šiīte',
	'šiītē',
	'šiīti',
	'šiīts',
	'šiītu',
	'šikai',
	'šikam',
	'šikas',
	'šikām',
	'šikās',
	'šikie',
	'šikli',
	'šikos',
	'šikus',
	'šilka',
	'šilki',
	'šilte',
	'šimla',
	'šimmi',
	'šinēs',
	'šinēt',
	'šingu',
	'šinka',
	'šinna',
	'šinto',
	'šipka',
	'širaa',
	'širaā',
	'širai',
	'širas',
	'širau',
	'širce',
	'širki',
	'širks',
	'širma',
	'šiška',
	'šitas',
	'šiten',
	'šitik',
	'šizim',
	'šizis',
	'šizma',
	'šižos',
	'šižus',
	'šīvis',
	'škipi',
	'škola',
	'škuta',
	'šķaut',
	'šķavā',
	'šķeit',
	'šķels',
	'šķelt',
	'šķeļu',
	'šķēde',
	'šķēla',
	'šķēle',
	'šķēlē',
	'šķēli',
	'šķēlu',
	'šķēļu',
	'šķēpa',
	'šķēpā',
	'šķēpi',
	'šķēps',
	'šķēpu',
	'šķērd',
	'šķēre',
	'šķērē',
	'šķēri',
	'šķērs',
	'šķērž',
	'šķibe',
	'šķibī',
	'šķibu',
	'šķieb',
	'šķied',
	'šķiet',
	'šķiež',
	'šķila',
	'šķilā',
	'šķils',
	'šķilt',
	'šķilu',
	'šķiļu',
	'šķina',
	'šķind',
	'šķini',
	'šķinu',
	'šķipi',
	'šķira',
	'šķirā',
	'šķiro',
	'šķirs',
	'šķirt',
	'šķiru',
	'šķist',
	'šķita',
	'šķiti',
	'šķitu',
	'šķība',
	'šķībā',
	'šķībi',
	'šķībo',
	'šķībs',
	'šķību',
	'šķīda',
	'šķīdi',
	'šķīdu',
	'šķīla',
	'šķīli',
	'šķīlu',
	'šķīra',
	'šķīri',
	'šķīru',
	'šķīsi',
	'šķīst',
	'šķīšu',
	'šķīta',
	'šķītā',
	'šķīti',
	'šķīto',
	'šķīts',
	'šķītu',
	'šķīva',
	'šķīvo',
	'šķoba',
	'šķobi',
	'šķobu',
	'šķovē',
	'šķute',
	'šķūre',
	'šķūrē',
	'šķūri',
	'šķūru',
	'šķūtē',
	'šlaga',
	'šlagā',
	'šlagu',
	'šlaka',
	'šlems',
	'šlepe',
	'šlepē',
	'šlepi',
	'šlice',
	'šlifa',
	'šlifā',
	'šlifi',
	'šlifs',
	'šlifu',
	'šliha',
	'šlihā',
	'šlihi',
	'šlihs',
	'šlihu',
	'šlips',
	'šloka',
	'šluss',
	'šļaka',
	'šļakā',
	'šļaku',
	'šļapa',
	'šļaup',
	'šļāca',
	'šļāce',
	'šļācē',
	'šļāci',
	'šļācu',
	'šļāču',
	'šļāks',
	'šļākt',
	'šļice',
	'šļoka',
	'šļuha',
	'šļuka',
	'šļukā',
	'šļuki',
	'šļuks',
	'šļukt',
	'šļuku',
	'šļura',
	'šļute',
	'šļūca',
	'šļūce',
	'šļūcē',
	'šļūci',
	'šļūcu',
	'šļūču',
	'šļūde',
	'šļūdi',
	'šļūdu',
	'šļūka',
	'šļūkā',
	'šļūks',
	'šļūkt',
	'šļūku',
	'šmagi',
	'šmara',
	'šmati',
	'šmats',
	'šmauc',
	'šmiga',
	'šmigu',
	'šmita',
	'šmīga',
	'šmīgā',
	'šmīgo',
	'šmīgu',
	'šmola',
	'šmorē',
	'šmuce',
	'šmuks',
	'šmule',
	'šmulē',
	'šmuli',
	'šmuļu',
	'šnabo',
	'šneka',
	'šnekā',
	'šneki',
	'šneks',
	'šneku',
	'šnepe',
	'šnore',
	'šnorē',
	'šnori',
	'šnoru',
	'šnuka',
	'šņabo',
	'šņaga',
	'šņakā',
	'šņaks',
	'šņakt',
	'šņaku',
	'šņauc',
	'šņāca',
	'šņāci',
	'šņācu',
	'šņāka',
	'šņākā',
	'šņāko',
	'šņāks',
	'šņākt',
	'šņāku',
	'šņāpa',
	'šņāpā',
	'šņāpi',
	'šņāpj',
	'šņāps',
	'šņāpt',
	'šņāpu',
	'šņiks',
	'šņikt',
	'šņiku',
	'šņīpa',
	'šņīpā',
	'šņīpu',
	'šņore',
	'šņorē',
	'šņori',
	'šņoru',
	'šņuka',
	'šogad',
	'šokēs',
	'šokēt',
	'šolks',
	'šolta',
	'šorīt',
	'šorti',
	'šorts',
	'šosēs',
	'šosēt',
	'šotte',
	'špaga',
	'špagā',
	'špagu',
	'špaks',
	'špala',
	'špana',
	'špata',
	'špatā',
	'špate',
	'špati',
	'špats',
	'špatu',
	'špāse',
	'špica',
	'špicā',
	'špicē',
	'špici',
	'špics',
	'špicu',
	'špiga',
	'špika',
	'špikā',
	'špiki',
	'špiko',
	'špiks',
	'špiku',
	'špīle',
	'špīrs',
	'špīsa',
	'špīss',
	'špoģi',
	'špoks',
	'špuka',
	'špuks',
	'špukt',
	'špura',
	'špure',
	'špūre',
	'šrafē',
	'šrama',
	'šrāga',
	'šrota',
	'šrotā',
	'šrote',
	'šroti',
	'šrots',
	'šrotu',
	'šruti',
	'štaga',
	'štagā',
	'štagu',
	'štara',
	'štata',
	'štatā',
	'štati',
	'štats',
	'štatu',
	'štāba',
	'štābā',
	'štābi',
	'štābs',
	'štābu',
	'štāde',
	'štāte',
	'štāts',
	'štiha',
	'štihā',
	'štihi',
	'štihs',
	'štihu',
	'štiks',
	'štīmē',
	'štohs',
	'štoka',
	'štoks',
	'štora',
	'štorā',
	'štoru',
	'štuka',
	'štuks',
	'štuma',
	'šudin',
	'šujam',
	'šujas',
	'šujat',
	'šujos',
	'šujot',
	'šulle',
	'šunta',
	'šuntā',
	'šuntē',
	'šunti',
	'šunts',
	'šuntu',
	'šurai',
	'šurca',
	'šurfa',
	'šurfā',
	'šurfē',
	'šurfi',
	'šurfs',
	'šurfu',
	'šurpu',
	'šusta',
	'šusti',
	'šusts',
	'šutas',
	'šutka',
	'šuvām',
	'šuvās',
	'šuvāt',
	'šuvis',
	'šuvos',
	'šūnas',
	'šūpaa',
	'šūpaā',
	'šūpai',
	'šūpas',
	'šūpau',
	'šūpos',
	'šūpot',
	'šūsim',
	'šūsit',
	'šūšos',
	'šūšot',
	'šūtai',
	'šūtam',
	'šūtas',
	'šūtām',
	'šūtās',
	'šūtie',
	'šūtos',
	'šūtus',
	'švaca',
	'švaka',
	'švaki',
	'švaks',
	'švaku',
	'švats',
	'švābi',
	'švābu',
	'švāna',
	'švete',
	'švika',
	'šviks',
	'švira',
	'švīce',
	'švīga',
	'švīgā',
	'švīgu',
	'švīka',
	'švīkā',
	'švīko',
	'švīks',
	'švīku',
	'švīta',
	'švītā',
	'švīti',
	'švīts',
	'švītu',
	'tabes',
	'tabla',
	'tablo',
	'tabor',
	'tacca',
	'tacet',
	'tacim',
	'tacis',
	'tačka',
	'tačkā',
	'tačku',
	'tačos',
	'tačus',
	'tadai',
	'tadža',
	'tadžs',
	'taels',
	'tafta',
	'taftā',
	'tafti',
	'tafts',
	'taftu',
	'tagad',
	'tagan',
	'tagat',
	'tahta',
	'tahtā',
	'tahtu',
	'taids',
	'taiga',
	'taigā',
	'taigu',
	'taihu',
	'taiko',
	'taims',
	'taina',
	'taipa',
	'taira',
	'tairī',
	'taisa',
	'taisi',
	'taisu',
	'taiti',
	'taiza',
	'takna',
	'takot',
	'taksa',
	'takse',
	'taksē',
	'takši',
	'takšķ',
	'takšu',
	'taktē',
	'takti',
	'taktī',
	'takts',
	'taktu',
	'talca',
	'talja',
	'talka',
	'talkā',
	'talki',
	'talko',
	'talks',
	'talku',
	'tallo',
	'talma',
	'talmā',
	'talmu',
	'talon',
	'talot',
	'talpa',
	'talsa',
	'talsi',
	'taļja',
	'taļķe',
	'taļļa',
	'taļļā',
	'taļļu',
	'tambo',
	'tamēr',
	'tamga',
	'tampa',
	'tamps',
	'tamsa',
	'tanga',
	'tango',
	'tangs',
	'tangu',
	'tanha',
	'tanis',
	'tanka',
	'tankā',
	'tanki',
	'tanks',
	'tankš',
	'tanku',
	'tanna',
	'tanšu',
	'tanta',
	'tante',
	'tantē',
	'tanti',
	'tanto',
	'tapas',
	'tapat',
	'tapām',
	'tapāt',
	'tapis',
	'tapos',
	'tapot',
	'tapsē',
	'tapsi',
	'tapšu',
	'tapta',
	'taptā',
	'tapti',
	'tapto',
	'tapts',
	'taptu',
	'tarās',
	'tarāt',
	'tarba',
	'tarbā',
	'tarbe',
	'tarbu',
	'tardo',
	'tarēs',
	'tarēt',
	'targa',
	'taric',
	'tarka',
	'tarkš',
	'tarma',
	'tarna',
	'taros',
	'tarot',
	'tarpa',
	'tarte',
	'tartu',
	'tarza',
	'taska',
	'tasks',
	'tasmi',
	'taste',
	'tasto',
	'taška',
	'tašķī',
	'tatri',
	'taucē',
	'tauha',
	'tauja',
	'taujā',
	'tauju',
	'tauka',
	'taukā',
	'tauki',
	'tauko',
	'tauks',
	'taukš',
	'tauku',
	'tauns',
	'taupa',
	'taupi',
	'taupo',
	'taupu',
	'taura',
	'taurā',
	'taure',
	'taurē',
	'tauri',
	'taurs',
	'tauru',
	'tauta',
	'tautā',
	'tautu',
	'tauva',
	'tauvā',
	'tauvo',
	'tauvu',
	'tādai',
	'tādēļ',
	'tādin',
	'tālab',
	'tālai',
	'tālam',
	'tālas',
	'tālāk',
	'tālām',
	'tālās',
	'tālie',
	'tālne',
	'tālos',
	'tālus',
	'tāļai',
	'tāļam',
	'tāļas',
	'tāļāk',
	'tāļām',
	'tāļās',
	'tāļie',
	'tāļos',
	'tāļus',
	'tāmēs',
	'tāmēt',
	'tāpat',
	'tāpēc',
	'tārpa',
	'tārpā',
	'tārpi',
	'tārps',
	'tārpu',
	'tārss',
	'tāsis',
	'tātad',
	'tčeva',
	'tebra',
	'tecēs',
	'tecēt',
	'tedis',
	'tehno',
	'teibe',
	'teica',
	'teice',
	'teici',
	'teicu',
	'teiči',
	'teide',
	'teija',
	'teika',
	'teikā',
	'teiks',
	'teikt',
	'teiku',
	'teiļi',
	'teini',
	'teipa',
	'teipā',
	'teipi',
	'teips',
	'teipu',
	'teīna',
	'teīnā',
	'teīni',
	'teīns',
	'teīnu',
	'tekam',
	'tekat',
	'tekās',
	'tekāt',
	'tekme',
	'tekne',
	'teknē',
	'tekni',
	'tekņu',
	'tekot',
	'teksa',
	'teksā',
	'teksi',
	'tekss',
	'teksu',
	'teķis',
	'telča',
	'telde',
	'telis',
	'telka',
	'tella',
	'telos',
	'telpa',
	'telpā',
	'telpu',
	'telši',
	'telšu',
	'telti',
	'teltī',
	'telts',
	'tembe',
	'temme',
	'tempa',
	'tempā',
	'tempe',
	'tempi',
	'tempj',
	'tempo',
	'temps',
	'tempt',
	'tempu',
	'temza',
	'tendē',
	'tendo',
	'tenge',
	'tenho',
	'tenis',
	'tenka',
	'tenkā',
	'tenko',
	'tenku',
	'tenno',
	'tenta',
	'tentā',
	'tenti',
	'tents',
	'tentu',
	'tenue',
	'tepat',
	'tepēs',
	'tepēt',
	'tepla',
	'terca',
	'tercā',
	'tercu',
	'terēt',
	'terga',
	'terma',
	'termā',
	'terme',
	'termi',
	'terms',
	'termu',
	'terni',
	'terra',
	'terva',
	'terve',
	'terza',
	'tesām',
	'tesāt',
	'tesis',
	'tesīs',
	'tesla',
	'teslā',
	'teslu',
	'testa',
	'testā',
	'teste',
	'testē',
	'testi',
	'testo',
	'tests',
	'testu',
	'tešam',
	'tešat',
	'tešot',
	'tetri',
	'tevim',
	'tēbas',
	'tējot',
	'tējus',
	'tēlos',
	'tēlot',
	'tēmai',
	'tēmas',
	'tēmām',
	'tēmās',
	'tēmēs',
	'tēmēt',
	'tērce',
	'tērcē',
	'tērci',
	'tērču',
	'tērēs',
	'tērēt',
	'tērgā',
	'tērgo',
	'tērka',
	'tērpa',
	'tērpā',
	'tērpi',
	'tērpj',
	'tērps',
	'tērpt',
	'tērpu',
	'tērze',
	'tērzē',
	'tēsām',
	'tēsāt',
	'tēsis',
	'tēsīs',
	'tēsta',
	'tēstā',
	'tēsti',
	'tēsto',
	'tēsts',
	'tēstu',
	'tēšam',
	'tēšat',
	'tēšot',
	'tētis',
	'tētus',
	'tēvis',
	'thāne',
	'theni',
	'theos',
	'thēta',
	'thuja',
	'tiāra',
	'tiārā',
	'tiāru',
	'tibia',
	'tibra',
	'ticam',
	'ticas',
	'ticat',
	'ticēs',
	'ticēt',
	'ticim',
	'ticis',
	'ticos',
	'ticot',
	'tičos',
	'tičus',
	'tiece',
	'tiecē',
	'tieci',
	'tieču',
	'tieka',
	'tiekt',
	'tieku',
	'tiela',
	'tiels',
	'tiene',
	'tiens',
	'tiepa',
	'tiepi',
	'tiepj',
	'tieps',
	'tiept',
	'tiepu',
	'tiere',
	'tiesa',
	'tiesā',
	'tiesi',
	'tiest',
	'tiesu',
	'tieša',
	'tiešā',
	'tieši',
	'tiešo',
	'tiešs',
	'tiešu',
	'tiete',
	'tieva',
	'tievā',
	'tievi',
	'tievo',
	'tievs',
	'tievu',
	'tigas',
	'tigra',
	'tigri',
	'tigrs',
	'tikai',
	'tikam',
	'tikas',
	'tikām',
	'tikās',
	'tikāt',
	'tikko',
	'tikla',
	'tikli',
	'tikls',
	'tikmē',
	'tikos',
	'tiksi',
	'tikši',
	'tikšķ',
	'tikšu',
	'tikta',
	'tiktā',
	'tikti',
	'tikto',
	'tikts',
	'tiktu',
	'tikus',
	'tikva',
	'tilbe',
	'tilde',
	'tildē',
	'tildi',
	'tilia',
	'tilka',
	'tilla',
	'tillā',
	'tilli',
	'tills',
	'tillu',
	'tilpa',
	'tilpe',
	'tilpē',
	'tilpi',
	'tilps',
	'tilpt',
	'tilpu',
	'tilta',
	'tiltā',
	'tilti',
	'tilts',
	'tiltu',
	'tilža',
	'tilžu',
	'timba',
	'timma',
	'timpa',
	'timpu',
	'timsa',
	'timsi',
	'timss',
	'timst',
	'timšs',
	'tinam',
	'tinas',
	'tinat',
	'tinām',
	'tinās',
	'tināt',
	'tinca',
	'tinda',
	'tinea',
	'tinēt',
	'tings',
	'tinim',
	'tinis',
	'tinkš',
	'tinos',
	'tinot',
	'tinšu',
	'tinte',
	'tintē',
	'tinti',
	'tinto',
	'tiņas',
	'tiņos',
	'tiņus',
	'tione',
	'tipat',
	'tirdī',
	'tirgo',
	'tirgs',
	'tirka',
	'tirkā',
	'tirki',
	'tirks',
	'tirkš',
	'tirku',
	'tiros',
	'tirpa',
	'tirpā',
	'tirpi',
	'tirps',
	'tirpt',
	'tirpu',
	'tirsa',
	'tirst',
	'tirza',
	'tirzā',
	'tišri',
	'titis',
	'titra',
	'titrā',
	'titrē',
	'titri',
	'titrs',
	'titru',
	'tivok',
	'tizli',
	'tizls',
	'tīdes',
	'tīgas',
	'tīkas',
	'tīkla',
	'tīklā',
	'tīkli',
	'tīklo',
	'tīkls',
	'tīklu',
	'tīkos',
	'tīkot',
	'tīmus',
	'tīnim',
	'tīnis',
	'tīņās',
	'tīņāt',
	'tīņos',
	'tīņus',
	'tīrai',
	'tīram',
	'tīras',
	'tīrām',
	'tīrās',
	'tīrāt',
	'tīrie',
	'tīrīs',
	'tīrīt',
	'tīros',
	'tīrot',
	'tīrus',
	'tīsim',
	'tīsit',
	'tīsta',
	'tīsti',
	'tīstu',
	'tīšai',
	'tīšam',
	'tīšas',
	'tīšām',
	'tīšās',
	'tīšie',
	'tīšos',
	'tīšot',
	'tīšus',
	'tītai',
	'tītam',
	'tītas',
	'tītām',
	'tītās',
	'tītie',
	'tītos',
	'tītus',
	'tjaņs',
	'tjāga',
	'tjete',
	'tjipa',
	'tjūma',
	'tobis',
	'tobiš',
	'točka',
	'točna',
	'točno',
	'todus',
	'toefl',
	'togad',
	'tokai',
	'token',
	'tolai',
	'tolam',
	'tolas',
	'tolām',
	'tolās',
	'tolie',
	'tolim',
	'tolis',
	'tolka',
	'tolki',
	'tolks',
	'tolna',
	'tolos',
	'tolus',
	'toļos',
	'toļus',
	'tomba',
	'tomēr',
	'tomēt',
	'tomka',
	'tompa',
	'tondo',
	'tonēs',
	'tonēt',
	'tonga',
	'tongi',
	'tongs',
	'tonim',
	'tonis',
	'tonja',
	'tonna',
	'tonnā',
	'tonne',
	'tonnu',
	'tonus',
	'toņos',
	'toņus',
	'toona',
	'topam',
	'topat',
	'topic',
	'topka',
	'topot',
	'topus',
	'torba',
	'torbi',
	'torfs',
	'torio',
	'torīt',
	'torka',
	'torki',
	'torna',
	'torne',
	'torni',
	'tornī',
	'torns',
	'torņa',
	'torņi',
	'torņu',
	'torpa',
	'torps',
	'torre',
	'torri',
	'torsa',
	'torsā',
	'torsi',
	'torss',
	'torsu',
	'toršu',
	'torte',
	'tortē',
	'torti',
	'torts',
	'tosam',
	'tosat',
	'tosām',
	'tosāt',
	'tosis',
	'tosīs',
	'tosja',
	'tosno',
	'tosot',
	'tosta',
	'tostā',
	'tosti',
	'tosto',
	'tosts',
	'tostu',
	'totot',
	'touja',
	'tožas',
	'trace',
	'trafs',
	'traka',
	'trakā',
	'traki',
	'trakī',
	'trako',
	'traks',
	'trakt',
	'traku',
	'traķi',
	'tralē',
	'tralī',
	'trals',
	'trama',
	'tramā',
	'trami',
	'trams',
	'tramš',
	'tramu',
	'trana',
	'tranā',
	'trani',
	'trans',
	'tranu',
	'trapa',
	'trapā',
	'trape',
	'trapi',
	'traps',
	'trapu',
	'trasa',
	'trase',
	'trasē',
	'trasi',
	'trasī',
	'trass',
	'trasu',
	'trata',
	'tratā',
	'trate',
	'tratu',
	'trauc',
	'traus',
	'trauš',
	'trava',
	'trāna',
	'trānā',
	'trāni',
	'trāns',
	'trānu',
	'trāpa',
	'trāpi',
	'trāpu',
	'trebo',
	'trebū',
	'treja',
	'treji',
	'treju',
	'treka',
	'trekā',
	'treki',
	'treks',
	'treku',
	'treme',
	'tremē',
	'tremi',
	'trenc',
	'trenē',
	'trepe',
	'tresa',
	'trese',
	'tresē',
	'tresi',
	'trest',
	'trešs',
	'trešu',
	'trevi',
	'trēma',
	'trēna',
	'trēnā',
	'trēni',
	'trēns',
	'trēnu',
	'trēza',
	'triba',
	'tribā',
	'tribu',
	'trice',
	'tricē',
	'trici',
	'triču',
	'tridu',
	'triec',
	'triep',
	'triji',
	'trika',
	'trikā',
	'triki',
	'triko',
	'triks',
	'triku',
	'trila',
	'trima',
	'trimē',
	'trimo',
	'trims',
	'trina',
	'trine',
	'trini',
	'trinu',
	'tripa',
	'trips',
	'tript',
	'trīce',
	'trīcē',
	'trīci',
	'trīcu',
	'trīču',
	'trīds',
	'trīna',
	'trīne',
	'trīņi',
	'trīre',
	'trīsa',
	'trīsā',
	'trīse',
	'trīsi',
	'trīso',
	'trīss',
	'trīsu',
	'trīšu',
	'trīta',
	'trītā',
	'trīti',
	'trīto',
	'trīts',
	'trītu',
	'trofa',
	'troga',
	'trogā',
	'trogi',
	'trogs',
	'trogu',
	'troja',
	'trojs',
	'troka',
	'troks',
	'trona',
	'trone',
	'trons',
	'tropa',
	'tropā',
	'tropi',
	'trops',
	'tropu',
	'trose',
	'trosē',
	'trosi',
	'tross',
	'trošu',
	'trots',
	'truba',
	'trubā',
	'trubu',
	'truka',
	'truks',
	'truku',
	'trula',
	'trulā',
	'trule',
	'truli',
	'trulo',
	'truls',
	'trulu',
	'truļu',
	'truma',
	'trums',
	'truni',
	'trunu',
	'trupa',
	'trupā',
	'trupe',
	'trupē',
	'trupi',
	'trups',
	'trupt',
	'trupu',
	'trusi',
	'truss',
	'trust',
	'trusu',
	'truša',
	'truše',
	'truši',
	'trušs',
	'trušu',
	'trūba',
	'trūbe',
	'trūce',
	'trūcē',
	'trūci',
	'trūču',
	'trūda',
	'trūdā',
	'trūde',
	'trūdē',
	'trūdi',
	'trūds',
	'trūdu',
	'trūka',
	'trūki',
	'trūks',
	'trūkt',
	'trūku',
	'trūle',
	'trūli',
	'trūpi',
	'trūst',
	'trūžu',
	'tsuga',
	'tuber',
	'tucis',
	'tučno',
	'tufta',
	'tukas',
	'tukla',
	'tuklā',
	'tukli',
	'tuklo',
	'tukls',
	'tuklu',
	'tukša',
	'tukšā',
	'tukši',
	'tukšo',
	'tukšs',
	'tukšu',
	'tukta',
	'tukte',
	'tulas',
	'tulce',
	'tulcē',
	'tulci',
	'tulča',
	'tulči',
	'tulču',
	'tulim',
	'tuliņ',
	'tulis',
	'tulka',
	'tulkā',
	'tulki',
	'tulko',
	'tulks',
	'tulku',
	'tulle',
	'tulpa',
	'tulpe',
	'tulpē',
	'tulpi',
	'tulžu',
	'tuļos',
	'tuļpa',
	'tuļus',
	'tumba',
	'tumbā',
	'tumbu',
	'tumēs',
	'tumēt',
	'tumsa',
	'tumsā',
	'tumsē',
	'tumsi',
	'tumss',
	'tumst',
	'tumsu',
	'tumša',
	'tumšā',
	'tumši',
	'tumšo',
	'tumšs',
	'tumšu',
	'tunga',
	'tungā',
	'tungu',
	'tunis',
	'tunka',
	'tuņņa',
	'tuono',
	'tupam',
	'tupat',
	'tupām',
	'tupās',
	'tupāt',
	'tupēs',
	'tupēt',
	'tupis',
	'tuple',
	'tupos',
	'tupot',
	'tupsa',
	'tupsi',
	'tupst',
	'tupšu',
	'tupta',
	'tuptā',
	'tupti',
	'tupto',
	'tupts',
	'tuptu',
	'tupus',
	'turaa',
	'turaā',
	'turai',
	'turam',
	'turas',
	'turat',
	'turau',
	'turba',
	'turbo',
	'turca',
	'turco',
	'turda',
	'turēs',
	'turēt',
	'turfs',
	'turis',
	'turja',
	'turka',
	'turkā',
	'turki',
	'turks',
	'turku',
	'turma',
	'turna',
	'turne',
	'turnē',
	'turni',
	'turos',
	'turot',
	'turpu',
	'tursi',
	'turza',
	'turzā',
	'turzu',
	'tusēt',
	'tusla',
	'tusni',
	'tusnī',
	'tussi',
	'tusšu',
	'tusta',
	'tustā',
	'tusti',
	'tusto',
	'tusts',
	'tustu',
	'tušam',
	'tušat',
	'tušām',
	'tušāt',
	'tušēs',
	'tušēt',
	'tušis',
	'tušķi',
	'tušot',
	'tutsi',
	'tutta',
	'tutti',
	'tuvai',
	'tuvam',
	'tuvas',
	'tuvāk',
	'tuvām',
	'tuvās',
	'tuvie',
	'tuvos',
	'tuvus',
	'tuzim',
	'tuzis',
	'tuzla',
	'tužos',
	'tužus',
	'tūcām',
	'tūcāt',
	'tūcis',
	'tūcīs',
	'tūcīt',
	'tūcot',
	'tūdaļ',
	'tūjas',
	'tūkas',
	'tūkām',
	'tūkāt',
	'tūksi',
	'tūkst',
	'tūkšu',
	'tūkta',
	'tūktā',
	'tūkti',
	'tūkto',
	'tūkts',
	'tūktu',
	'tūlin',
	'tūliņ',
	'tūlis',
	'tūlīt',
	'tūska',
	'tūskā',
	'tūskt',
	'tūsku',
	'tūzis',
	'tvana',
	'tvanā',
	'tvani',
	'tvano',
	'tvans',
	'tvanu',
	'tvera',
	'tvers',
	'tvert',
	'tveru',
	'tvēra',
	'tvēri',
	'tvēru',
	'tvins',
	'tvīda',
	'tvīdā',
	'tvīdi',
	'tvīds',
	'tvīdu',
	'tvīka',
	'tvīkā',
	'tvīki',
	'tvīks',
	'tvīkt',
	'tvīku',
	'tvīns',
	'tvīta',
	'tvītā',
	'tvīti',
	'tvīto',
	'tvīts',
	'tvītu',
	'ubaga',
	'ubagā',
	'ubagi',
	'ubago',
	'ubags',
	'ubagu',
	'ubari',
	'ubeda',
	'ucacā',
	'ucina',
	'ucini',
	'ucinu',
	'uclaf',
	'učali',
	'učene',
	'učiks',
	'učuks',
	'udaja',
	'udāna',
	'udīne',
	'udžda',
	'ugama',
	'ugāle',
	'ugija',
	'uguni',
	'ugunī',
	'uguns',
	'uguņo',
	'uguņu',
	'uhaku',
	'uhaņa',
	'uikas',
	'ujuni',
	'ukazs',
	'ukini',
	'ulala',
	'ulans',
	'ulāns',
	'ulemi',
	'ulems',
	'ulens',
	'ulete',
	'ulica',
	'uliks',
	'uliss',
	'ullis',
	'ulmus',
	'ulpis',
	'uluru',
	'ulusa',
	'uluss',
	'ulvis',
	'uļita',
	'umaga',
	'umago',
	'umaņa',
	'umaņu',
	'umari',
	'umāra',
	'umbra',
	'umbrā',
	'umbri',
	'umbru',
	'ummas',
	'ummis',
	'unārs',
	'unced',
	'unece',
	'unhcr',
	'unica',
	'unice',
	'uniks',
	'unima',
	'union',
	'unita',
	'unrra',
	'untas',
	'uņeča',
	'upele',
	'upene',
	'upenē',
	'upeni',
	'upeņi',
	'upeņu',
	'upiņi',
	'upīša',
	'upīši',
	'upīte',
	'upolu',
	'upsts',
	'upupa',
	'upurē',
	'upurs',
	'uraja',
	'uraša',
	'urava',
	'uravā',
	'uravo',
	'uraza',
	'urāla',
	'urāli',
	'urāna',
	'urānā',
	'urāni',
	'urāns',
	'urānu',
	'urāta',
	'urātā',
	'urāti',
	'urāts',
	'urātu',
	'urban',
	'urbām',
	'urbās',
	'urbāt',
	'urbim',
	'urbis',
	'urbja',
	'urbji',
	'urbju',
	'urbos',
	'urbsi',
	'urbšu',
	'urbta',
	'urbtā',
	'urbti',
	'urbto',
	'urbts',
	'urbtu',
	'urdam',
	'urdat',
	'urdām',
	'urdās',
	'urdāt',
	'urdēs',
	'urdēt',
	'urdīs',
	'urdīt',
	'urdos',
	'urdot',
	'urdza',
	'urdze',
	'urdzē',
	'urdzi',
	'urdzu',
	'urdžu',
	'urejs',
	'urgas',
	'urgos',
	'urgot',
	'urgsi',
	'urgšu',
	'urgta',
	'urgtā',
	'urgti',
	'urgto',
	'urgts',
	'urgtu',
	'urina',
	'urinē',
	'urini',
	'urinu',
	'urīms',
	'urīna',
	'urīnā',
	'urīni',
	'urīns',
	'urīnu',
	'urkas',
	'urkši',
	'urkšķ',
	'urkšu',
	'urķēs',
	'urķēt',
	'urķis',
	'urrās',
	'urrāt',
	'urska',
	'ursus',
	'urška',
	'uruka',
	'urupa',
	'usimā',
	'usnea',
	'usnes',
	'ustje',
	'ustka',
	'usuri',
	'ušaka',
	'ušaks',
	'ušāni',
	'ušuri',
	'utara',
	'utena',
	'utene',
	'utenē',
	'uteni',
	'uteņu',
	'utoja',
	'utoji',
	'utoju',
	'utosi',
	'utošu',
	'utota',
	'utotā',
	'utoti',
	'utoto',
	'utots',
	'utotu',
	'uvāla',
	'uvālā',
	'uvāli',
	'uvāls',
	'uvālu',
	'uvira',
	'uzaco',
	'uzacs',
	'uzada',
	'uzadi',
	'uzadu',
	'uzara',
	'uzari',
	'uzars',
	'uzart',
	'uzaru',
	'uzaug',
	'uzauj',
	'uzaus',
	'uzaut',
	'uzāķē',
	'uzāva',
	'uzāvi',
	'uzāvu',
	'uzbāz',
	'uzbāž',
	'uzber',
	'uzbēg',
	'uzboz',
	'uzbož',
	'uzbur',
	'uzcel',
	'uzceļ',
	'uzcep',
	'uzcer',
	'uzdod',
	'uzdos',
	'uzdot',
	'uzdur',
	'uzecē',
	'uzeja',
	'uzejā',
	'uzeju',
	'uzēda',
	'uzēdi',
	'uzēdu',
	'uzēst',
	'uzgāz',
	'uzgāž',
	'uzies',
	'uziet',
	'uzina',
	'uzirt',
	'uzjāj',
	'uzjās',
	'uzjāt',
	'uzjoz',
	'uzjož',
	'uzjum',
	'uzkal',
	'uzkaļ',
	'uzkar',
	'uzkāp',
	'uzkod',
	'uzkop',
	'uzkož',
	'uzkul',
	'uzkuļ',
	'uzkur',
	'uzķer',
	'uzlec',
	'uzlej',
	'uzlīp',
	'uzlīs',
	'uzlīt',
	'uzloc',
	'uzlok',
	'uzmal',
	'uzmaļ',
	'uzmāc',
	'uzmet',
	'uzmēz',
	'uzmēž',
	'uzmin',
	'uzmīs',
	'uzmīt',
	'uzmīz',
	'uzmīž',
	'uznāc',
	'uznāk',
	'uznes',
	'uzņem',
	'uzoda',
	'uzodi',
	'uzodu',
	'uzost',
	'uzotē',
	'uzožu',
	'uzpil',
	'uzpin',
	'uzpis',
	'uzpiš',
	'uzpīs',
	'uzpīt',
	'uzpos',
	'uzpoš',
	'uzput',
	'uzpūš',
	'uzpūt',
	'uzrāt',
	'uzrej',
	'uzrēc',
	'uzrit',
	'uzrīt',
	'uzroc',
	'uzrok',
	'uzrūc',
	'uzsāc',
	'uzsāk',
	'uzsēj',
	'uzsēs',
	'uzsēt',
	'uzsit',
	'uzsūc',
	'uzšuj',
	'uzšūs',
	'uzšūt',
	'uztek',
	'uztic',
	'uztin',
	'uztīs',
	'uztīt',
	'uztop',
	'uztur',
	'uzurb',
	'uzusa',
	'uzusā',
	'uzusi',
	'uzuss',
	'uzusu',
	'uzūra',
	'uzvar',
	'uzvāc',
	'uzvāz',
	'uzvāž',
	'uzved',
	'uzvel',
	'uzveļ',
	'uzver',
	'uzvij',
	'uzviz',
	'uzvīs',
	'uzvīt',
	'uzzel',
	'uzzeļ',
	'uzzib',
	'užava',
	'užice',
	'užuņu',
	'ūbele',
	'ūbelē',
	'ūbeli',
	'ūbels',
	'ūbeļu',
	'ūboja',
	'ūboji',
	'ūboju',
	'ūbosi',
	'ūbošu',
	'ūbota',
	'ūbotā',
	'ūboti',
	'ūboto',
	'ūbots',
	'ūbotu',
	'ūdele',
	'ūdelē',
	'ūdeli',
	'ūdeļu',
	'ūdeni',
	'ūdenī',
	'ūdens',
	'ūdeņi',
	'ūdeņu',
	'ūdris',
	'ūjina',
	'ūjini',
	'ūjinu',
	'ūksts',
	'ūkšam',
	'ūkšat',
	'ūkšēs',
	'ūkšēt',
	'ūkšķi',
	'ūkšķu',
	'ūkšot',
	'ūlasi',
	'ūlupi',
	'ūnija',
	'ūnijā',
	'ūniju',
	'ūpēns',
	'ūrija',
	'ūsāni',
	'ūsiņi',
	'ūsiņš',
	'ūsova',
	'ūvina',
	'ūvini',
	'ūvinu',
	'ūvula',
	'ūvulā',
	'ūvulu',
	'ūziņa',
	'ūziņā',
	'ūziņi',
	'ūziņš',
	'ūziņu',
	'ūzuls',
	'ūzuss',
	'vacis',
	'vadām',
	'vadās',
	'vadāt',
	'vadīs',
	'vadīt',
	'vados',
	'vadot',
	'vadse',
	'vadus',
	'vadzi',
	'vadži',
	'vagos',
	'vagot',
	'vagri',
	'vahša',
	'vaica',
	'vaicā',
	'vaida',
	'vaidā',
	'vaide',
	'vaidi',
	'vaids',
	'vaidu',
	'vaiga',
	'vaigā',
	'vaigi',
	'vaigs',
	'vaigu',
	'vaija',
	'vaiju',
	'vaiks',
	'vaile',
	'vaina',
	'vainā',
	'vaine',
	'vaino',
	'vainu',
	'vaiņa',
	'vaipa',
	'vaira',
	'vairā',
	'vaire',
	'vairi',
	'vairo',
	'vairs',
	'vairu',
	'vaiss',
	'vaita',
	'vaive',
	'vajag',
	'vajās',
	'vajāt',
	'vakar',
	'vakra',
	'vaksa',
	'vakša',
	'vakšu',
	'vakti',
	'vaktī',
	'vakts',
	'vaķis',
	'valba',
	'valbi',
	'valbu',
	'valce',
	'valcē',
	'valča',
	'valda',
	'valde',
	'valdē',
	'valdi',
	'valds',
	'valdu',
	'valga',
	'valgā',
	'valgi',
	'valgo',
	'valgs',
	'valgu',
	'valim',
	'valis',
	'valja',
	'valka',
	'valkā',
	'valki',
	'valks',
	'valku',
	'valla',
	'valle',
	'vallo',
	'valls',
	'valna',
	'valns',
	'valsa',
	'valsē',
	'value',
	'valžu',
	'vaļči',
	'vaļņi',
	'vaļos',
	'vaļsa',
	'vaļus',
	'vamba',
	'vamps',
	'vamze',
	'vamži',
	'vanči',
	'vanda',
	'vandi',
	'vandī',
	'vandu',
	'vanga',
	'vangs',
	'vanka',
	'vanks',
	'vankš',
	'vanna',
	'vannā',
	'vanne',
	'vanno',
	'vannu',
	'vanšu',
	'vanta',
	'vantā',
	'vanti',
	'vantī',
	'vants',
	'vanua',
	'vaņģo',
	'vaņīt',
	'vaņka',
	'vaņki',
	'vaņķi',
	'varam',
	'varas',
	'varat',
	'varāt',
	'varda',
	'varde',
	'vardē',
	'vardi',
	'varen',
	'vares',
	'varēm',
	'varēs',
	'varēt',
	'varga',
	'vargs',
	'varia',
	'variē',
	'varig',
	'varis',
	'varīt',
	'varka',
	'varķe',
	'varla',
	'varna',
	'varņi',
	'varot',
	'varsi',
	'varša',
	'varta',
	'varto',
	'varve',
	'varza',
	'varzā',
	'varzi',
	'varzu',
	'varža',
	'varžu',
	'vasar',
	'vaska',
	'vaskā',
	'vaski',
	'vasko',
	'vasks',
	'vasku',
	'vasto',
	'vaška',
	'vatēs',
	'vatēt',
	'vatra',
	'vatts',
	'vaukš',
	'vavra',
	'vazās',
	'vazāt',
	'vazot',
	'važas',
	'važos',
	'važot',
	'vācam',
	'vācas',
	'vācat',
	'vācām',
	'vācās',
	'vācāt',
	'vācis',
	'vācos',
	'vācot',
	'vādīt',
	'vāģis',
	'vājai',
	'vājam',
	'vājas',
	'vājām',
	'vājās',
	'vājāt',
	'vājēs',
	'vājēt',
	'vājie',
	'vājos',
	'vājot',
	'vājus',
	'vākas',
	'vākos',
	'vākot',
	'vāksi',
	'vākst',
	'vākšu',
	'vākta',
	'vāktā',
	'vākti',
	'vākto',
	'vākts',
	'vāktu',
	'vāķēs',
	'vāķēt',
	'vāķis',
	'vāķīt',
	'vālēs',
	'vālēt',
	'vālos',
	'vālot',
	'vālsa',
	'vāļās',
	'vāļāt',
	'vāļis',
	'vāpēs',
	'vāpēt',
	'vārai',
	'vāram',
	'vāras',
	'vārām',
	'vārās',
	'vārāt',
	'vārda',
	'vārdā',
	'vārde',
	'vārdi',
	'vārdo',
	'vārds',
	'vārdu',
	'vārēs',
	'vārēt',
	'vārga',
	'vārgā',
	'vārgi',
	'vārgo',
	'vārgs',
	'vārgt',
	'vārgu',
	'vārie',
	'vārīs',
	'vārīt',
	'vārme',
	'vārna',
	'vārnā',
	'vārne',
	'vārns',
	'vārnu',
	'vāros',
	'vārot',
	'vārpa',
	'vārpā',
	'vārpo',
	'vārps',
	'vārpu',
	'vārst',
	'vārta',
	'vārti',
	'vārts',
	'vārtu',
	'vārus',
	'vārve',
	'vātis',
	'vāzām',
	'vāzās',
	'vāzāt',
	'vāzis',
	'vāzīs',
	'vāzos',
	'vāzta',
	'vāztā',
	'vāzti',
	'vāzto',
	'vāzts',
	'vāztu',
	'vāžam',
	'vāžas',
	'vāžat',
	'vāžos',
	'vāžot',
	'vbtac',
	'vdeāk',
	'vecai',
	'vecam',
	'vecas',
	'vecām',
	'vecās',
	'vecēs',
	'vecēt',
	'vecie',
	'vecim',
	'vecis',
	'vecos',
	'vecot',
	'vecum',
	'vecus',
	'večos',
	'večus',
	'vedam',
	'vedas',
	'vedat',
	'vedām',
	'vedās',
	'vedāt',
	'vedga',
	'vedgā',
	'vedgu',
	'vedis',
	'vedīs',
	'vedos',
	'vedot',
	'vegda',
	'vegra',
	'veģis',
	'veģma',
	'vehma',
	'veica',
	'veice',
	'veici',
	'veicu',
	'veida',
	'veidā',
	'veidi',
	'veido',
	'veids',
	'veidu',
	'veigs',
	'veika',
	'veiki',
	'veiko',
	'veiks',
	'veikt',
	'veila',
	'veina',
	'veipi',
	'veirs',
	'veisa',
	'veiši',
	'vejle',
	'veksa',
	'vekše',
	'veķis',
	'velce',
	'velcē',
	'velci',
	'velču',
	'velde',
	'velds',
	'veldz',
	'velēs',
	'velēt',
	'velga',
	'velgā',
	'velgi',
	'velgo',
	'velgs',
	'velgt',
	'velgu',
	'velik',
	'velim',
	'velis',
	'velje',
	'velka',
	'velkā',
	'velke',
	'velki',
	'velks',
	'velku',
	'vella',
	'vellā',
	'velli',
	'vells',
	'vellu',
	'velme',
	'velmē',
	'velmi',
	'velna',
	'velnā',
	'velni',
	'velns',
	'velnu',
	'velpa',
	'velsa',
	'velsi',
	'velša',
	'velšu',
	'velta',
	'veltā',
	'velte',
	'veltē',
	'velti',
	'veltī',
	'velto',
	'velts',
	'veltu',
	'velva',
	'velve',
	'velvē',
	'velvi',
	'veļam',
	'veļas',
	'veļat',
	'veļke',
	'veļki',
	'veļķi',
	'veļos',
	'veļot',
	'veļus',
	'vemas',
	'vemba',
	'vemju',
	'vemsi',
	'vemšu',
	'vemta',
	'vemtā',
	'vemti',
	'vemto',
	'vemts',
	'vemtu',
	'venda',
	'vendā',
	'vendi',
	'vends',
	'vendu',
	'venga',
	'venge',
	'venlo',
	'venta',
	'vente',
	'venza',
	'vepri',
	'vepsa',
	'vepsā',
	'vepsi',
	'vepss',
	'vepsu',
	'veram',
	'veras',
	'verat',
	'verba',
	'verbā',
	'verbi',
	'verbs',
	'verbu',
	'verde',
	'verdi',
	'verga',
	'vergā',
	'vergi',
	'vergo',
	'vergs',
	'vergu',
	'verkš',
	'verle',
	'verne',
	'verns',
	'verņa',
	'veros',
	'verot',
	'verpa',
	'verps',
	'verst',
	'verte',
	'verti',
	'verve',
	'vervē',
	'verza',
	'veržē',
	'veska',
	'veslo',
	'vesna',
	'vespa',
	'vesta',
	'vestā',
	'veste',
	'vesti',
	'vesto',
	'vests',
	'vestu',
	'vešja',
	'vetēt',
	'vetka',
	'vevei',
	'vēdas',
	'vēdīs',
	'vēdīt',
	'vēgas',
	'vējam',
	'vējos',
	'vējot',
	'vēkši',
	'vēkšķ',
	'vēkšu',
	'vēlai',
	'vēlam',
	'vēlas',
	'vēlāk',
	'vēlām',
	'vēlās',
	'vēlāt',
	'vēlēs',
	'vēlēt',
	'vēlie',
	'vēlis',
	'vēlme',
	'vēlmē',
	'vēlmi',
	'vēlos',
	'vēlus',
	'vēmaa',
	'vēmaā',
	'vēmai',
	'vēmas',
	'vēmau',
	'vēmām',
	'vēmāt',
	'vēmes',
	'vēmis',
	'vēnām',
	'vēpla',
	'vērām',
	'vērās',
	'vērāt',
	'vērēt',
	'vērgs',
	'vērim',
	'vēris',
	'vēros',
	'vērot',
	'vērpa',
	'vērpe',
	'vērpē',
	'vērpi',
	'vērpj',
	'vērps',
	'vērpt',
	'vērpu',
	'vērsi',
	'vērsī',
	'vērst',
	'vērša',
	'vērši',
	'vēršu',
	'vērta',
	'vērtā',
	'vērte',
	'vērtē',
	'vērti',
	'vērto',
	'vērts',
	'vērtu',
	'vērus',
	'vērza',
	'vēsai',
	'vēsam',
	'vēsas',
	'vēsām',
	'vēsās',
	'vēsie',
	'vēsma',
	'vēsmā',
	'vēsmo',
	'vēsmu',
	'vēsos',
	'vēsta',
	'vēstī',
	'vēsts',
	'vēsus',
	'vētīs',
	'vētīt',
	'vētra',
	'vētrā',
	'vētri',
	'vētru',
	'vēzām',
	'vēzāt',
	'vēzda',
	'vēzes',
	'vēzēs',
	'vēzēt',
	'vēzim',
	'vēzis',
	'vēzīs',
	'vēzta',
	'vēztā',
	'vēzti',
	'vēzto',
	'vēzts',
	'vēztu',
	'vēžam',
	'vēžat',
	'vēžos',
	'vēžot',
	'vēžus',
	'viana',
	'vibra',
	'vibrē',
	'vicas',
	'vicia',
	'vicka',
	'vicos',
	'vicot',
	'video',
	'vidin',
	'vidja',
	'vidos',
	'vidra',
	'vidri',
	'vidua',
	'vidum',
	'vidus',
	'vidži',
	'vidžu',
	'vieba',
	'viebi',
	'viebj',
	'viebs',
	'viebt',
	'viebu',
	'vieda',
	'viedā',
	'viedē',
	'viedi',
	'viedo',
	'vieds',
	'viedu',
	'vieķi',
	'viela',
	'vielā',
	'vielu',
	'vieno',
	'viens',
	'viere',
	'viese',
	'viess',
	'viest',
	'vieša',
	'vieši',
	'viešu',
	'vieta',
	'vietā',
	'viete',
	'vietu',
	'vieve',
	'viežu',
	'vigna',
	'vijam',
	'vijas',
	'vijat',
	'vijām',
	'vijās',
	'vijāt',
	'vijis',
	'vijos',
	'vijot',
	'viksa',
	'vikse',
	'viksē',
	'viksi',
	'vikss',
	'vikšu',
	'vilce',
	'vilcē',
	'vilci',
	'vilču',
	'vilga',
	'vilgā',
	'vilgi',
	'vilgo',
	'vilgs',
	'vilgt',
	'vilgu',
	'viljē',
	'vilka',
	'vilkā',
	'vilki',
	'vilks',
	'vilkt',
	'vilku',
	'villa',
	'villā',
	'villu',
	'vilna',
	'vilnā',
	'vilne',
	'vilnī',
	'vilnu',
	'vilsi',
	'vilšu',
	'vilta',
	'viltā',
	'vilte',
	'vilti',
	'vilto',
	'vilts',
	'viltu',
	'viļam',
	'viļas',
	'viļat',
	'viļās',
	'viļāt',
	'viļķi',
	'viļņa',
	'viļņo',
	'viļos',
	'viļot',
	'viļus',
	'vimba',
	'vimbā',
	'vimbu',
	'vimme',
	'vinca',
	'vinča',
	'vinčā',
	'vinči',
	'vinču',
	'vinda',
	'vindā',
	'vinde',
	'vinds',
	'vindu',
	'vinga',
	'vinja',
	'vinne',
	'vinnē',
	'vinta',
	'vints',
	'vinza',
	'viņča',
	'viņķi',
	'viola',
	'violā',
	'violu',
	'viona',
	'viram',
	'viras',
	'virat',
	'virām',
	'virās',
	'virāt',
	'virba',
	'virbā',
	'virbi',
	'virbu',
	'virca',
	'vircā',
	'virco',
	'vircu',
	'vireo',
	'virga',
	'virgā',
	'virgi',
	'virgs',
	'virgu',
	'viris',
	'virkš',
	'virma',
	'virmā',
	'virmo',
	'virmu',
	'virot',
	'virpa',
	'virpā',
	'virpi',
	'virpo',
	'virpu',
	'virsa',
	'virsā',
	'virse',
	'virsi',
	'virst',
	'virsu',
	'virsū',
	'virša',
	'virši',
	'viršu',
	'virta',
	'virtā',
	'virte',
	'virti',
	'virto',
	'virts',
	'virtu',
	'virus',
	'virve',
	'virvē',
	'virvi',
	'virza',
	'virzā',
	'virze',
	'virzē',
	'virzi',
	'virzu',
	'viržu',
	'visai',
	'visād',
	'viseu',
	'viska',
	'viski',
	'visla',
	'vista',
	'vistā',
	'visti',
	'vists',
	'vistu',
	'visur',
	'visūr',
	'viška',
	'višķi',
	'višna',
	'višnu',
	'višņa',
	'višņi',
	'vitab',
	'vitis',
	'vitni',
	'vitrī',
	'vivat',
	'vizas',
	'vizēs',
	'vizēt',
	'vizla',
	'vizlā',
	'vizlu',
	'vizma',
	'vizmā',
	'vizmo',
	'vizmu',
	'viznī',
	'vizos',
	'vizot',
	'vižņi',
	'vižņo',
	'vīdam',
	'vīdat',
	'vīdēs',
	'vīdēt',
	'vīdot',
	'vīkša',
	'vīkši',
	'vīkšt',
	'vīkšu',
	'vīķis',
	'vīlas',
	'vīlām',
	'vīlās',
	'vīlāt',
	'vīlei',
	'vīles',
	'vīlēm',
	'vīlēs',
	'vīlēt',
	'vīlis',
	'vīlos',
	'vīņas',
	'vīsim',
	'vīsit',
	'vīsta',
	'vīstā',
	'vīsti',
	'vīsto',
	'vīsts',
	'vīstu',
	'vīšņa',
	'vīšos',
	'vīšot',
	'vītai',
	'vītam',
	'vītas',
	'vītām',
	'vītās',
	'vītāt',
	'vītes',
	'vītēs',
	'vītēt',
	'vītie',
	'vītis',
	'vītīs',
	'vītne',
	'vītnē',
	'vītni',
	'vītņi',
	'vītņo',
	'vītņu',
	'vītos',
	'vītra',
	'vītus',
	'vīzām',
	'vīzēt',
	'vīžos',
	'vīžot',
	'vjada',
	'vjāna',
	'vjāsa',
	'vjena',
	'vjetu',
	'vkpai',
	'vlahi',
	'vļkjs',
	'vļora',
	'vndpt',
	'vobla',
	'voblā',
	'voblu',
	'vodka',
	'vohma',
	'voila',
	'voini',
	'voiņi',
	'voiti',
	'voits',
	'volga',
	'volis',
	'volta',
	'voltā',
	'volte',
	'volti',
	'volts',
	'voltu',
	'volvo',
	'vondi',
	'vords',
	'vorna',
	'vorpa',
	'vovaa',
	'vožas',
	'vraca',
	'vraka',
	'vrakā',
	'vraki',
	'vraks',
	'vraku',
	'vrbno',
	'vriti',
	'vrīsa',
	'vsmta',
	'vučko',
	'vudsu',
	'vuier',
	'vulva',
	'vulvā',
	'vulvu',
	'vvbis',
	'zabas',
	'zabže',
	'začem',
	'zadēt',
	'zadki',
	'zafte',
	'zagām',
	'zagās',
	'zagāt',
	'zagle',
	'zaglē',
	'zagli',
	'zagļu',
	'zagos',
	'zagsi',
	'zagss',
	'zagšu',
	'zagta',
	'zagtā',
	'zagti',
	'zagto',
	'zagts',
	'zagtu',
	'zaida',
	'zaiga',
	'zaigā',
	'zaigi',
	'zaigo',
	'zaigs',
	'zaigu',
	'zaima',
	'zaimā',
	'zaimi',
	'zaimo',
	'zaims',
	'zaimu',
	'zaira',
	'zairs',
	'zaiza',
	'zakon',
	'zaksa',
	'zaksā',
	'zaksi',
	'zakss',
	'zaksu',
	'zaķis',
	'zalba',
	'zalbe',
	'zalga',
	'zalgā',
	'zalgi',
	'zalgo',
	'zalgs',
	'zalgu',
	'zalša',
	'zalts',
	'zalva',
	'zalve',
	'zalvē',
	'zalvi',
	'zalvu',
	'zaļai',
	'zaļam',
	'zaļas',
	'zaļām',
	'zaļās',
	'zaļēs',
	'zaļēt',
	'zaļga',
	'zaļgā',
	'zaļgi',
	'zaļgo',
	'zaļgs',
	'zaļgu',
	'zaļie',
	'zaļos',
	'zaļot',
	'zaļus',
	'zamba',
	'zambo',
	'zamki',
	'zamok',
	'zampa',
	'zampā',
	'zampu',
	'zamša',
	'zamšs',
	'zanda',
	'zante',
	'zapas',
	'zapte',
	'zapts',
	'zaras',
	'zarka',
	'zarna',
	'zarnā',
	'zarnu',
	'zaros',
	'zarot',
	'zarta',
	'zarte',
	'zaube',
	'zaude',
	'zaudē',
	'zauls',
	'zaumj',
	'zaura',
	'zaurā',
	'zauri',
	'zaurs',
	'zauru',
	'zavēt',
	'zāģēs',
	'zāģēt',
	'zāģis',
	'zākaa',
	'zākaā',
	'zākai',
	'zākas',
	'zākau',
	'zākās',
	'zākāt',
	'zālea',
	'zāleā',
	'zālei',
	'zāles',
	'zāleu',
	'zāļos',
	'zāļot',
	'zāmki',
	'zārda',
	'zārdā',
	'zārde',
	'zārdi',
	'zārdo',
	'zārds',
	'zārdu',
	'zārka',
	'zārkā',
	'zārki',
	'zārko',
	'zārks',
	'zārku',
	'zduni',
	'zebra',
	'zebrā',
	'zebre',
	'zebru',
	'zeida',
	'zeids',
	'zeila',
	'zeile',
	'zeili',
	'zeiļi',
	'zeīns',
	'zeķes',
	'zelči',
	'zelēs',
	'zelēt',
	'zella',
	'zelle',
	'zelsi',
	'zelšu',
	'zelta',
	'zeltā',
	'zelti',
	'zeltī',
	'zelto',
	'zelts',
	'zeltu',
	'zelve',
	'zeļam',
	'zeļat',
	'zeļči',
	'zeļģe',
	'zeļķi',
	'zeļot',
	'zemai',
	'zemam',
	'zemas',
	'zemām',
	'zemās',
	'zemen',
	'zemēs',
	'zemēt',
	'zemie',
	'zemin',
	'zemļi',
	'zemos',
	'zemot',
	'zemus',
	'zenka',
	'zenki',
	'zenta',
	'zezer',
	'zēlām',
	'zēlāt',
	'zēlis',
	'zibam',
	'zibat',
	'zibēs',
	'zibēt',
	'zibot',
	'zieda',
	'ziedā',
	'ziede',
	'ziedē',
	'ziedi',
	'ziedo',
	'zieds',
	'ziedu',
	'ziegs',
	'zieķe',
	'zieķē',
	'ziema',
	'ziemā',
	'ziemo',
	'ziemu',
	'ziepe',
	'ziepē',
	'ziest',
	'ziežu',
	'zikrs',
	'zilai',
	'zilam',
	'zilas',
	'zilām',
	'zilās',
	'zilāt',
	'zilbe',
	'zilbē',
	'zilbi',
	'zilēs',
	'zilēt',
	'zilga',
	'zilgā',
	'zilgi',
	'zilgo',
	'zilgs',
	'zilgt',
	'zilgu',
	'zilie',
	'zilis',
	'zilnē',
	'zilnī',
	'zilos',
	'zilot',
	'zilsi',
	'zilst',
	'zilšu',
	'zilta',
	'ziltā',
	'zilte',
	'zilti',
	'zilto',
	'zilts',
	'ziltu',
	'zilus',
	'zimba',
	'zimse',
	'zimza',
	'zimzā',
	'zimze',
	'zimzē',
	'zimzi',
	'zimzu',
	'zimžu',
	'zinām',
	'zinās',
	'zināt',
	'zinga',
	'zinis',
	'zinos',
	'zinot',
	'zinšu',
	'zinta',
	'zintā',
	'zinte',
	'zintē',
	'zinti',
	'zints',
	'zintu',
	'ziņģe',
	'ziņģē',
	'ziņos',
	'ziņot',
	'ziras',
	'zirga',
	'zirgā',
	'zirgi',
	'zirgs',
	'zirgu',
	'zirņi',
	'zivis',
	'zizma',
	'zīdām',
	'zīdās',
	'zīdāt',
	'zīdēt',
	'zīdis',
	'zīdīs',
	'zīdīt',
	'zīdos',
	'zīdot',
	'zīlēs',
	'zīlēt',
	'zīlis',
	'zīmes',
	'zīmēs',
	'zīmēt',
	'zīsta',
	'zīstā',
	'zīsti',
	'zīsto',
	'zīsts',
	'zīstu',
	'zīšļā',
	'zīves',
	'zīžam',
	'zīžas',
	'zīžat',
	'zīžļā',
	'zīžos',
	'zīžot',
	'zlate',
	'zlīna',
	'zloba',
	'zlota',
	'zlotā',
	'zloti',
	'zlots',
	'zlotu',
	'zmeja',
	'zmejs',
	'znaja',
	'znošu',
	'znoti',
	'znotī',
	'znots',
	'zobos',
	'zobot',
	'zofka',
	'zogam',
	'zogas',
	'zogat',
	'zogos',
	'zogot',
	'zohar',
	'zoles',
	'zolēs',
	'zolēt',
	'zolva',
	'zomba',
	'zonda',
	'zonde',
	'zondē',
	'zondi',
	'zonds',
	'zonēs',
	'zonēt',
	'zongs',
	'zonta',
	'zonžu',
	'zooze',
	'zorba',
	'zorbā',
	'zorbi',
	'zorbs',
	'zorbu',
	'zorģi',
	'zorte',
	'zosēs',
	'zosēt',
	'zosna',
	'zosta',
	'zoste',
	'zubki',
	'zubra',
	'zubre',
	'zubri',
	'zubru',
	'zudām',
	'zudāt',
	'zudis',
	'zudīs',
	'zulla',
	'zumam',
	'zumat',
	'zumba',
	'zumēs',
	'zumēt',
	'zumot',
	'zunda',
	'zundi',
	'zunds',
	'zurza',
	'zusta',
	'zustā',
	'zusti',
	'zusto',
	'zusts',
	'zustu',
	'zušos',
	'zušus',
	'zutim',
	'zutis',
	'zuzam',
	'zuzat',
	'zuzēs',
	'zuzēt',
	'zuzot',
	'zūdam',
	'zūdat',
	'zūdās',
	'zūdos',
	'zūdot',
	'zūras',
	'zvaks',
	'zvana',
	'zvanā',
	'zvani',
	'zvans',
	'zvanu',
	'zvaņa',
	'zvaņā',
	'zvaņu',
	'zvāro',
	'zveja',
	'zvejā',
	'zveji',
	'zvejo',
	'zvejs',
	'zveju',
	'zvelē',
	'zvels',
	'zvelt',
	'zveļu',
	'zvetē',
	'zvēla',
	'zvēli',
	'zvēlu',
	'zvēra',
	'zvērā',
	'zvēre',
	'zvērē',
	'zvēri',
	'zvēro',
	'zvērs',
	'zvēru',
	'zviga',
	'zviln',
	'zvilu',
	'zvira',
	'zvīgo',
	'zvīļa',
	'zvīļā',
	'zvīļi',
	'zvīļo',
	'zvīļš',
	'zvīļu',
	'zvīņa',
	'zvīņā',
	'zvīņi',
	'zvīņu',
	'žagaa',
	'žagaā',
	'žagai',
	'žagas',
	'žagau',
	'žagos',
	'žagot',
	'žagus',
	'žampa',
	'žampā',
	'žampu',
	'žanis',
	'žanra',
	'žanrā',
	'žanri',
	'žanrs',
	'žanru',
	'žarga',
	'žarki',
	'žauga',
	'žauju',
	'žauna',
	'žaunā',
	'žaunu',
	'žausi',
	'žaušu',
	'žauta',
	'žautā',
	'žauti',
	'žauto',
	'žauts',
	'žautu',
	'žākle',
	'žāklē',
	'žākli',
	'žākļu',
	'žāvaa',
	'žāvaā',
	'žāvai',
	'žāvas',
	'žāvau',
	'žāvām',
	'žāvās',
	'žāvāt',
	'žāvēs',
	'žāvēt',
	'žāvis',
	'žāvos',
	'žebre',
	'žebri',
	'žeire',
	'žeirs',
	'želēt',
	'želva',
	'želve',
	'želvi',
	'žepče',
	'žesta',
	'žestā',
	'žesti',
	'žests',
	'žestu',
	'žēlaa',
	'žēlaā',
	'žēlai',
	'žēlam',
	'žēlas',
	'žēlau',
	'žēlām',
	'žēlās',
	'žēlie',
	'žēliņ',
	'žēlos',
	'žēlot',
	'žēlus',
	'žgova',
	'žigla',
	'žiglā',
	'žigli',
	'žiglo',
	'žigls',
	'žiglu',
	'žilba',
	'žilbā',
	'žilbi',
	'žilbo',
	'žilbs',
	'žilbt',
	'žilbu',
	'žilka',
	'žirēs',
	'žirēt',
	'žiršs',
	'žļadz',
	'žļeba',
	'žogas',
	'žogus',
	'žompa',
	'žompā',
	'žompu',
	'žonga',
	'žoras',
	'žovti',
	'žuana',
	'žubes',
	'žubis',
	'žulga',
	'žulgā',
	'žulgi',
	'žulgo',
	'žulgs',
	'žulgt',
	'žulgu',
	'žulis',
	'žulka',
	'žulpa',
	'žults',
	'žurēt',
	'žurga',
	'žurgā',
	'žurgu',
	'žurka',
	'žurkā',
	'žurks',
	'žurku',
	'žuvām',
	'žuvāt',
	'žuvis',
	'žūpos',
	'žūpot',
	'žūrēt',
	'žūsim',
	'žūsit',
	'žūsti',
	'žūstu',
	'žūšot',
	'žūtai',
	'žūtam',
	'žūtas',
	'žūtām',
	'žūtās',
	'žūtie',
	'žūtos',
	'žūtus',
	'žūžas',
	'žūžos',
	'žūžot',
	'žvadz',
	'žvāks',
	'žvika',
	'žviks',
	'žvikt',
	'žvira',
	'žvīgo',
	'žvīks',
	'porti',
	'kūlas',
	'pakām',
	'pakai',
	'lakai',
	'jakai',
	'aldis',
	'lūpas',
	'rīmas',
	'nūjas',
	'ēzeļi',
	'lāvām',
	'lodēm',
	'gulbī',
	'zīles',
	'zīlei',
	'vāles',
	'vālei',
	'vālēm',
	'Raiņa',
	'Raini',
	'Rainī',
	'Raiņi',
	'Raiņu',
	'sugas',
	'sugai',
	'sugās',
	'ķešas',
	'ķešai',
	'ķešām',
	'ķešās',
	'retas',
	'retām',
	'retās',
	'rētas',
	'rētai',
	'rētām',
	'rētās',
	'nūjai',
	'nūjām',
	'nūjās',
	'košam',
	'košos',
	'košie',
	'košai',
	'košām',
	'košās',
	'košāk',
	'retus',
	'retos',
	'tavos',
	'tavus',
	'tavam',
	'jakas',
	'jakām',
	'jakās',
	'tapai',
	'tapās',
	'ķepas',
	'ķepai',
	'ķepās',
	'tavās',
	'tavām',
	'tavai',
	'tavas',
	'cienu',
	'ripas',
	'ripai',
	'ripām',
	'ripās',
	'sūkļa',
	'sūkli',
	'sūklī',
	'sūkļi',
	'sūkļu',
	'mokos',
	'sēkli',
	'sēklī',
	'sēkļi',
	'sēkļu',
	'saldū',
	'zīlēm',
	'ventu',
	'zobam',
	'ķēvei',
	'lindā',
	'sejām',
	'ratus',
	'tālis',
	'cerku',
	'sejās',
	'vēstu',
	'nāsis',
	'mauku',
	'mošķu',
	'bērēs',
	'vāzes',
	'oāzes',
	'odzes',
	'tāmēm',
	'ķirmi',
	'ķirbi',
	'bāņos',
	'jāņos',
	'rātei',
	'rāmie',
	'sūnās',
	'pilēm',
	'agita',
	'anita',
	'rāmas',
	'grāvī',
	'kedās',
	'septu',
	'mizām',
	'ļipām',
	'kijām',
	'alkās',
	'mucai',
	'mucas',
	'mušai',
	'mušas',
	'ratos',
	'tūtās',
	'tūtām',
	'tūjās',
	'tūjām',
	'tūtēs',
	'kūtīs',
	'stopē',
	'vīnam',
	'pleķa',
	'kādās',
	'kādām',
	'kāzās',
	'kāzām',
	'rikas',
	'rikai',
	'poras',
	'mošķa',
	'rūpēm',
	'ķirši',
	'ķiršu',
	'droni',
	'dronu',
	'raģēs',
	'ragos',
	'pašos',
	'pašas',
]
